import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";

import { IMAGE_UPLOAD_URL } from "./urls";
import { getLoginUser } from "./localStorage";

export function apiCall(requestMethod, url, data, onSuccess, onFailure) {
  var formData = {
    method: requestMethod,
    headers: { "Content-Type": "application/json" },
  };

  var formBody = JSON.stringify(data);

  if (data !== undefined && data !== "") {
    formData["body"] = formBody;
  }
  try {
    fetch(url, formData)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success) {
          onSuccess(responseJson);
        } else {
          onFailure(responseJson);
        }
      });
  } catch (e) {
    console.log("server disconnected");
  }
}
export function functionalityApiCall(
  requestMethod,
  url,
  data,
  onSuccess,
  onFailure,
  token
) {
  var formData = {
    method: requestMethod,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  var formBody = JSON.stringify(data);

  if (data !== undefined && data !== "") {
    formData["body"] = formBody;
  }

  try {
    fetch(url, formData, 500).then((response) => {
      response.json().then((responseJson) => {
        if (responseJson.status === 200) {
          onSuccess(responseJson);
        } else {
          onFailure(responseJson);
        }
      });
    });
  } catch (e) {
    console.log("server disconnected");
  }
}

export function functionalityDeleteApiCall(url, onSuccess, token) {
  var formData = {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    fetch(url, formData, 500).then((response) => {
      response.json().then((responseJson) => {
        if (responseJson.status === 200) {
          onSuccess(responseJson);
        }
      });
    });
  } catch (e) {
    console.log("server disconnected");
  }
}

export function wihtoutBodyApiCall(method, url, onSuccess, onFailure, token) {
  var formData = {
    method: method,
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  try {
    fetch(url, formData).then((response) => {
      response.json().then((responseJson) => {
        if (responseJson.status === 200) {
          onSuccess(responseJson);
        } else {
          onFailure(responseJson);
        }
      });
    });
  } catch (e) {
    console.log("server disconnected");
  }
}

export function imageUploadApiCall(data, onSuccess) {
  const { token } = getLoginUser();
  let formData = new FormData();
  for (let v of data) {
    formData.append("files", v);
  }

  try {
    axios
      .post(`${IMAGE_UPLOAD_URL}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((v) => {
        if (v.status === 200) {
          onSuccess(v.data.filename);
        } else {
          toast.error("images not upload");
        }
      });
  } catch (e) {
    console.log("server disconnected", e.getMessage());
  }
}

export function fetchGetApiCall(url, onSuccess, onFailure) {
  const token = getLoginUser()?.token;
  try {
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((v) => {
        if (v.status === 200) {
          onSuccess(v.data.data);
        } else {
          onFailure(v);
        }
      });
  } catch (e) {
    console.log("server disconnected", e.getMessage());
  }
}

export const getTimeStops = (start, end, minutes) => {
  var startTime = moment(start, "HH:mm A");
  var endTime = moment(end, "HH:mm A");

  if (endTime.isBefore(startTime)) {
    endTime.add(1, "day");
  }

  var timeStops = [];

  while (startTime <= endTime) {
    timeStops.push(new moment(startTime).format("HH:mm"));
    startTime.add(minutes, "minutes");
  }
  return timeStops;
};
