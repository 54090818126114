import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";

import { getLoginUserDetails, loginUser } from "../../action/user";

import Logo from "../../Components/Logo/X-pod-01.png";
import Image from "../../Components/GymPics/loginImage.jpg";

//css files
import "../../Components/CSSfiles/LoginForgotPasswordRegistration.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const history = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.login?.isProcess);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const successCallBack = async (response) => {
        dispatch(getLoginUserDetails());
        history("/dashboard");
      };
      dispatch(loginUser(email, password, successCallBack));
    } catch (error) {
      console.log("inDesign", error);
    }
  };

  const handleLoadImage = () => {
    setImageLoading(!imageLoading);
  };

  const redirectPrivacyPolicy = () => {
    history("/privacy-policy");
  };

  const redirectForgotPassword = () => {
    history("/forgotPassword");
  };

  return (
    <div className="container">
      <div className="row authMain">
        <div className="col-xxl-8 col-lg-7 p-0">
          <div className="authImage d-flex align-items-center justify-content-center position-relative">
            {imageLoading && (
              <span className="loginImgLoad">
                <TailSpin heigth="150" width="175" color="black" />
              </span>
            )}
            <img src={Image} alt="login" onLoad={handleLoadImage} />
          </div>
        </div>

        <div className="col-xxl-4 col-lg-5 p-0">
          <div className="authRightSide">
            <div className="Logo">
              <img src={Logo} height={150} alt="" width={150} />
            </div>
            <div className="formLogin">
              <Form>
                <Form.Group size="lg">
                  <input
                    type="email"
                    className="input"
                    id="Uname"
                    placeholder="Email Address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  ></input>
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                  <input
                    type="Password"
                    className="input mt-2"
                    id="Pass"
                    placeholder="Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  ></input>
                </Form.Group>
                <div
                  className="forgotPass"
                  onClick={() => redirectForgotPassword()}
                >
                  <p>Forgot Password?</p>
                </div>
                <Button
                  block
                  size="lg"
                  type="submit"
                  className="authButton w-100"
                  onClick={handleSubmit}
                >
                  {!isLoading ? (
                    "Login"
                  ) : (
                    <TailSpin heigth="25" width="45" color="white" />
                  )}
                </Button>
                <div
                  className="privacy"
                  onClick={() => redirectPrivacyPolicy()}
                >
                  <p>Privacy Policy</p>
                </div>
              </Form>

              {/* <div className="question">
                <p>Don't have any account yet?</p>
                <Button
                  block
                  size="lg"
                  type="submit"
                  className="register w-100"
                  onClick={() => {
                    history("/register");
                  }}
                >
                  Register
                </Button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
