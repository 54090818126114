import {
  GET_GROUPDETAILS_FAIL,
  ADD_GROUPDETAILS_FAIL,
  EDIT_GROUPDETAILS_FAIL,
  GET_GROUPDETAILS_REQUEST,
  DELETE_GROUPDETAILS_FAIL,
  GET_GROUPDETAILS_SUCCESS,
  ADD_GROUPDETAILS_REQUEST,
  ADD_GROUPDETAILS_SUCCESS,
  EDIT_GROUPDETAILS_REQUEST,
  EDIT_GROUPDETAILS_SUCCESS,
  DELETE_GROUPDETAILS_REQUEST,
  DELETE_GROUPDETAILS_SUCCESS,
} from "../action/actionConstant";

const intialState = {
  status: "",
  data: null,
  isProcess: false,
  message: null,
};

const groupDetail = (state = intialState, action) => {
  const { data } = action;
  switch (action.type) {
    case ADD_GROUPDETAILS_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case ADD_GROUPDETAILS_SUCCESS:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case ADD_GROUPDETAILS_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case GET_GROUPDETAILS_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case GET_GROUPDETAILS_SUCCESS:
      return {
        ...state,
        data: data,
        isProcess: false,
      };
    case GET_GROUPDETAILS_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case EDIT_GROUPDETAILS_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case EDIT_GROUPDETAILS_SUCCESS:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case EDIT_GROUPDETAILS_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
        message: data.message || data.data.message,
      };
    case DELETE_GROUPDETAILS_REQUEST:
      return {
        ...state,
        isProcess: true,
      };
    case DELETE_GROUPDETAILS_SUCCESS:
      return {
        ...state,
        isProcess: false,
      };
    case DELETE_GROUPDETAILS_FAIL:
      return {
        ...state,
        isProcess: false,
        message: data.message,
      };
    default:
      return state;
  }
};
export default groupDetail;
