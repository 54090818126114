import React from "react";

import DarkHeader from "./DarkHeader/DarkHeader";
import LightHeader from "./LightHeader/LightHeader";

function Header({ scrolled }) {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {scrolled ? (
        <DarkHeader handleClick={handleClick} />
      ) : (
        <LightHeader handleClick={handleClick} />
      )}
    </div>
  );
}
export default Header;
