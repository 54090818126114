import React from "react";
import { Col, Row } from "react-bootstrap";
// import Button from "../Components/Button/Button";

import styles from "./index.module.scss";

function Banner() {
  return (
    <div className={`container-fluid ${styles.mainBanner}`}>
      <Row justify="center" className={styles.bannerContainer}>
        <Col xl={18} className={styles.bannerCol}>
          <div className={`container ${styles.bannerText}`}>
            <h1>Nobody But My Body</h1>
            <h4>Entire Gym to Yourself</h4>
            {/* <Button title={"Book Now"} /> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Banner;
