import {
  GET_GYMDETAILS_FAIL,
  EDIT_GYMDETAILS_FAIL,
  GET_GYMDETAILS_REQUEST,
  DELETE_GYMDETAILS_FAIL,
  GET_GYMDETAILS_SUCCESS,
  EDIT_GYMDETAILS_REQUEST,
  EDIT_GYMDETAILS_SUCCESS,
  DELETE_GYMDETAILS_REQUEST,
  DELETE_GYMDETAILS_SUCCESS,
  GET_GYMCOUNTS_SUCCESS,
} from "../action/actionConstant";

const intialState = {
  status: "",
  data: null,
  isProcess: false,
  message: null,
  gymList: null,
  slots: null,
  duration: null,
};

const GymDetails = (state = intialState, action) => {
  const { data } = action;
  // let test = data
  switch (action.type) {
    case GET_GYMDETAILS_REQUEST:
      return {
        ...state,
        data: null,
        gymList: null,
        isProcess: true,
      };
    case GET_GYMDETAILS_SUCCESS:
      return {
        ...state,
        data: data,
        gymList: data?.list,
        isProcess: false,
      };
    case GET_GYMDETAILS_FAIL:
      return {
        ...state,
        data: null,
        gymList: null,
        isProcess: false,
      };
    case EDIT_GYMDETAILS_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case EDIT_GYMDETAILS_SUCCESS:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case EDIT_GYMDETAILS_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
        message: data.message || data.data.message,
      };
    case DELETE_GYMDETAILS_REQUEST:
      return {
        ...state,
        isProcess: true,
      };
    case DELETE_GYMDETAILS_SUCCESS:
      return {
        ...state,
        isProcess: false,
      };
    case DELETE_GYMDETAILS_FAIL:
      return {
        ...state,
        isProcess: false,
        message: data.message,
      };
    case GET_GYMCOUNTS_SUCCESS:
      let slot = 0;
      let duration = 0;
      action?.data?.forEach((element) => {
        if (element?.gym_type === "SLOT") {
          slot = element?.count;
        } else {
          duration = element?.count;
        }
      });
      return {
        ...state,
        slots: slot,
        duration: duration,
      };
    default:
      return state;
  }
};
export default GymDetails;
