import { Routes, Route, Navigate } from "react-router-dom";

import CCTV from "../Pages/CCTV";
import GymListing from "../Pages/Gym";
import Finance from "../Pages/Finance";
import Feedback from "../Pages/Feedback";
import UserEdit from "../Pages/UserEdit";
import Dashboard from "../Pages/Dashboard";
import Franchise from "../Pages/Franchise";
import GymCreate from "../Pages/GymCreate";
import GymDetail from "../Pages/GymDetail";
import ReportPage from "../Pages/ReportPage";
import UserListing from "../Pages/UserListing";
import GroupCreate from "../Pages/GroupCreate";
import GroupListing from "../Pages/GroupListing";
import GroupDetails from "../Pages/GroupDetails";
import FeedbackChat from "../Pages/FeedBackChat";
import FranchiseEdit from "../Pages/FranchiseEdit";
import ProfileDetail from "../Pages/ProfileDetail";
import BookingsListing from "../Pages/BookingsListing";
import NotificationNew from "../Pages/NotificationNew";
import FranchiseCreate from "../Pages/FranchiseCreate";
import MyProSidebarProvider from "./MyProSidebarProvider";
import FranchiseDashboard from "../Pages/FranchiseDashboard";
import NotificationsListing from "../Pages/NotificationsListing";

const PrivateRoute = () => {
  return (
    <MyProSidebarProvider>
      <Routes>
        <Route exact path="*" element={<Navigate to="/dashboard" replace />} />
        <Route exact path="/dashboard" element={<Dashboard />} />

        <Route path="/gym-listing" element={<GymListing />} />

        <Route path="/gym-create" element={<GymCreate />} />

        {/* <Route path="/user-create" element={<UserCreate/>} /> */}

        <Route path="/user-listing" element={<UserListing />} />

        <Route path="/user-edit" element={<UserEdit />} />

        <Route path="/group-listing" element={<GroupListing />} />

        <Route path="/bookings-listing" element={<BookingsListing />} />

        <Route path="/report" element={<ReportPage />} />

        <Route path="/franchise-create" element={<FranchiseCreate />} />

        <Route path="/franchise-dashboard" element={<FranchiseDashboard />} />

        <Route path="/CCTV" element={<CCTV />} />

        <Route
          path="/notifications-listing"
          element={<NotificationsListing />}
        />

        <Route path="/notification-new" element={<NotificationNew />} />

        <Route path="/feedback" element={<Feedback />} />
        <Route path="/feedback-chat" element={<FeedbackChat />} />

        {/* <PrivateRoute path="/vaccination" element={<VerifyVax/>} /> */}

        <Route path="/Finance" element={<Finance />} />

        <Route path="/gym-detail" element={<GymDetail />} />

        <Route path="/franchise" element={<Franchise />} />

        <Route path="/profile-detail" element={<ProfileDetail />} />

        <Route path="/group-details" element={<GroupDetails />} />

        <Route path="/group-create" element={<GroupCreate />} />

        <Route path="/franchise/edit/:id" element={<FranchiseEdit />} />
      </Routes>
    </MyProSidebarProvider>
  );
};

export default PrivateRoute;
