import {
  functionalityApiCall,
  functionalityDeleteApiCall,
} from "../comman/connect";
import { toast } from "react-toastify";
import { getLoginUser } from "../comman/localStorage";
import { ADD_FRANCHISE_URL, GET_FRANCHISE_URL } from "../comman/urls";

import {
  ADD_FRANCHISE_FAIL,
  GET_FRANCHISE_FAIL,
  EDIT_FRANCHISE_FAIL,
  ADD_FRANCHISE_REQUEST,
  ADD_FRANCHISE_SUCCESS,
  GET_FRANCHISE_REQUEST,
  GET_FRANCHISE_SUCCESS,
  EDIT_FRANCHISE_REQUEST,
  EDIT_FRANCHISE_SUCCESS,
  DELETE_FRANCHISE_REQUEST,
  DELETE_FRANCHISE_SUCCESS,
} from "./actionConstant";

const franchiseRequest = () => ({
  type: GET_FRANCHISE_REQUEST,
});

const franchiseSuccess = (data) => ({
  type: GET_FRANCHISE_SUCCESS,
  data,
});

const franchiseFail = () => ({
  type: GET_FRANCHISE_FAIL,
});
const addFranchiseRequest = () => ({
  type: ADD_FRANCHISE_REQUEST,
});

const addFranchiseSuccess = () => ({
  type: ADD_FRANCHISE_SUCCESS,
});

const addFranchiseFail = (data) => ({
  type: ADD_FRANCHISE_FAIL,
  data,
});
const editFranchiseRequest = () => ({
  type: EDIT_FRANCHISE_REQUEST,
});

const editFranchiseSuccess = () => ({
  type: EDIT_FRANCHISE_SUCCESS,
});

const editFranchiseFail = (data) => ({
  type: EDIT_FRANCHISE_FAIL,
  data,
});
const deleteFranchiseRequest = () => ({
  type: DELETE_FRANCHISE_REQUEST,
});

const deleteFranchiseSuccess = () => ({
  type: DELETE_FRANCHISE_SUCCESS,
});

export const getFranchise =
  (search, page, limit, filter, filterColumn) => (dispatch) => {
    dispatch(franchiseRequest());
    const { token } = getLoginUser();
    if (token) {
      const data = {
        page: page,
        limit: limit,
        sort: `${filterColumn ? filterColumn : "id"} ${
          filter ? filter : "asc"
        }`,
        search: [
          {
            tableNo: "0",
            search: search,
            matchFields: ["company_name"],
          },
        ],
      };
      const url = GET_FRANCHISE_URL;
      const onSuccess = (response) => {
        dispatch(franchiseSuccess(response));
      };

      const onFailure = (response) => {
        dispatch(franchiseFail(response));
        toast.error(response.message);
      };

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };

export const addFranchise = (requestData, successCallBack) => (dispatch) => {
  dispatch(addFranchiseRequest());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(addFranchiseSuccess(response));
      successCallBack(response.data);
    };
    const url = ADD_FRANCHISE_URL;

    const onFailure = (response) => {
      dispatch(addFranchiseFail(response));
      toast.error(response.message);
    };

    functionalityApiCall("POST", url, requestData, onSuccess, onFailure, token);
  }
};

export const editFranchise =
  (id, requestData, successCallBack) => (dispatch) => {
    dispatch(editFranchiseRequest());
    const { token } = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(editFranchiseSuccess(response));
        successCallBack(response);
      };
      const url = `${ADD_FRANCHISE_URL}/${id}`;

      const onFailure = (response) => {
        dispatch(editFranchiseFail(response));
        toast.error(response.message);
      };

      functionalityApiCall(
        "PUT",
        url,
        requestData,
        onSuccess,
        onFailure,
        token
      );
    }
  };

export const deleteFranchise = (id) => (dispatch) => {
  dispatch(deleteFranchiseRequest());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(deleteFranchiseSuccess(response));
      toast.success("Deleted Successfully");
    };
    const url = `${ADD_FRANCHISE_URL}/${id}`;

    functionalityDeleteApiCall(url, onSuccess, token);
  }
};
