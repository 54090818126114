import { combineReducers } from "redux";

import chat from "./chat";
import login from "./user";
import report from "./report";
import finance from "./finance";
import payment from "./payment";
import bookings from "./booking";
import gymUsers from "./gymUsers";
import feedback from "./feedback";
import franchise from "./franchise";
import gymDetail from "./gymDetail";
import groupDetail from "./groupDetail";
import vaccination from "./vaccination";
import transection from "./transection";
import notification from "./notification";

const reducers = combineReducers({
  chat: chat,
  login: login,
  feedback: feedback,
  gymUsers: gymUsers,
  bookings: bookings,
  gymDetail: gymDetail,
  franchise: franchise,
  groupDetail: groupDetail,
  vaccination: vaccination,
  transection: transection,
  finance: finance,
  report: report,
  notification: notification,
  payment: payment,
});

export default reducers;
