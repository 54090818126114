import {
  GET_TRANSECTION_FAIL,
  GET_TRANSECTION_SUCCESS,
  GET_TRANSECTION_REQUEST,
} from "../action/actionConstant";

const intialState = {
  status: "",
  data: null,
  isProcess: false,
  message: null,
};

const transection = (state = intialState, action) => {
  const { data } = action;
  switch (action.type) {
    case GET_TRANSECTION_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case GET_TRANSECTION_SUCCESS:
      return {
        ...state,
        data: data.data,
        isProcess: false,
      };
    case GET_TRANSECTION_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    default:
      return state;
  }
};
export default transection;
