/* eslint-disable react-hooks/exhaustive-deps */
import { ToastContainer } from "react-toastify";

import Routing from "./routing";

import "./App.css";
import 'swiper/swiper-bundle.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import "../node_modules/react-date-range/dist/styles.css";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import "../node_modules/react-date-range/dist/theme/default.css";
// import "../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routing />
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
