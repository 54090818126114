import React from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import SideHeader from "../SideHeader/SideHeader";
import logo1 from "../../../../Components/Logo/X-pod-01.png";

import styles from "./index.module.scss";

export const menuBar = [
  {
    name: "Login",
    link: "/login",
  },
];
const LightHeader = ({ handleClick }) => {
  return (
    <div className={styles.scrolledNav}>
      <div className="container">
        <Row className={styles.setNavbar}>
          <Col lg={3} md={3} sm={3} xs={3}>
            <div className={styles.logoBox}>
              <NavLink exact to="/">
                <img className={styles.companyLogo} src={logo1} alt="logo" />
              </NavLink>
            </div>
          </Col>
          <Col lg={9} md={9} sm={9} xs={9}>
            <div className={styles.menubar}>
              {menuBar?.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    activeClassName={styles.selected}
                    className={styles.navLink}
                    exact
                    to={item.link}
                    onClick={handleClick}
                  >
                    {item.name}
                  </NavLink>
                );
              })}
            </div>
            <div className={styles.faveside}>
              <SideHeader color={"fff"} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LightHeader;
