/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import moment from "moment";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";

import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
import Delete from "../Components/svgImg/Delete.svg";
import { deleteBooking, getBookings } from "../action/booking";

import "../Components/CSSfiles/Bookings.css";

function Bookings(props) {
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState();
  const [modalShow, setModalShow] = useState(false);

  const [filterType, setFilterType] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");

  const [type, setType] = useState("Type");
  const isLoading = useSelector((state) => state.bookings?.isProcess);
  const bookingList = useSelector((state) =>
    state.bookings.data ? state.bookings?.data : []
  );

  useEffect(() => {
    dispatch(getBookings("", "", 1, 200));
  }, []);

  const handleSearch = (data) => {
    dispatch(getBookings(data, "", 1, 200));
  };

  const handleGymSearch = (data) => {
    dispatch(getBookings("", data, 1, 200));
  };

  const debounce_fun = _.debounce(function (response) {
    handleSearch(response);
  }, 400);

  const gymDebounce = _.debounce(function (response) {
    handleGymSearch(response);
  }, 400);

  const handleDelete = () => {
    dispatch(deleteBooking(deleteId));
    setModalShow(!modalShow);
  };

  const handleFilterData = () => {
    let filterObj = {};
    if (filterType) {
      filterObj.type = filterType;
    }
    if (startDate) {
      filterObj.created_at = {
        min: startDate ? `${startDate} 00:00:00` : "",
      };
    }
    if (endDate) {
      filterObj.created_at = {
        ...filterObj.created_at,
        max: endDate ? `${endDate} 23:59:59` : null,
      };
    }
    if (!filterObj.type) {
      dispatch(getBookings("", "", 1, 200));
    }
    dispatch(getBookings("", "", 1, 200, null, filterObj));
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setType("Type");
    setFilterType("");
    dispatch(getBookings("", "", 1, 200));
  };

  const header = [
    { name: "Booking ID", selector: (row) => row.id, sortable: true },
    { name: "Gym Name", selector: (row) => row.gym_name, sortable: true },
    {
      name: "User Name",
      selector: (row) => row.user_id_username,
      sortable: true,
    },
    { name: "Type", selector: (row) => row.type, sortable: true },
    {
      name: "Date / Time",
      selector: (row) => row.created_at,
      sortable: true,
      cell: (selectors) => moment(selectors.created_at).format("DD/MM/YYYY"),
    },
    { name: "Start Time", selector: (row) => row.start_time, sortable: true },
    { name: "End Time", selector: (row) => row.end_time, sortable: true },
    {
      name: "Total Duration",
      selector: (row) => row.total_duration,
      sortable: true,
    },
    { name: "Fees", selector: (row) => row.booking_amount, sortable: true },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (selectors) => (
        <div className="d-flex align-items-center">
          <button
            className="btn actionBtn"
            onClick={() => {
              setModalShow(!modalShow);
              setDeleteId(props.id);
            }}
          >
            <img src={Delete} alt="delete" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mainboxes">
        <TopHeader title={"Booking Listing"} />
        <div className="row align-items-center pb-3 mb-3">
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
            onHide={() => setModalShow(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Delete Gym
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Are you sure, you want to delete?</h4>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setModalShow(!modalShow);
                }}
              >
                Close
              </button>
              <button
                className="btn enableBtnActive"
                onClick={() => {
                  handleDelete();
                }}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal>
          <div className="col-xxl-2 col-lg-4 col-md-6 col-sm-12">
            <SearchBar placeholder={"Search Bookings"} search={debounce_fun} />
          </div>
          <div className="col-xxl-2 col-lg-4 col-md-6 col-sm-12 mobileTopSpacing">
            <SearchBar placeholder={"Search Gym"} search={gymDebounce} />
          </div>
          <div className="col-xxl-2 col-lg-4 col-md-6 col-sm-12 tabletTopSpacing">
            <div className="d-flex  align-items-center ">
              <div className="minWidth">Start Date : </div>
              <input
                type="date"
                value={startDate}
                className="lastBooking startDate"
                max={endDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center
          laptopCenter topSpacing"
          >
            <div className="minWidth">End Date : </div>
            <input
              type="date"
              value={endDate}
              className="lastBooking startDate"
              format="DD/MM/YY"
              min={startDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div>
          <div className="col-xxl-1 col-lg-2 col-md-6 col-sm-12 topSpacing">
            <DropdownButton
              id="dropdown-basic-button"
              title={type}
              className="bg-transparent todayDrop"
              onSelect={(e) => {
                e = e.split(",");
                setType(e[1]);
                setFilterType(e[0]);
              }}
            >
              <Dropdown.Item eventKey={["slot", "SLOT"]}>SLOT</Dropdown.Item>
              <Dropdown.Item eventKey={["duration", "DURATION"]}>
                DURATION
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="col-xxl-2 col-lg-2 col-md-6 col-sm-12 d-flex align-items-center topSpacing">
            <button
              className="btn sortBtn reportExport mobileFilterBtn"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              className="btn createGymBtn exportBtn searchBtn tabletFont mobileFilterBtn"
              onClick={handleFilterData}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          {isLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : (
            <DataTable columns={header} data={bookingList} pagination />
          )}
        </div>
      </div>
    </>
  );
}

export default Bookings;
