import React from "react";

import "../Components/CSSfiles/PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="main">
      <div className="pages">
        <div>
          {/* <p className="title">PRIVACY POLICY</p> */}
          <h1 className="title">PRIVACY POLICY</h1>

          <div>
            <p>
              This policy, sets out the basis on which{" "}
              <strong>XPOD Pte. Ltd</strong> a company incorporated under the{" "}
              laws of Singapore with its registered office address 20 CHWEE
              CHIAN ROAD, SINGAPORE 119782 and (Unique Entity Number 202125123D)
              (together with our subsidiaries, our holding company, subsidiaries
              of our holding company from time to time, collectively called "
              <strong>XPOD Pte. Ltd</strong>" or <strong>“we”</strong>) collects
              personal data from you and how we process such data.
            </p>
            <p>
              By visiting our website https://xpod.com.sg (our “
              <strong>Site</strong>”) or using XPOD or any other applications or
              software we provide from time to time (collectively our “
              <strong>Mobile App</strong>”), you accept and consent to the
              practices set out below.
            </p>
          </div>
          <div>
            <p className="collection">
              1. <span className="information">COLLECTION OF INFORMATION</span>
            </p>
            <p className="point">
              1.1
              <span className="purpose">
                For the purposes outlined in Clause 2., we may collect and
                process the following information about you:
              </span>
            </p>
            <div className="component">
              <p className="include">
                (a)
                <span className="credit">
                  Information you give us- information that you provide us
                  (which may include your name, address, email address,
                  telephone number, credit card information and other personal
                  description) by filling in forms on our Site or our Mobile
                  App, or by corresponding with us (by phone, email or
                  otherwise), for example:
                </span>
              </p>
              <div className="account">
                <p className="detail">
                  (i)
                  <span className="mobile">
                    when you register for an account with us in our Mobile App;
                  </span>
                </p>
                <p>
                  (ii){" "}
                  <span className="report">
                    when you report any problem to us;
                  </span>
                </p>
                <p>
                  (iii)
                  <span className="site">
                    when you use certain features on our Site or our Mobile App;
                  </span>
                </p>
                <p>
                  (iv){" "}
                  <span className="request">
                    when you request any support from us; or
                  </span>
                </p>
                <p>
                  (v)
                  <span className="mobile">
                    when you complete any survey or questionnaire we send you.
                  </span>
                </p>
              </div>
            </div>
            <div className="component">
              <p className="include">
                (b)
                <span className="credit">
                  Information we collect about you - information automatically
                  collected when you visit our Site or use our Mobile App, for
                  example:
                </span>
              </p>
              <div className="account">
                <p className="protocol">
                  (i)
                  <span className="internet">
                    technical information, including the Internet protocol (IP)
                    address used to connect your computer to the Internet and
                    your log-in information, browser type and version, time zone
                    setting, browser plug-in types and versions, operating
                    system and platform;
                  </span>
                </p>
                <p className="protocol">
                  (ii)
                  <span className="internet">
                    information about your visit, including the full Uniform
                    Resource Locators (URLs), clickstream to, through and from
                    our site (including date and time), products you viewed or
                    searched for, page response times, download errors, length
                    of visits to certain pages, page interaction information
                    (such as scrolling, clicks, and mouse-overs), methods used
                    to browse away from the page, and any phone number used to
                    call our customer service number.
                  </span>
                </p>
              </div>
            </div>
            <div className="component">
              <p className="include">
                (c)
                <span className="credit">
                  Information we receive from third parties - We work with third
                  parties and we may receive information about you from them,
                  for example, business partners, sub-contractors in technical,
                  payment and delivery services, advertising networks, analytics
                  providers, search information providers, or credit reference
                  agencies. We will notify you when we receive information about
                  you from them and the purposes for which we intend to use that
                  information.
                </span>
              </p>
            </div>
            <p className="point">
              1.2
              <span className="purpose">
                We only retain personal data for so long as it is necessary.
                Data may be archived as long as the purpose for which the data
                was used still exists.
              </span>
            </p>
          </div>
          <div>
            <p className="collection">
              2.{" "}
              <span className="information">USES MADE OF THE INFORMATION</span>
            </p>
            <p className="point">
              2.1
              <span className="purpose">
                We use information held about you for the following purposes:
              </span>
            </p>
            <div className="component">
              <p className="include">
                (a)
                <span className="credit">
                  providing, improving and developing our services;
                </span>
              </p>
              <p className="include">
                (b)
                <span className="credit">
                  researching, designing and launching new features or products;
                </span>
              </p>
              <p className="include">
                (c)
                <span className="credit">
                  presenting content and information in our Site and our Mobile
                  App in the most effective manner for you and for the device
                  you use;
                </span>
              </p>
              <p className="include">
                (d)
                <span className="credit">
                  providing you with alerts, updates, materials or information
                  about our services or other types of information that you
                  requested or signed up to;
                </span>
              </p>
              <p className="include">
                (e)
                <span className="credit">collecting overdue amounts;</span>
              </p>
              <p className="include">
                (f)
                <span className="credit">
                  complying with laws and regulations applicable to us in or
                  outside of Singapore;
                </span>
              </p>
              <p className="include">
                (g)
                <span className="credit">
                  responding or taking part in legal proceedings, including
                  seeking professional advice;
                </span>
              </p>
              <p className="include">
                (h)
                <span className="credit">
                  communicating with you and responding to your questions or
                  requests; and
                </span>
              </p>
              <p className="include">
                (i)
                <span className="credit">
                  purposes directly related or incidental to the above.
                </span>
              </p>
            </div>
          </div>
          <div>
            <p className="collection">
              3.{" "}
              <span className="information">
                DISCLOSURE OF YOUR INFORMATION
              </span>
            </p>
            <p className="personal">
              We will keep your personal data we hold confidential but you agree
              we may provide information to:
            </p>
            <div className="component">
              <p className="include">
                (a)
                <span className="credit">
                  any member of our group, which means our subsidiaries, our
                  ultimate holding company and its subsidiaries, as defined in
                  Section 5 of the Companies Act 1967;
                </span>
              </p>
              <p className="include">
                (b)
                <span className="credit">
                  personnel, agents, advisers, auditors, contractors, financial
                  institutions, and service providers in connection with our
                  operations or services (for example staff engaged in the
                  fulfilment of your order, the processing of your payment and
                  the provision of support services);
                </span>
              </p>
              <p className="include">
                (c)
                <span className="credit">
                  our overseas offices, affiliates, business partners and
                  counterparts (on a need- to-know basis only);
                </span>
              </p>
              <p className="include">
                (d)
                <span className="credit">
                  persons under a duty of confidentiality to us;
                </span>
              </p>
              <p className="include">
                (e)
                <span className="credit">
                  persons to whom we are required to make disclosure under
                  applicable laws and regulations in or outside of Singapore; or
                </span>
              </p>
              <p className="include">
                (f)
                <span className="credit">
                  actual or proposed transferees of our operations (or a
                  substantial part thereof) in or outside of Singapore.
                </span>
              </p>
            </div>
          </div>
          <div>
            <p className="collection">
              4. <span className="information">COOKIES</span>
            </p>
            <p className="point">
              4.1
              <span className="purpose">
                Our Site uses cookies to distinguish you from other users of the
                Site. This helps us to provide you with a good experience when
                you browse our Site and also allows us to improve our Site.
              </span>
            </p>
            <p className="point">
              4.2
              <span className="purpose">
                A cookie is a small file of letters and numbers that we store on
                your browser or the hard drive of your computer if you agree to
                the use of cookies. Cookies contain information that is
                transferred to your computer's hard drive.
              </span>
            </p>
            <p className="point">
              4.3
              <span className="purpose">
                We use persistent cookies and session cookies. A persistent
                cookie stays in your browser and will be read by us when you
                return to our Site or a partner site that uses our services.
                Session cookies only last for as long as the session (usually
                the current visit to a website or a browser session).
              </span>
            </p>
            <p className="point">
              4.4
              <span className="purpose">We use the following cookies:</span>
            </p>
            <div className="component">
              <p className="include">
                (a)
                <span className="credit">
                  Strictly necessary cookies - These are cookies that are
                  required for the operation of our Site. They include, for
                  example, cookies that enable you to log into secure areas of
                  our website, use a shopping cart or make use of e-billing
                  services.
                </span>
              </p>
            </div>
            <p className="point">
              4.5
              <span className="purpose">
                You can block cookies by activating the setting on your browser
                that allows you to refuse the setting of all or some cookies.
                However, if you do so, you may not be able to access all or
                parts of our Site.
              </span>
            </p>
          </div>
          <div>
            <p className="collection">
              5. <span className="information">THIRD-PARTY SITES</span>
            </p>
            <p className="personal">
              Our Site, our App or our communication with you may from time to
              time contain links to third-party websites over which we have no
              control. If you follow a link to any of these websites, please
              note that they have their own practices and policies. We encourage
              you to read the privacy policies or statements of these websites
              understand your rights. We accept no responsibility or liability
              for any practices of third-party websites.
            </p>
          </div>
          <div>
            <p className="collection">
              6. <span className="information">SECURITY</span>
            </p>
            <p className="point">
              6.1
              <span className="purpose">
                All information you provide to us is stored on our secure
                servers.
              </span>
            </p>
            <p className="point">
              6.2
              <span className="purpose">
                Any payment transactions will be encrypted using TLS/SSL
                technology.
              </span>
            </p>
            <p className="point">
              6.3
              <span className="purpose">
                Where we have given you (or where you have chosen) a password
                that enables you to access certain parts of the Site, you are
                responsible for keeping this password confidential. We ask you
                not to share a password with anyone.
              </span>
            </p>
            <p className="point">
              6.4
              <span className="purpose">
                We restrict access to personal information to our employees,
                service providers and contractors on a strictly need-to-know
                basis and ensure that those persons are subject to contractual
                confidentiality obligations.
              </span>
            </p>
            <p className="point">
              6.5
              <span className="purpose">
                We review our information collection, storage and processing
                practices from time to time to guard against unauthorised
                access, processing or use.
              </span>
            </p>
            <p className="point">
              6.6
              <span className="purpose">
                Please note, however, the transmission of information via the
                Internet is not completely secure. Although we will do our best
                to protect your personal data, we cannot guarantee the security
                of your data transmitted to our Site; any transmission is at
                your own risk.
              </span>
            </p>
          </div>
          <div>
            <p className="collection">
              7. <span className="information">YOUR RIGHTS</span>
            </p>
            <p className="point">
              7.1
              <span className="purpose">You have the right to:</span>
            </p>
            <div className="component">
              <p className="include">
                (a)
                <span className="credit">
                  check whether we hold personal data about you;
                </span>
              </p>
              <p className="include">
                (b)
                <span className="credit">
                  access any personal data we hold about you; and
                </span>
              </p>
              <p className="include">
                (c)
                <span className="credit">
                  require us to correct any inaccuracy or error in any personal
                  data we hold about you.
                </span>
              </p>
            </div>
            <p className="point">
              7.2
              <span className="purpose">
                Any request under Clause 7.1 may be subject to a small
                administrative fee to meet our cost in processing your request.
              </span>
            </p>
          </div>
          <div>
            <p className="collection">
              8.{" "}
              <span className="information">CHANGES TO OUR PRIVACY POLICY</span>
            </p>
            <p className="personal">
              We may amend this policy from time to time by posting the updated
              policy on our Site. By continuing to use our Site and our App
              after the changes come into effect means that you agree to be
              bound by the revised policy.
            </p>
          </div>
          <div>
            <p className="collection">
              9. <span className="information">CONTACT US</span>
            </p>
            <p className="personal">
              If you have any questions, comments or requests regarding personal
              data, please address them to:
            </p>
            <p className="personal">
              Wayne Poh, Director, 90060121,{" "}
              <a href="javascript(void)" className="mailname">
                directhomegyme@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
