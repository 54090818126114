import {
  DEBIT_AMOUNT_REQUEST,
  DEBIT_AMOUNT_SUCCESS,
  DEBIT_AMOUNT_FAIL,
  CREDIT_AMOUNT_REQUEST,
  CREDIT_AMOUNT_SUCCESS,
  CREDIT_AMOUNT_FAIL,
} from "../action/actionConstant";

const intialState = {
  status: "",
  data: null,
  isProcess: false,
  message: null,
  isVerify: false,
};

const payment = (state = intialState, action) => {
  const { data } = action;
  switch (action.type) {
    case DEBIT_AMOUNT_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case DEBIT_AMOUNT_SUCCESS:
      return {
        ...state,
        data: data,
        isProcess: false,
      };
    case DEBIT_AMOUNT_FAIL:
      return {
        ...state,
        isProcess: false,
      };
    case CREDIT_AMOUNT_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case CREDIT_AMOUNT_SUCCESS:
      return {
        ...state,
        data: data,
        isProcess: false,
      };
    case CREDIT_AMOUNT_FAIL:
      return {
        ...state,
        isProcess: false,
      };
    default:
      return state;
  }
};

export default payment;
