import { toast } from "react-toastify";

import {
  GET_FEEDBACK_FAIL,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_SUCCESS,
  GET_UNREAD_FEEDBACK,
} from "./actionConstant";
import { getLoginUser } from "../comman/localStorage";
import { functionalityApiCall } from "../comman/connect";
import { GET_FEEDBACK_URL, UNREAD_FEEDBACK_URL } from "../comman/urls";

const getFeedbackRequested = () => ({
  type: GET_FEEDBACK_REQUEST,
});

const getFeedbackSuccess = (data) => ({
  type: GET_FEEDBACK_SUCCESS,
  payload: data,
});
const getUnreadFeedback = (data) => ({
  type: GET_UNREAD_FEEDBACK,
  payload: data,
});

const getFeedbackFailure = () => ({
  type: GET_FEEDBACK_FAIL,
});

export const getFeedbackList = (filter, byGym) => (dispatch) => {
  dispatch(getFeedbackRequested());
  const { token } = getLoginUser();
  if (token) {
    const data = {
      page: 1,
      limit: 1000,
      sort: `updated_at ${filter ? filter : "desc"}`,
    };
    if (byGym && byGym !== 0) {
      data.query = {
        gym_id: byGym,
      };
    }
    const url = GET_FEEDBACK_URL;
    const onSuccess = (response) => {
      dispatch(getFeedbackSuccess(response.data));
    };

    const onFailure = (response) => {
      dispatch(getFeedbackFailure());
      toast.error(response.message);
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const getUnreadFeedbackCount = () => (dispatch) => {
  const { token } = getLoginUser();
  if (token) {
    const url = UNREAD_FEEDBACK_URL;
    const onSuccess = (response) => {
      console.log("esponse.data", response.data);
      dispatch(getUnreadFeedback(response.data));
    };

    const onFailure = () => {};

    functionalityApiCall("GET", url, "", onSuccess, onFailure, token);
  }
};
