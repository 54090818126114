
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../Pages/Home/Home";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Login from "../Pages/Login_and_Registration/Login";
import ResetPassword from "../Pages/Login_and_Registration/ResetPassword";
import ForgotPassword from "../Pages/Login_and_Registration/ForgotPassword";

const PublicRoute = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/" element={<Home />} />

      <Route exact path="/home" element={<Home />} />
      <Route exact path="*" element={<Navigate to="/" replace />} />
      {/* <PublicRoute path="/register" element={Registration} /> */}
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default PublicRoute;
