import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";

import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
import { validationFields } from "../comman/Hooks";
import AddGroup from "../Components/svgImg/AddGroup.svg";
import { addGroupDetails } from "../action/groupDetails";
import { editGymUser, getGymUsers } from "../action/gymUsers";

import "../Components/CSSfiles/GroupDetails.css";

function GroupCreate() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [groupDetail, setGroupDetail] = useState({
    advance_booking: null,
    book_amendment: null,
    cancellation_fee: null,
    discount: null,
    max_slot: null,
    name: null,
    period: null,
    price: null,
  });
  const [formErrors, setFormErrors] = useState([]);
  const [groupId, setGroupId] = useState();
  const [month, setMonth] = useState("Per Month...");
  const isLoading = useSelector((state) => state.groupDetail.isProcess);
  const isUserLoading = useSelector((state) => state.gymUsers.isProcess);
  const userList = useSelector((state) => state.gymUsers.data?.list);
  const [userData, setUserData] = useState(userList);

  useEffect(() => {
    dispatch(getGymUsers("", 1, 200));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newArr = [];
    if (userList) {
      userList.forEach((item) => {
        if (!item.group_id) {
          newArr.push(item);
        }
      });
      setUserData([...newArr]);
    }
  }, [userList]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setGroupDetail((groupDetail) => ({
        ...groupDetail,
        [name]: value,
      }));
    } else {
      setGroupDetail((groupDetail) => ({
        ...groupDetail,
        [name]: parseInt(value),
      }));
    }
  };
  const handleDropdown = (name, e) => {
    setGroupDetail({
      ...groupDetail,
      [name]: parseInt(e),
    });
  };

  const handleAddMember = (id) => {
    if (groupId) {
      let obj = {
        group_id: groupId,
        group_join_date: moment(new Date()).format("YYYY-MM-DD  HH:mm:ss"),
      };

      const succesCallBack = () => {
        dispatch(getGymUsers("", 1, 200));
        toast.success("Added...");
      };
      dispatch(editGymUser(id, obj, succesCallBack));
    } else {
      toast.error("Please create a group First");
    }
  };

  const handleSubmit = () => {
    const validFieldArray = validationFields(groupDetail);
    if (validFieldArray.length === 0) {
      const successCallBack = (response) => {
        setFormErrors([]);
        setGroupId(response);
        toast.success("Group added! now add a member");
      };
      dispatch(addGroupDetails(groupDetail, successCallBack));
    } else {
      setFormErrors([...validFieldArray]);
      toast.error("Fields are required");
    }
  };
  const handleSearch = (data) => {
    dispatch(getGymUsers(data, 1, 200));
  };

  const debounce_fun = _.debounce(function (response) {
    handleSearch(response);
  }, 500);

  const header = [
    {
      name: "Photo",
      selector: (row) => row.image,
      cell: (selectors) => (
        <img src={selectors.image} alt={"user profile"} className="userImage" />
      ),
    },
    { name: "ID", selector: (row) => row.id, sortable: true },
    {
      name: "Last Booking Date",
      selector: (row) => moment(row.last_booking_date).format("YYYY-MM-DD"),
      sortable: true,
    },
    { name: "User Name", selector: (row) => row.username, sortable: true },
    { name: "First Name", selector: (row) => row.first_name, sortable: true },
    { name: "Gender", selector: (row) => row.gender, sortable: true },
    {
      name: "Group Status",
      selector: (row) => row.group_id_name,
      cell: (selectors) =>
        selectors.group_id_name ? selectors.group_id_name : "-",
      sortable: true,
    },
    {
      name: "Number Of Booking",
      selector: (row) => row.number_of_bookings,
      sortable: true,
    },
    {
      name: "Total Spent",
      selector: (row) => row.total_spent,
      sortable: true,
      cell: (selectors) => (selectors.total_spent ? selectors.total_spent : 0),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (selectors) =>
        !selectors.group_id ? (
          <div className="d-flex align-items-center">
            <button
              className="btn addGroupBtn"
              onClick={() => handleAddMember(selectors.id)}
            >
              <img src={AddGroup} alt="edit" />
            </button>
          </div>
        ) : null,
    },
  ];

  return (
    <div className="mainboxes">
      <TopHeader title={"Create New Group"} />
      <div className="row align-items-center  justify-content-end searchMain unsetWidth pb-2 mb-3">
        {/* <div className="col-xxl-1 col-lg-2 col-md-3 col-sm-6">
          <p className="topBarTitle">Group Name</p>
          <p className="topDetail topGroupDetail">Ordinary</p>
        </div>
        <div className="col-xxl-1 col-lg-2 col-md-3 col-sm-6">
          <p className="topBarTitle">Number Of User</p>
          <p className="topDetail topGroupDetail">3344</p>
        </div>
        <div className="col-xxl-1 col-lg-2 col-md-3 col-sm-6">
          <p className="topBarTitle">Discount %</p>
          <p className="topDetail topGroupDetail">% 20</p>
        </div>
        <div className="col-xxl-2 col-lg-2 col-md-3 col-sm-6 nanoSpacing">
          <p className="topBarTitle">Advance Booking #</p>
          <p className="topDetail topGroupDetail">7 days</p>
        </div>
        <div className="col-xxl-2 col-lg-2 col-md-3 col-sm-6 tabletSpacing">
          <p className="topBarTitle">Cencellation Fee</p>
          <p className="topDetail topGroupDetail">%5</p>
        </div>
        <div className="col-xxl-1 col-lg-2 col-md-3 col-sm-6 tabletSpacing">
          <p className="topBarTitle">Max Current Slot</p>
          <p className="topDetail topGroupDetail">6</p>
        </div>
        <div className="col-xxl-2 col-lg-6 col-md-3 col-sm-6 tabletSpacing laptopSpace">
          <p className="topBarTitle">Booking amendment #</p>
          <p className="topDetail topGroupDetail">72 hour</p>
        </div> */}
        <div
          className="col-xxl-2 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center
         justify-content-end tabletSpacing laptopSpace mobileWidth"
        >
          <button
            className="btn topCancel mx-2"
            onClick={() => {
              history("/group-listing");
            }}
          >
            Cancel
          </button>
          <button
            className="btn saveBtn franchBtn mobileGroupBtn"
            onClick={() => handleSubmit()}
          >
            {!isLoading ? (
              "Save"
            ) : (
              <TailSpin heigth="15" width="45" color="white" />
            )}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-12 col-lg-12">
          <div className="borderShadowBox franchiseeDetailBox my-3">
            <div className="row">
              <div className="col-xxl-2 col-drop-2 tabletGroupNameSize">
                <p className="topBarTitle">Group Name</p>{" "}
                <input
                  type="text"
                  className={`input franchiseDetailInput ${
                    formErrors.includes("name") && "error"
                  }`}
                  name="name"
                  onChange={handleInput}
                />
              </div>
              <div className="col-xxl-10 col-lg-10 groupInputDiv flex-wrap">
                <div className="widthFour px-2">
                  <p className="topBarTitle">Price</p>
                  <div className="priceInput">
                    <input
                      type="number"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("price") && "error"
                      }`}
                      name="price"
                      pattern="[0-9]*"
                      onChange={handleInput}
                    />
                    <span>$</span>
                  </div>
                </div>
                <div className="px-2 widthFour">
                  <p className="topBarTitle">Period</p>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={month}
                    className={`bg-transparent notificationDrop ${
                      formErrors.includes("period") && "error"
                    }`}
                    onSelect={(e) => {
                      e = e.split(",");
                      setMonth(e[1]);
                      handleDropdown("period", e[0]);
                    }}
                  >
                    <Dropdown.Item eventKey={[1, "1 Month"]}>
                      1 Month
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={[2, "1 Year"]}>
                      1 Year
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
                <div className="px-2 widthFour">
                  <p className="topBarTitle">Max Current Slot</p>
                  <input
                    type="number"
                    className={`input franchiseDetailInput ${
                      formErrors.includes("max_slot") && "error"
                    }`}
                    name="max_slot"
                    pattern="[0-9]*"
                    onChange={handleInput}
                  />
                </div>
                <div className="px-2 widthFour">
                  <p className="topBarTitle">Discount %</p>
                  <div className="priceInput">
                    <input
                      type="number"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("discount") && "error"
                      }`}
                      name="discount"
                      pattern="[0-9]*"
                      onChange={handleInput}
                    />
                    <span>%</span>
                  </div>
                </div>
                <div className="px-2 widthFour">
                  <p className="topBarTitle">Advance Booking #</p>
                  <input
                    type="number"
                    className={`input franchiseDetailInput ${
                      formErrors.includes("advance_booking") && "error"
                    }`}
                    name="advance_booking"
                    pattern="[0-9]*"
                    onChange={handleInput}
                  />
                </div>
                <div className="px-2 widthTwo">
                  <p className="topBarTitle">Cancellation Fee</p>
                  <div className="priceInput">
                    <input
                      type="number"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("cancellation_fee") && "error"
                      }`}
                      name="cancellation_fee"
                      pattern="[0-9]*"
                      onChange={handleInput}
                    />
                    <span>%</span>
                  </div>
                </div>
                <div className="px-2 widthTwo">
                  <p className="topBarTitle">Booking amendment #</p>
                  <input
                    type="number"
                    className={`input franchiseDetailInput ${
                      formErrors.includes("book_amendment") && "error"
                    }`}
                    name="book_amendment"
                    pattern="[0-9]*"
                    onChange={handleInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-xxl-12 col-lg-12 d-flex align-items-center">
          <h3
            className="m-0 detailHeading 
                  "
          >
            User Included The Group
          </h3>
          <div className="mx-3">
            <SearchBar placeholder={"Search User"} search={debounce_fun} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          {isUserLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : userData.length > 0 ? (
            <DataTable
              columns={header}
              data={userData ? userData : []}
              pagination
            />
          ) : (
            <div className="d-flex justify-content-center">
              All users have been added to groups.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GroupCreate;
