import { toast } from "react-toastify";

import {
  GET_GYMUSER_FAIL,
  EDIT_GYMUSER_FAIL,
  GET_GYMUSER_SUCCESS,
  GET_GYMUSER_REQUEST,
  EDIT_GYMUSER_SUCCESS,
  EDIT_GYMUSER_REQUEST,
  GET_USERCOUNT_REQUEST,
  GET_USERCOUNT_SUCCESS,
  GET_USERCOUNT_FAIL,
} from "./actionConstant";
import { getLoginUser } from "../comman/localStorage";
import {
  functionalityApiCall,
  functionalityDeleteApiCall,
} from "../comman/connect";
import {
  DELETE_GYMUSER_URL,
  EDIT_GYMUSER_URL,
  GET_DASHBOARD_COUNT_URL,
  GET_GYMUSER_URL,
} from "../comman/urls";
import moment from "moment";

const userRequested = () => ({
  type: GET_GYMUSER_REQUEST,
});

const userSuccess = (data) => ({
  type: GET_GYMUSER_SUCCESS,
  data,
});

const userFailure = (data) => ({
  type: GET_GYMUSER_FAIL,
  data,
});

const editUserRequested = () => ({
  type: EDIT_GYMUSER_REQUEST,
});

const editUserSuccess = (data) => ({
  type: EDIT_GYMUSER_SUCCESS,
  data,
});

const editUserFailure = (data) => ({
  type: EDIT_GYMUSER_FAIL,
  data,
});

const getUserCountRequested = () => ({
  type: GET_USERCOUNT_REQUEST,
});

const getUserCountSuccess = (data) => ({
  type: GET_USERCOUNT_SUCCESS,
  data,
});

const getUserCountFailure = (data) => ({
  type: GET_USERCOUNT_FAIL,
  data,
});

export const getGymUsers =
  (search, page, limit, filter, filterColumn, other) => (dispatch) => {
    dispatch(userRequested());
    const { token } = getLoginUser();
    if (token) {
      const data = {
        page: page,
        limit: limit,
        sort: `${filterColumn ? filterColumn : "id"} ${
          filter ? filter : "desc"
        }`,
        search: [
          {
            tableNo: "0",
            search: search,
            matchFields: [
              "first_name",
              "id",
              "username",
              "first_name",
              "last_name",
              "gender",
            ],
          },
          {
            tableNo: "1",
            search: search,
            matchFields: ["name"],
          },
        ],
        populate: [
          { tableName: "groups", key: "group_id", projection: ["name"] },
        ],
      };
      if (other) {
        other.sms_verified = 1;
        data.query = other;
      } else {
        data.query = {
          sms_verified: 1,
        };
      }
      const url = GET_GYMUSER_URL;
      const onSuccess = (response) => {
        dispatch(userSuccess(response));
      };

      const onFailure = (response) => {
        dispatch(userFailure(response));
        toast.error(response.message);
      };

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };

export const getGymUserById = (search, successCallBack) => (dispatch) => {
  const { token } = getLoginUser();
  if (token) {
    const data = {
      page: 1,
      limit: 200,
      sort: "id desc",
      search: [
        {
          tableNo: "0",
          search: search,
          matchFields: ["first_name", "id"],
        },
      ],
      populate: [
        { tableName: "groups", key: "group_id", projection: ["name"] },
      ],
    };
    const url = GET_GYMUSER_URL;
    const onSuccess = (response) => {
      successCallBack(response.data?.list[0]);
    };

    const onFailure = (response) => {
      toast.error(response.message);
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const editGymUser = (id, requestData, successCallBack) => (dispatch) => {
  dispatch(editUserRequested());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(editUserSuccess(response));
      successCallBack(response);
    };
    const url = `${EDIT_GYMUSER_URL}/${id}`;

    const onFailure = (response) => {
      dispatch(editUserFailure(response));
      toast.error(response.message || response.data.message);
    };

    functionalityApiCall("PUT", url, requestData, onSuccess, onFailure, token);
  }
};
export const deleteGymUser = (id, successCallBack) => (dispatch) => {
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      successCallBack(response);
    };
    const url = `${DELETE_GYMUSER_URL}/${id}`;
    functionalityDeleteApiCall(url, onSuccess, token);
  }
};

export const getGymCount = (start_date) => (dispatch) => {
  dispatch(getUserCountRequested());
  const { token } = getLoginUser();
  if (token) {
    const data = {
      startDate: start_date,
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    };
    const url = GET_DASHBOARD_COUNT_URL;
    const onSuccess = (response) => {
      dispatch(getUserCountSuccess(response.data));
    };

    const onFailure = (response) => {
      dispatch(getUserCountFailure(response.data));
      toast.error(response.message);
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
