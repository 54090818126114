import {
  LOGOUT,
  LOGIN_FAIL,
  REGISTER_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  FORGOTPASS_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  FORGOTPASS_REQUEST,
  FORGOTPASS_SUCCESS,
  LOGIN_USER_DETAILS_FAIL,
  LOGIN_USER_DETAILS_SUCCESS,
  LOGIN_USER_DETAILS_REQUEST,
} from "../action/actionConstant";

const intialState = {
  loginUser: null,
  isProcess: false,
  message: null,
  userDetails: null,
};

const login = (state = intialState, action) => {
  const { data } = action;
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isProcess: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isProcess: false,
        message: data.message,
        loginUser: data.data ? data.data : data,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isProcess: false,
        loginUser: null,
        message: data?.message,
      };
    case LOGIN_USER_DETAILS_REQUEST:
      return {
        ...state,
        isProcess: true,
        userDetails: null,
      };
    case LOGIN_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isProcess: false,
        message: data.message,
        userDetails: data,
      };
    case LOGIN_USER_DETAILS_FAIL:
      return {
        ...state,
        isProcess: false,
        message: data.message,
        userDetails: null,
      };
    case LOGOUT:
      return {
        ...state,
        isProcess: false,
        message: "Successfully logout",
        loginUser: null,
        userDetails: null,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        isProcess: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isProcess: false,
        message: data.message,
        loginUser: data.data,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isProcess: false,
        message: data.message,
        loginUser: null,
      };
    case FORGOTPASS_REQUEST:
      return {
        ...state,
      };
    case FORGOTPASS_SUCCESS:
      return {
        ...state,

        message: data.message,
      };
    case FORGOTPASS_FAIL:
      return {
        ...state,

        message: data.message,
      };
    default:
      return state;
  }
};

export default login;
