import { useSelector } from "react-redux";

import PublicRoute from "./publicRouter";
import PrivateRoute from "./privateRouter";
import { getLoginUser } from "../comman/localStorage";

const Routing = () => {
  const userToken =
    useSelector((state) => state.login.loginUser?.token) || getLoginUser();

  if (userToken) return <PrivateRoute />;
  else return <PublicRoute />;
};

export default Routing;
