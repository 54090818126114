import React, { useState } from "react";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";

import TopHeader from "./TopHeader";
import { Pagination } from "./Pagination";
import { profileTableList } from "./GymItem";
import FileIcon from "../Components/svgImg/File.svg";
import CameraIcon from "../Components/svgImg/Camera.svg";
import MessageIcon from "../Components/svgImg/Message.svg";
import ProfileImage from "../Components/GymPics/profile.png";

import "../Components/CSSfiles/Profile.css";

function ProfileDetail(props) {
  const [bookings, setBookings] = useState(true);
  const [currentItems, setCurrentItems] = useState([]);

  const Paginate = (data) => {
    setCurrentItems(data);
  };

  const handleBooking = () => {
    setBookings(!bookings);
  };

  return (
    <div className="mainboxes">
      <TopHeader title={"Tom Lark"} />
      <div className="row align-items-center searchMain pb-2 mb-3">
        <div className="col-xxl-9 col-lg-9 d-flex align-items-center justify-content-between">
          <div>
            <p className="topBarTitle">ID</p>
            <p className="topDetail topGroupDetail">00034</p>
          </div>
          <div>
            <p className="topBarTitle">Member Status</p>
            <p className="topDetail topGroupDetail">Active</p>
          </div>
          <div>
            <p className="topBarTitle">Username</p>
            <p className="topDetail topGroupDetail">Creazy Boys</p>
          </div>
          <div>
            <p className="topBarTitle">First Name</p>
            <p className="topDetail topGroupDetail">Tom Lark</p>
          </div>
          <div>
            <p className="topBarTitle">Gender</p>
            <p className="topDetail topGroupDetail">Male</p>
          </div>
          <div>
            <p className="topBarTitle">Group Status</p>
            <p className="topDetail topGroupDetail">Gold</p>
          </div>
          <div>
            <p className="topBarTitle">Number Of Booking</p>
            <p className="topDetail topGroupDetail">42</p>
          </div>
          <div>
            <p className="topBarTitle">Total Spent</p>
            <p className="topDetail topGroupDetail">$ 754.00</p>
          </div>
        </div>
        <div className="col-xxl-3 col-lg-3 d-flex align-items-center justify-content-end">
          <button className="btn topCancel mx-2">Cancel</button>
          <button className="btn saveBtn">Save</button>
        </div>
      </div>

      <div className="row">
        <div className="col-xxl-3 col-lg-12">
          <div className="row">
            <div className="col-xxl-12 col-lg-6 mt-3">
              <div className="borderShadowBox profilePicBox">
                <img
                  className="profileImage"
                  src={ProfileImage}
                  alt="profile"
                />
                <button className="btn chnagePicBtn">
                  <img src={CameraIcon} alt="cam" className="mx-1" /> Change
                  Photo
                </button>
              </div>
            </div>
            <div className="col-xxl-12 col-lg-6">
              <div className="borderShadowBox userWalletBox">
                <div className="msgBtnDiv">
                  <button className="btn msgUserBtn">
                    <img src={MessageIcon} alt="cam" className="mx-2" /> Message
                    User
                  </button>
                </div>
                <div className="userWalletDiv">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <span className="userBoxTitle">Wallet Balance</span>
                      <p className="m-0 userBoxValue changeValuecolor mt-1">
                        $ 588.00 USD
                      </p>
                    </div>
                    <div>
                      <span className="userBoxTitle">Total Spent</span>
                      <p className="m-0 userBoxValue mt-1">$754.00</p>
                    </div>
                  </div>
                  <div className="cdBtns">
                    <button className="btn userCDBtn">Credit</button>
                    <button className="btn userCDBtn mx-2">Debit</button>
                  </div>
                </div>
                <div className="cardDetailMain">
                  <p className="userBoxTitle">Default Credit Card</p>
                  <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <span className="userBoxTitle">My Card TEB</span>
                      <p className="m-0 mt-1 fs-5">**** **** **** 4545</p>
                    </div>
                    <button className="btn userCDBtn">Credit</button>
                  </div>
                </div>
                <div className="cdBtnsDiv">
                  <button className="btn userCDBtn">Delete User</button>
                  <button className="btn userCDBtn mx-2">Ban User</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-9 col-lg-12">
          <div className="borderShadowBox userProfileBox my-3">
            <div className="row">
              <div className="col-xxl-4 col-lg-4">
                <div>
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    User Name
                  </p>
                  <input
                    type="text"
                    className="input franchiseDetailInput"
                    value={"Crazy Boys"}
                  />
                </div>
                <div className="mt-4">
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    First Name
                  </p>
                  <input
                    type="text"
                    className="input franchiseDetailInput"
                    value={"Tom"}
                  />
                </div>
                <div className="mt-4">
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Email Address
                  </p>
                  <input
                    type="text"
                    className="input franchiseDetailInput"
                    value={"TomLarek@gmail.com"}
                  />
                </div>
              </div>

              <div className="col-xxl-4 col-lg-4">
                <div className="d-flex align-items-center">
                  <div className="groupDrop px-1">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Group Status
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Gold"
                      className="bg-transparent profileDrop"
                    >
                      <Dropdown.Item href="#/action-1">Male</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Female</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Other</Dropdown.Item>
                    </DropdownButton>
                  </div>
                  <div className="expiryDrop px-1">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Group Expiry Date
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Select Date"
                      className="bg-transparent profileDrop"
                    >
                      <Dropdown.Item href="#/action-1">Male</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Female</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Other</Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Last Name
                  </p>
                  <input
                    type="text"
                    className="input franchiseDetailInput"
                    value={"Lark"}
                  />
                </div>
                <div className="mt-4">
                  <p className="topBarTitle franchiseeDetailBoxTitle">Mobile</p>
                  <input
                    type="text"
                    className="input franchiseDetailInput"
                    value={"90324034 0034"}
                  />
                </div>
              </div>

              <div className="col-xxl-3 col-lg-3">
                <div>
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Group Renewal Status
                  </p>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Automatic"
                    className="bg-transparent groupThirdDrop"
                  >
                    <Dropdown.Item href="#/action-1">Male</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Female</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Other</Dropdown.Item>
                  </DropdownButton>
                </div>
                <div className="mt-4">
                  <p className="topBarTitle franchiseeDetailBoxTitle">Gender</p>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Male"
                    className="bg-transparent groupThirdDrop"
                  >
                    <Dropdown.Item href="#/action-1">Male</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Female</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Other</Dropdown.Item>
                  </DropdownButton>
                </div>
                <div className="mt-4">
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Dob (optional)
                  </p>
                  <div className="d-flex align-items-center">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="00"
                      className="bg-transparent profileDateDrop"
                    >
                      <Dropdown.Item href="#/action-1">Gym 1</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Gym 2</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Gym 3</Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="00"
                      className="bg-transparent profileDateDrop centerSpace"
                    >
                      <Dropdown.Item href="#/action-1">Gym 1</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Gym 2</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Gym 3</Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="00"
                      className="bg-transparent profileDateDrop"
                    >
                      <Dropdown.Item href="#/action-1">Gym 1</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Gym 2</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Gym 3</Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="verifyProfileMain">
              <div>
                <p className="topBarTitle franchiseeDetailBoxTitle">
                  Vaccination Status
                </p>
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Verified"
                  className="bg-transparent groupThirdDrop"
                >
                  <Dropdown.Item href="#/action-1">Male</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Female</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Other</Dropdown.Item>
                </DropdownButton>
              </div>
              <div className="verifiedSec">
                <img src={FileIcon} alt="file" />
                <p className="m-0 fileText mt-2">Click here to view file</p>
              </div>
            </div>
            {bookings && (
              <p className="topBarTitle joined m-0 mt-3">
                User Joined Date: 04.22. 2019 16:00
              </p>
            )}
          </div>
          <div className="borderShadowBox profileTableBox my-3">
            <div className="d-flex align-items-center mb-2">
              <p
                className={`btn ${
                  bookings ? "bookingsBtnActive" : "bookingsBtn"
                }`}
                onClick={() => handleBooking(1)}
              >
                Current bookings
              </p>
              <p
                className={`btn mx-4 ${
                  !bookings ? "bookingsBtnActive" : "bookingsBtn"
                }`}
                onClick={() => handleBooking(2)}
              >
                booking history
              </p>
            </div>
            <div className="row">
              <div className="table-responsive">
                <Table className="overflow-auto">
                  <tr>
                    <th></th>
                    <th>Id</th>
                    <th>Gym Type</th>
                    <th>Address</th>
                    <th>Date - Hour</th>
                    <th>Price</th>
                    <th>Status</th>
                  </tr>
                  <tbody>
                    {currentItems.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <img
                              src={item.Photo}
                              alt="icon"
                              className="profileDetailPhoto"
                            />
                          </td>
                          <td>{item.id}</td>
                          <td>{item.type}</td>
                          <td>{item.address}</td>
                          <td>{item.date}</td>
                          <td>{item.price}</td>
                          <td>{bookings ? item.status : "Completed"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="mb-8 d-flex align-items-center justify-content-end">
                <Pagination
                  data={profileTableList}
                  changePage={Paginate}
                  perPage={8}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileDetail;
