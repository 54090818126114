/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";

import TopHeader from "./TopHeader";
import Pic1 from "../Components/GymPics/user.png";
import { getFeedbackList } from "../action/feedback";
import { getGymDetails } from "../action/gymDetails";
import { getChatUserList, markAsUnread } from "../action/chat";

import "../Components/CSSfiles/Feedback.css";
import moment from "moment";

var _ = require("lodash");
function Feedback() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [filter, setFilter] = useState("Most Recent");
  const [userId, setUserId] = useState("");
  const [gymName, setGymName] = useState("All");
  const feedbackList = useSelector((state) =>
    state.feedback?.feedbackList ? state.feedback?.feedbackList?.list : []
  );
  const isLoading = useSelector((state) => state.feedback?.isProcess);
  const conversationLoader = useSelector((state) => state.chat?.isProcess);
  const conversationList = useSelector((state) =>
    state.chat?.conversationList ? state.chat?.conversationList : []
  );
  const gymList = useSelector((state) =>
    state.gymDetail.gymList ? state.gymDetail.gymList : []
  );

  useEffect(() => {
    dispatch(getChatUserList("", 1, 1000));
    dispatch(getFeedbackList(""));
    dispatch(getGymDetails("", 1, 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateChat = async (id, gymId) => {
    setUserId(id);
    let data = _.find(conversationList, function (n) {
      if (n.customer_id === id || n.staff_id === id) return true;
      else return false;
    });
    if (data) history("/feedback-chat", { conversationDetails: data });
  };

  // useEffect(() => {
  //   if (
  //     chatRes === "Conversation created successfully" ||
  //     chatRes === "Conversation already exist"
  //   ) {
  //     if (conversationList?.length > 0) {
  //       let data = _.find(conversationList, function (n) {
  //         if (n.customer_id === userId || n.staff_id === userId) return true;
  //         else return false;
  //       });
  //       if (data) {
  //         history("/feedback-chat", { conversationDetails: data });
  //         dispatch(createChatSuccess(null));
  //       }
  //     }
  //   }
  // }, [chatRes, conversationList]);

  const handleFilter = (value) => {
    dispatch(getFeedbackList(value));
  };

  const handleColumnFilter = (value) => {
    dispatch(getFeedbackList("", parseInt(value)));
  };

  const handleMarkAsUnread = (id) => {
    const successCallBack = () => {
      dispatch(getFeedbackList(""));
      window.location.reload();
    };
    dispatch(markAsUnread(id, successCallBack));
  };

  const header = [
    {
      name: "Photo",
      selector: (row) => row.photo,
      sortable: true,
      cell: (selectors) => (
        <td>
          <img
            src={selectors.photo ? selectors.photo : Pic1}
            alt="img"
            className="gymPhoto"
          />
        </td>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.customer_id_first_name,
      sortable: true,
      cell: (selectors) =>
        conversationList?.map((item, index) => {
          if (item?.feedback_id === selectors?.id) {
            return (
              <div className="d-flex align-items-center" key={index}>
                {item?.customer_id_first_name +
                  " " +
                  item?.customer_id_last_name}
              </div>
            );
          }
          return null;
        }),
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      cell: (selectors) => (
        <div className="d-flex align-items-center">
          <p>{`${selectors.description} ${selectors.other_report}`}</p>
        </div>
      ),
    },
    {
      name: "",
      selector: "",
      cell: (selectors) => {
        if (
          Boolean(
            _.find(conversationList, function (o) {
              return o.feedback_id === selectors.id && o.unread_count_staff > 0;
            })
          )
        ) {
          return (
            <div className="d-flex align-items-center">
              {conversationList?.map((item, index) => {
                if (item?.feedback_id === selectors?.id) {
                  return (
                    <span
                      key={index}
                      className={`ranslate-middle badge rounded-pill notificationBadge bg-danger`}
                    >
                      {item.unread_count_staff}
                    </span>
                  );
                }
                return null;
              })}
            </div>
          );
        }
      },
    },
    {
      name: "Date/Time",
      selector: (row) => row.description,
      sortable: true,
      cell: (selectors) =>
        moment(selectors.created_at).format("DD/MM/YYYY hh:mm"),
    },
    {
      name: "Chat",
      selector: "action",
      cell: (selectors) => (
        <div className="d-flex align-items-center">
          <button
            className="btn btn-primary chatBtn"
            onClick={() => {
              handleCreateChat(selectors.user_id, selectors.gym_id);
            }}
          >
            {conversationLoader && selectors.id === userId ? (
              <TailSpin color="white" height={"20"} width={"20"} />
            ) : (
              "Chat"
            )}
          </button>
          <button
            disabled={Boolean(
              _.find(conversationList, function (o) {
                if (
                  o.feedback_id === selectors.id &&
                  o.unread_count_staff > 0
                ) {
                  return true;
                } else {
                  return false;
                }
              })
            )}
            className="btn btn-primary chatBtn mx-2"
            onClick={() => {
              conversationList?.forEach((item) => {
                item?.feedback_id === selectors?.id &&
                  handleMarkAsUnread(item.id);
              });
            }}
          >
            Mark As Unread
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="mainboxes">
      <TopHeader title={"Feedback"} />
      <div className="row align-items-center searchMain pb-3 mb-3">
        <div className="col-xxl-12 col-lg-12 d-flex align-items-center justify-content-end">
          <DropdownButton
            id="dropdown-basic-button"
            title={gymName}
            className="bg-transparent listDropDown"
            onSelect={(e) => {
              e = e.split(",");
              setGymName(e[1]);
              handleColumnFilter(e[0]);
            }}
          >
            <Dropdown.Item eventKey={[0, "All"]}>{"All"}</Dropdown.Item>
            {gymList.map((data, index) => (
              <Dropdown.Item key={index} eventKey={[data.id, data.name]}>
                {data.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <DropdownButton
            id="dropdown-basic-button"
            title={filter}
            className="bg-transparent listDropDown mx-5"
            onSelect={(e) => {
              setFilter(e === "desc" ? "Most Recent" : "Oldest");
              handleFilter(e);
            }}
          >
            <Dropdown.Item eventKey={"desc"}>Most Recent</Dropdown.Item>
            <Dropdown.Item eventKey={"asc"}>Oldest</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="row">
        <div className="row">
          <div className="table-responsive">
            {isLoading && feedbackList?.length === 0 ? (
              <div className="spinner">
                <TailSpin heigth="75" width="100" color="black" />
              </div>
            ) : (
              <DataTable
                columns={header}
                data={feedbackList ? feedbackList : []}
                pagination
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
