import moment from "moment";
import { toast } from "react-toastify";

import {
  DELETE_BOOKINGS_REQUEST,
  DELETE_BOOKINGS_SUCCESS,
  GET_BOOKCOUNT_FAIL,
  GET_BOOKCOUNT_REQUEST,
  GET_BOOKCOUNT_SUCCESS,
  GET_BOOKINGS_FAIL,
  GET_BOOKINGS_REQUEST,
  GET_BOOKINGS_SUCCESS,
} from "./actionConstant";
import { getLoginUser } from "../comman/localStorage";
import {
  DELETE_BOOKING_URL,
  GET_BOOKINGS_URL,
  GET_BOOKING_URL,
} from "../comman/urls";
import {
  functionalityApiCall,
  functionalityDeleteApiCall,
} from "../comman/connect";

const getBookingsRequested = () => ({
  type: GET_BOOKINGS_REQUEST,
});

const getBookingsSuccess = (data) => ({
  type: GET_BOOKINGS_SUCCESS,
  data,
});

const getBookingsFailure = () => ({
  type: GET_BOOKINGS_FAIL,
});

const deleteBookingsRequested = () => ({
  type: DELETE_BOOKINGS_REQUEST,
});

const deleteBookingsSuccess = (data) => ({
  type: DELETE_BOOKINGS_SUCCESS,
  data,
});

const getBookCountRequested = () => ({
  type: GET_BOOKCOUNT_REQUEST,
});

const getBookCountSuccess = (data) => ({
  type: GET_BOOKCOUNT_SUCCESS,
  data,
});

const getBookCountFailure = () => ({
  type: GET_BOOKCOUNT_FAIL,
});

export const getBookings =
  (search, gymSearch, page, limit, filter, filterBy) => (dispatch) => {
    dispatch(getBookingsRequested());

    const { token } = getLoginUser();

    if (token) {
      const data = {
        page: page,
        limit: limit,
        sort: `updated_at ${filter ? filter : "desc"}`,
        search: [
          {
            tableNo: 0,
            search: search,
            matchFields: ["booking_id"],
          },
          {
            tableNo: 1,
            search: search,
            matchFields: ["first_name", "username", "email"],
          },
          {
            tableNo: 2,
            search: gymSearch,
            matchFields: ["name", "address"],
          },
        ],
        populate: [
          {
            tableName: "user",
            key: "user_id",
            projection: ["first_name", "username", "email", "image"],
          },
          {
            tableName: "gym",
            key: "gym",
            projection: ["name", "address"],
          },
        ],
      };

      if (filterBy) {
        data.query = filterBy;
      }

      const url = GET_BOOKINGS_URL;

      const onSuccess = (response) => {
        dispatch(getBookingsSuccess(response.data.list));
      };

      const onFailure = (response) => {
        dispatch(getBookingsFailure());
        toast.error(response.message);
      };

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };

export const deleteBooking = (id) => (dispatch) => {
  dispatch(deleteBookingsRequested());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(deleteBookingsSuccess(response));
      toast.success("Deleted Successfully");
    };
    const url = `${DELETE_BOOKING_URL}/${id}`;

    functionalityDeleteApiCall(url, onSuccess, token);
  }
};

export const getBookingCount = (start_date) => (dispatch) => {
  dispatch(getBookCountRequested());
  const { token } = getLoginUser();
  if (token) {
    const data = {
      startDate: start_date,
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    };
    const url = GET_BOOKING_URL;
    const onSuccess = (response) => {
      dispatch(getBookCountSuccess(response.data));
    };

    const onFailure = (response) => {
      dispatch(getBookCountFailure());
      toast.error(response.message);
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
