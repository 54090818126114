import MenuButton from "../Pages/MenuButton";

const MyProSidebarProvider = ({ children }) => {
  return (
    <div className="container-fluid">
      <div className="row sidebarSpace">
        <div className="col-xxl-1 col-lg-1 col-md-1 col-sm-0">
          <MenuButton />
        </div>
        <div className="col-xxl-11 col-lg-11 col-md-11 col-sm-12 spacing">
          {children}
        </div>
      </div>
    </div>
  );
};

export default MyProSidebarProvider;
