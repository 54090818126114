import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { TailSpin } from "react-loader-spinner";
import { forgotPassword } from "../../action/user";
import { useDispatch } from "react-redux";

import Lock from "../../Components/svgImg/Lock.svg";
import Image from "../../Components/GymPics/loginImage.jpg";

import "../../Components/CSSfiles/LoginForgotPasswordRegistration.css";

function ForgotPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit() {
    setIsLoading(!isLoading);
    const successCallBack = (response) => {
      setIsLoading(!isLoading);
    };
    const failCallBack = (response) => {
      setIsLoading(false);
    };
    dispatch(forgotPassword(email, successCallBack, failCallBack));
  }

  return (
    <div className="container">
      <div className="row authMain">
        <div className="col-xxl-8 p-0">
          <div className="authImage">
            <img src={Image} alt={""} />
          </div>
        </div>
        <div className="col-xxl-4 p-0">
          <div className="authRightSide forgotPassSide">
            <div className="Lock my-5">
              <img src={Lock} alt="" height={127.06} width={119.15} />
            </div>
            <div className="text mb-5">
              <h1 className="mb-3">Forgot Password ?</h1>
              <p>
                Enter the Email Address You've registered with us and well send
                help shortly
              </p>
            </div>
            <div className="Submit">
              <Form>
                <Form.Group size="lg">
                  <input
                    type="text"
                    className="input"
                    id="email"
                    placeholder="Email Address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  ></input>
                </Form.Group>
                <br />
                <Button
                  block
                  size="lg"
                  type="button"
                  className="authButton w-100 mb-5"
                  onClick={handleSubmit}
                >
                  {!isLoading ? (
                    "Submit"
                  ) : (
                    <TailSpin heigth="25" width="45" color="white" />
                  )}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
