import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Group from "../../../Components/GymPics/Group-49-1.webp";
// import Button from "../Components/Button/Button";

import styles from "./index.module.scss";
function Location() {
  return (
    <div className={styles.locationMain}>
      <Container>
        <Row className="align-items-center">
          <Col lg={6} md={12} className="d-flex justify-content-center">
            <img src={Group} className={styles.bannerImg} alt="group" />
          </Col>
          <Col lg={6} md={12}>
            <div className={styles.contentMain}>
              <h2>Your Space, Your Pace</h2>
              <h5>
                Book any of our 24/7 Private Pods
                <br /> across the island.
              </h5>
              <p>No prying eyes</p>
              <p>No judgement</p>
              <p>No sharing of equipment</p>
              <h3>#JUSTGETSTARTED</h3>
              <div className={styles.btn}>
                {/* <Button title="SEE ALL LOCATION" secondary /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Location;
