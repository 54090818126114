import { toast } from "react-toastify";

import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAIL,
} from "./actionConstant";
import { getLoginUser } from "../comman/localStorage";
import { functionalityApiCall } from "../comman/connect";
import { GET_NOTIFICATION_URL, SEND_NOTIFICATION_URL } from "../comman/urls";

const getNotificationRequested = () => ({
  type: GET_NOTIFICATION_REQUEST,
});

const getNotificationSuccess = (data) => ({
  type: GET_NOTIFICATION_SUCCESS,
  data,
});

const getNotificationFailure = () => ({
  type: GET_NOTIFICATION_FAIL,
});

const sendNotificationRequested = () => ({
  type: SEND_NOTIFICATION_REQUEST,
});

const sendNotificationSuccess = () => ({
  type: SEND_NOTIFICATION_SUCCESS,
});

const sendNotificationFailure = () => ({
  type: SEND_NOTIFICATION_FAIL,
});

export const getNotification =
  (search, page, limit, filter, filterBy) => (dispatch) => {
    dispatch(getNotificationRequested());
    const { token } = getLoginUser();
    if (token) {
      const data = {
        page: page,
        limit: limit,
        sort: `id ${filter ? filter : "asc"}`,
        search: [
          {
            tableNo: "0",
            search: search,
            matchFields: ["title", "id"],
          },
        ],
      };

      if (filterBy) {
        data.query = filterBy;
      }
      const url = GET_NOTIFICATION_URL;
      const onSuccess = (response) => {
        dispatch(getNotificationSuccess(response.data));
      };

      const onFailure = (response) => {
        dispatch(getNotificationFailure());
        toast.error(response.message);
      };

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };

export const sendNotification = (data, successCallBack) => (dispatch) => {
  dispatch(sendNotificationRequested());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(sendNotificationSuccess(response.data));
    };

    const onFailure = (response) => {
      dispatch(sendNotificationFailure());
      successCallBack(response.message);
    };

    functionalityApiCall(
      "POST",
      SEND_NOTIFICATION_URL,
      data,
      onSuccess,
      onFailure,
      token
    );
  }
};
