import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";

import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
import { handleDownload } from "../comman/Hooks";
import Wallet from "../Components/svgImg/Wallet.svg";
import Spinner from "../Components/svgImg/Spinner.gif";
import GroupOverView from "../Components/svgImg/GroupOverView.svg";
import FinanceOverView from "../Components/svgImg/FinanceOverView.svg";
import { getEarning, getGroupEarning, getWallet } from "../action/finance";
import { getTransection, getTransectionSheet } from "../action/transection";

import "../Components/CSSfiles/Finance.css";

function Finance(props) {
  const currentDate = new Date();
  const dispatch = useDispatch();
  const [type, setType] = useState("Type");
  const [earning, setEarning] = useState("30 Days");
  const [groupEarning, setGroupEarning] = useState("30 Days");
  const [filterType, setFilterType] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [search, setSearch] = useState("");
  const [filterObj, setFilterObj] = useState({});
  const transectionList = useSelector((state) =>
    state.transection?.data?.list ? state.transection?.data?.list : []
  );
  const transectionLoading = useSelector(
    (state) => state.transection.isProcess
  );
  const earningLoading = useSelector((state) => state.finance?.isEarning);
  const dailyEarning = useSelector((state) => state.finance?.earning?.daily);
  const customEarning = useSelector((state) => state.finance?.earning?.custom);
  const walletLoading = useSelector((state) => state.finance?.isWallet);
  const walletData = useSelector((state) => state.finance?.wallet);
  const groupLoading = useSelector((state) => state.finance?.isGroupEarn);
  const groupData = useSelector((state) => state.finance?.groupEarn);

  useEffect(() => {
    dispatch(getTransection("", 1, 200, "asc"));
    dispatch(
      getEarning(moment(new Date().getDate() - 30).format("YYYY-MM-DD"))
    );
    dispatch(getWallet());
    dispatch(
      getGroupEarning(moment(new Date().getDate() - 30).format("YYYY-MM-DD"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (data) => {
    dispatch(getTransection(data, 1, 200));
    setSearch(data);
  };

  const debounce_fun = _.debounce(function (response) {
    handleSearch(response);
  }, 500);

  const handleFilterData = () => {
    let filterObj = {};
    if (filterType) {
      filterObj.type = filterType;
    }
    if (startDate) {
      filterObj.created_at = {
        min: startDate ? `${startDate} 00:00:00` : "",
      };
    }
    if (endDate) {
      filterObj.created_at = {
        ...filterObj.created_at,
        max: endDate ? `${endDate} 23:59:59` : null,
      };
    }
    if (!filterObj.type) {
      dispatch(getTransection("", 1, 200, "asc"));
    }
    dispatch(getTransection("", 1, 200, "asc", filterObj));
    setFilterObj(filterObj);
  };

  const handleDropdown = (name, e) => {
    if (e === "yesterday") {
      currentDate.setDate(currentDate.getDate() - 1);
    } else if (e === "week") {
      currentDate.setDate(currentDate.getDate() - 7);
    } else if (e === "30 Days") {
      currentDate.setDate(currentDate.getDate() - 30);
    }
    if (name === "earning") {
      dispatch(getEarning(moment(currentDate).format("YYYY-MM-DD")));
    } else if (name === "groupEarning") {
      dispatch(getGroupEarning(moment(currentDate).format("YYYY-MM-DD")));
    }
  };

  const handleDownloadSheet = () => {
    function success(response) {
      handleDownload(response.data, "transection-report");
    }
    dispatch(
      getTransectionSheet(
        search,
        1,
        2000000,
        "asc",
        Object.keys(filterObj).length === 0 ? undefined : filterObj,
        success
      )
    );
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setType("Type");
    setFilterType("");
    dispatch(getTransection("", 1, 200));
  };

  const header = [
    // {
    //   title: "",
    //   prop: "",
    //   cell: (props) => (
    //     <InputGroup.Checkbox aria-label="Checkbox for following text input" />
    //   )
    // },
    { name: "Id", selector: (row) => row.id, sortable: true },
    {
      name: "User Name",
      selector: (row) => row.user_id_username,
      sortable: true,
    },
    { name: "Email", selector: (row) => row.user_id_email, sortable: true },
    {
      name: "Gym Name",
      selector: (row) => row.gym_id_name,
      sortable: true,
      cell: (selectors) =>
        selectors.gym_id_name ? selectors.gym_id_name : "-",
    },
    {
      name: "Gym Address",
      selector: (row) => row.gym_id_address,
      sortable: true,
      cell: (selectors) =>
        selectors.gym_id_address ? selectors.gym_id_address : "-",
    },
    { name: "Amount", selector: (row) => row.amount, sortable: true },
    { name: "Type", selector: (row) => row.type, sortable: true },
    {
      name: "Gym use",
      selector: (row) => row.gym_id_max_user_pax,
      sortable: true,
      cell: (selectors) =>
        selectors.gym_id_max_user_pax ? selectors.gym_id_max_user_pax : "-",
    },
    {
      name: "Transection Date",
      selector: (row) => row.created_at,
      sortable: true,
      cell: (selectors) => moment(selectors.created_at).format("DD/MM/YYYY"),
    },
  ];

  return (
    <div className="mainboxes">
      <TopHeader title={"Finance"} />
      <div className="row mt-3 justify-content-center">
        <div className="col-xxl-4 col-lg-6">
          <div className="cardBody financeBody">
            <div className="d-flex align-items-end justify-content-between mb-5">
              <div className="text-left">
                <img
                  className="financeGraph"
                  src={FinanceOverView}
                  alt="icon"
                />
                <p className="m-0 totalGym">EARNINGS</p>
              </div>
              <div>
                <div className="d-flex align-items-center justify-content-end">
                  <span className="gymSubTitle">SLOT</span>
                  <p className="m-0 earningText">$ {dailyEarning?.duration}</p>
                </div>
                <div className="d-flex align-items-center">
                  <span className="gymSubTitle">DURATION</span>
                  <p className="m-0 earningText">$ {dailyEarning?.slot}</p>
                </div>
              </div>
              <div className="text-end">
                <span className="gymSubTitle m-0">DAILY EARNINGS</span>
                <p className="m-0 earning">
                  {earningLoading ? (
                    <img src={Spinner} alt={Spinner} />
                  ) : (
                    `$ ${
                      parseInt(dailyEarning?.duration) +
                      parseInt(dailyEarning?.slot)
                    }`
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between p-0">
              <DropdownButton
                id="dropdown-basic-button"
                title={earning}
                className="bg-transparent lastBooking w-auto finance30Day"
                onSelect={(e) => {
                  setEarning(e);
                  handleDropdown("earning", e);
                }}
              >
                <Dropdown.Item eventKey={"Today"}>Today</Dropdown.Item>
                <Dropdown.Item eventKey={"Yesterday"}>Yesterday</Dropdown.Item>
                <Dropdown.Item eventKey={"Week"}>Week</Dropdown.Item>
                <Dropdown.Item eventKey={"30 Days"}>30 Days</Dropdown.Item>
              </DropdownButton>
              <div className="d-flex align-items-end">
                <div>
                  <div className="d-flex align-items-center justify-content-end">
                    <span className="gymSubTitle">SLOT</span>
                    <p className="m-0 earningText">$ {customEarning?.slot}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="gymSubTitle">DURATION</span>
                    <p className="m-0 earningText">
                      $ {customEarning?.duration}
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-end">
                <span className="gymSubTitle text-uppercase m-0">
                  LAST {earning}
                </span>
                <p className="m-0 earningBottomText">
                  {earningLoading ? (
                    <img src={Spinner} alt={Spinner} />
                  ) : (
                    `$ ${
                      parseInt(customEarning?.duration) +
                      parseInt(customEarning?.slot)
                    }`
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-4 col-lg-6">
          <div className="cardBody financeBody">
            <div className="d-flex align-items-end justify-content-between mb-5">
              <div className="text-left">
                <img className="financeGraph" src={Wallet} alt="icon" />
                <p className="m-0 totalGym">WALLET BALANCE</p>
              </div>
              <div className="text-end">
                <span className="gymSubTitle m-0">TODAY DEPOSIT</span>
                <p className="m-0 earning">
                  {walletLoading ? (
                    <img src={Spinner} alt={Spinner} />
                  ) : (
                    `$  ${walletData?.daily ? walletData?.daily : 0}`
                  )}
                </p>
              </div>
            </div>
            <div className="p-0">
              <div className="text-end">
                <span className="gymSubTitle m-0">TOTAL BALANCE</span>
                <p className="m-0 earningBottomText">
                  $ {walletData?.totalBalance ? walletData.totalBalance : 0}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-4 col-lg-6">
          <div className="cardBody financeBody">
            <div className="d-flex align-items-end justify-content-between mb-5">
              <div className="text-left">
                <img className="financeGraph" src={GroupOverView} alt="icon" />
                <p className="m-0 totalGym">TOTAL GROUP'S FEE EARNED</p>
              </div>
              <div className="text-end">
                <span className="gymSubTitle m-0">DAILY EARNINGS</span>
                <p className="m-0 earning">
                  {groupLoading ? (
                    <img src={Spinner} alt={Spinner} />
                  ) : (
                    "$ " + groupData?.daily
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <DropdownButton
                id="dropdown-basic-button"
                title={groupEarning}
                className="bg-transparent lastBooking w-auto py-1"
                onSelect={(e) => {
                  setGroupEarning(e);
                  handleDropdown("groupEarning", e);
                }}
              >
                <Dropdown.Item eventKey={"Today"}>Today</Dropdown.Item>
                <Dropdown.Item eventKey={"Yesterday"}>Yesterday</Dropdown.Item>
                <Dropdown.Item eventKey={"Week"}>Week</Dropdown.Item>
                <Dropdown.Item eventKey={"30 Days"}>30 Days</Dropdown.Item>
              </DropdownButton>
              <div className="p-0">
                <div className="text-end">
                  <span className="gymSubTitle text-uppercase m-0">
                    LAST {groupEarning}
                  </span>
                  <p className="m-0 earningBottomText">$ {groupData?.custom}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center my-3 mobileTrasection">
        <div className="col-xxl-2 col-lg-2 col-md-4">
          <p className="transectionTitle m-0">ALL TRANSACTIONS</p>
        </div>
        <div className="col-xxl-2 col-lg-3 col-md-8 d-flex align-items-center mobileTopSpacing">
          <SearchBar
            placeholder={"Search Transactions"}
            search={debounce_fun}
          />
        </div>
        <div
          className="col-xxl-8 col-lg-12 d-flex align-items-center 
        justyBet topSpacing flex-wrap"
        >
          <div className="d-flex align-items-center ">
            <div className="minWidth">Start Date : </div>
            <input
              type="date"
              value={startDate}
              className="lastBooking startDate filterDate"
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </div>
          <div className="d-flex align-items-center mobileTopSpacing">
            <div className="minWidth">End Date : </div>
            <input
              type="date"
              value={endDate}
              className="lastBooking startDate filterDate"
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div>
          <DropdownButton
            id="dropdown-basic-button"
            title={type}
            className="bg-transparent todayDrop mobileTopSpacing mobileEndWidth"
            onSelect={(e) => {
              e = e.split(",");
              setType(e[1]);
              setFilterType(e[0]);
            }}
          >
            <Dropdown.Item eventKey={["booking", "Booking"]}>
              Booking
            </Dropdown.Item>
            <Dropdown.Item eventKey={["topup", "Topup"]}>Topup</Dropdown.Item>
            <Dropdown.Item eventKey={["Wallet Balance", "Wallet Balance"]}>
              Wallet Balance
            </Dropdown.Item>
          </DropdownButton>
          <div className="d-flex align-items-center filterSubmit tabletTopSpacing mobileFilterWidth">
            <button className="btn sortBtn reportExport " onClick={handleClear}>
              Clear
            </button>
            <button
              className="btn createGymBtn exportBtn searchBtn tabletFont mobileFilterBtn"
              onClick={handleFilterData}
            >
              Filter
            </button>
            <button
              onClick={() => handleDownloadSheet()}
              className="btn exportBtn searchBtn tabletFont mobileFilterBtn csvBtn"
            >
              Export CSV
            </button>
          </div>
        </div>
        {/* <div className="col-xxl-2 col-lg-2">
          <button className="btn createGymBtn exportBtn m-0">
            Exported Selected
          </button>
        </div> */}
      </div>
      <div className="row">
        <div className="table-responsive mb-2">
          {transectionLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : (
            <DataTable columns={header} data={transectionList} pagination />
          )}
        </div>
      </div>
    </div>
  );
}

export default Finance;
