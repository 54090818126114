import React, { useEffect, useState } from "react";

import Space from "./Space/space";
import Header from "./Header/Header";
import Banner from "./Banner/banner";
import Offers from "./Offers/Offers";
import Signup from "./Signup/Signup";
import Download from "./Download/Download";
import Location from "./Location/location";
import Copyright from "./Copyright/Copyright";
import PricingInfo from "./PricingInfo/PricingInfo";

function Home() {
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  return (
    <>
      <Header scrolled={scrolled} />
      <Banner />
      <Location />
      <Signup />
      <Offers />
      {/* <Booking /> */}
      <Space />
      <PricingInfo />
      <Download />
      {/* <Pods />
      <Community />
      <Featured />
      <Privacy />
      <Footer /> */}
      <Copyright />
    </>
  );
}

export default Home;
