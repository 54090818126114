import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import play from "../../../Components/GymPics/CTA-1.png";
import app from "../../../Components/GymPics/CTA.png";
import Swipper from "./Swipper";

import styles from "./index.module.scss";

function Signup() {
  return (
    <Container className="my-5">
      <div className={styles.signupMain}>
        <Row className="align-items-center">
          <Col lg={5} md={12} className="d-flex justify-content-end">
            <Swipper />
          </Col>
          <Col lg={7} md={12} className={styles.contentSecMain}>
            <div className={styles.contentSec}>
              <div className={styles.content}>
                <h1>1.Sign Up</h1>
                <p>Download the App and create and account</p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.gym.xpod"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={play} alt="play" />
                </a>
                <br />
                <img src={app} alt="app" />
              </div>
            </div>
            <div className={styles.contentSec}>
              <div className={styles.content}>
                <h1>2.Book a gym slot</h1>
                <p>Book a slot by selecting date and time.</p>
              </div>
            </div>
            <div className={styles.contentSec}>
              <div className={styles.content}>
                <h1>3.Booking History</h1>
                <p>List of all Active or Non-active booking</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Signup;
