import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { DropdownDate } from "react-dropdown-date";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";

import TopHeader from "./TopHeader";
import { validationFields } from "../comman/Hooks";
import { addFranchise } from "../action/franchise";
import { editGymDetails } from "../action/gymDetails";

import "../Components/CSSfiles/FranchiseCreate.css";

function FranchiseCreate(props) {
  const [franchiseId, setFranchiseId] = useState();
  const [franchiseDetail, setFranchiseDetail] = useState({
    company_name: null,
    dob: null,
    email: null,
    first_name: null,
    gender: null,
    last_name: null,
    mobile: null,
    unique_id: null,
  });
  // const gymObj = {
  //   id: 1,
  //   name: "",
  //   address: "",
  //   location: {},
  //   start_date: "",
  //   end_date: "",
  //   franchise_id: franchiseId,
  // };
  // const [counter, setCounter] = useState([gymObj]);
  const [updateGym, setUpdateGym] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [gymCreate, setGymCreate] = useState(false);
  const isLoading = useSelector((state) => state.franchise.isProcess);
  // const isGymLoading = useSelector((state) => state.gymDetail.isProcess);
  const gymList = useSelector((state) =>
    state.gymDetail.gymList ? state.gymDetail.gymList : []
  );
  const [date, setDate] = useState(new Date());
  const [gender, setGender] = useState("Gender");
  const dispatch = useDispatch();
  const history = useNavigate();

  // const handleAddGym = () => {
  //   setCounter([
  //     ...counter,
  //     {
  //       id: counter.length + 1,
  //       name: "",
  //       address: "",
  //       location: { latitude: "-31.11924", longitude: "42.39557" },
  //       start_date: "",
  //       end_date: "",
  //     },
  //   ]);
  // };

  const handleCheckGym = (e, id) => {
    if (e.target.checked) {
      let arr = updateGym;
      arr.push({
        id: id,
        franchise_id: franchiseId,
      });
      setUpdateGym(arr);
    } else {
      _.remove(updateGym, function (n) {
        return n.id === id;
      });
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFranchiseDetail((franchiseDetail) => ({
      ...franchiseDetail,
      [name]: value,
    }));
  };

  const handleDropdown = (e) => {
    if (e) setFranchiseDetail({ ...franchiseDetail, gender: e });
  };

  // const handleGymSubmit = () => {
  //   counter.map((c) => delete c.id);
  //   const succesCallBack = () => {
  //     history("/Franchise");
  //   };
  //   dispatch(addGymDetails(counter, succesCallBack));
  // };

  // const handleDelete = (index) => {
  //   let gyms = counter;
  //   const deleteGym = _.remove(gyms, function (n) {
  //     return n.id !== index - 1;
  //   });
  //   setCounter(deleteGym);
  // };

  const handleDate = (props) => {
    setFranchiseDetail({ ...franchiseDetail, dob: props });
  };

  // const handleGymChange = (e, id) => {
  //   let arr = counter;
  //   const { name, value } = e.target;
  //   for (let i = 0; i < arr.length; i++) {
  //     if (arr[i].id === id) {
  //       arr[i].name = "name" === name ? value : arr[i].name;
  //       arr[i].address = "address" === name ? value : arr[i].address;
  //       arr[i].location = { latitude: "-31.11924", longitude: "42.39557" };
  //       arr[i].start_date = "start_date" === name ? value : arr[i].start_date;
  //       arr[i].end_date = "end_date" === name ? value : arr[i].end_date;
  //       arr[i].franchise_id = franchiseId;
  //     }
  //   }

  //   setCounter(arr);
  // };

  const handleSubmit = () => {
    const validFieldArray = validationFields(franchiseDetail);
    if (validFieldArray.length === 0) {
      setFormErrors([]);
      const succesCallBack = (response) => {
        if (response.id) {
          setGymCreate(!gymCreate);
          setFranchiseId(response.id);
          updateGym.map((data) => {
            return (data.franchise_id = response.id);
          });
          const succesCallBack = () => {
            history("/franchise");
          };
          dispatch(editGymDetails(updateGym, succesCallBack));
        }
      };
      dispatch(addFranchise(franchiseDetail, succesCallBack));
    } else {
      setFormErrors([...validFieldArray]);
      toast.error("Fields are required");
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const header = [
    {
      name: "",
      selector: (row) => row.id,
      cell: (selectors) => (
        <InputGroup.Checkbox
          aria-label="Checkbox for following text input"
          name="id"
          onChange={(e) => handleCheckGym(e, selectors.id)}
        />
      ),
      sortable: true,
    },
    {
      name: "Photo",
      selector: (row) => row.images,
      cell: (selectors) => (
        <td>
          <img
            src={selectors.images ? selectors.images.split(",")[0] : ""}
            alt="img"
            className="gymPhoto"
          />
        </td>
      ),
    },
    { name: "Id", selector: (row) => row.id, sortable: true },
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Address", selector: (row) => row.address, sortable: true },
    { name: "Area", selector: (row) => row.area, sortable: true },
    {
      name: "Start Date",
      selector: (row) => row.start_date,
      sortable: true,
      cell: (selectors) => moment(selectors.start_date).format("DD/MM/YYYY"),
    },
    {
      name: "End Date",
      selector: (row) => row.end_date,
      sortable: true,
      cell: (selectors) => moment(selectors.end_date).format("DD/MM/YYYY"),
    },
  ];

  return (
    <div className="mainboxes">
      <TopHeader title={"Create New Franchise"} />
      <div className="row align-items-center searchMain pb-2 mb-3">
        <div className="col-xxl-9 col-lg-9 d-flex align-items-center justify-content-between"></div>
        <div className="col-xxl-3 col-lg-3 d-flex align-items-center mobileBtns">
          <button
            className="btn topCancel mx-2"
            onClick={() => {
              history("/franchise");
            }}
          >
            Cancel
          </button>
          <button className="btn saveBtn franchBtn" onClick={handleSubmit}>
            {!isLoading ? (
              "Create"
            ) : (
              <TailSpin heigth="15" width="45" color="white" />
            )}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-12 col-lg-12">
          <div className="borderShadowBox franchiseeDetailBox my-3">
            <h3 className="m-0 detailHeading mb-4">
              Display Franchisee Information:
            </h3>
            <Form>
              <div className="row">
                <div className="col-xxl-3 col-lg-3">
                  <div>
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Franchisee ID
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("unique_id") && "error"
                      }`}
                      name="unique_id"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mt-4">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Franchisee Company Name
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("company_name") && "error"
                      }`}
                      name="company_name"
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-3 tabletTopSpacing">
                  <div>
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      First Name
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("first_name") && "error"
                      }`}
                      name="first_name"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mt-4">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Email Address
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("email") && "error"
                      }`}
                      name="email"
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-3 tabletTopSpacing">
                  <div>
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Last Name
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("last_name") && "error"
                      }`}
                      name="last_name"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mt-4">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Mobile
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("mobile") && "error"
                      }`}
                      name="mobile"
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-3 tabletGender tabletTopSpacing">
                  <div>
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Gender
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={gender}
                      className={`bg-transparent franchiseDetailDrop ${
                        formErrors.includes("gender") && "error-drop"
                      }`}
                      onSelect={(e) => {
                        setGender(e);
                        handleDropdown(e);
                      }}
                    >
                      <Dropdown.Item eventKey={"Male"}>Male</Dropdown.Item>
                      <Dropdown.Item eventKey={"Female"}>Female</Dropdown.Item>
                      <Dropdown.Item eventKey={"Other"}>Other</Dropdown.Item>
                    </DropdownButton>
                  </div>
                  <div className="tabletDob">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Dob (optional)
                    </p>
                    <div
                      className={`d-flex align-items-center ${
                        formErrors.includes("dob") && "error-date"
                      }`}
                    >
                      <DropdownDate
                        className={formErrors.includes("dob") && "error"}
                        selectedDate={date}
                        onDateChange={(date) => {
                          setDate(formatDate(date));
                          handleDate(formatDate(date));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-12 col-lg-12">
          <div className="borderShadowBox franchiseeDetailBox my-2">
            <h3 className="m-0 detailHeading mb-4">
              Please select the gyms to be in this franchise
            </h3>
            <div className="table-responsive gymTable">
              {isLoading ? (
                <div className="spinner">
                  <TailSpin heigth="75" width="100" color="black" />
                </div>
              ) : (
                <DataTable columns={header} data={gymList} pagination />
              )}
            </div>
            {/* <div className="d-flex align-items-center justify-content-between">
              <h3
                className="m-0 detailHeading mb-4
                  "
              >
                Display Gym Information:
              </h3>
              <button className="btn detailAddGym" onClick={handleAddGym}>
                Add Gym
              </button>
            </div>
            <div className="row">
              <div className="col-xxl-3 col-lg-2">
                <p className="topBarTitle franchiseeDetailBoxTitle">
                  Name Of Gym
                </p>
              </div>
              <div className="col-xxl-3 col-lg-2">
                <p className="topBarTitle franchiseeDetailBoxTitle">Address</p>
              </div>
              <div className="col-xxl-3 col-lg-3">
                <p className="topBarTitle franchiseeDetailBoxTitle">
                  Google Maps URL
                </p>
              </div>
              <div className="col-xxl-3 col-lg-5 ">
                <div className="row">
                  <div className="col-xxl-5 col-lg-5">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Start Date
                    </p>
                  </div>
                  <div className="col-xxl-5 col-lg-5">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      End Date
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {counter.map((data, index) => {
              return (
                <div className="row mb-4">
                  <div className="col-xxl-3 col-lg-2">
                    <input
                      type="text"
                      onChange={(e) => {
                        handleGymChange(e, data.id);
                      }}
                      name="name"
                      className="input franchiseDetailInput"
                    />
                  </div>
                  <div className="col-xxl-3 col-lg-2">
                    <input
                      type="text"
                      name="address"
                      onChange={(e) => {
                        handleGymChange(e, data.id);
                      }}
                      className="input franchiseDetailInput"
                    />
                  </div>
                  <div className="col-xxl-3 col-lg-3">
                    <input
                      type="text"
                      name="location"
                      onChange={(e) => {
                        handleGymChange(e, data.id);
                      }}
                      className="input franchiseDetailInput"
                    />
                  </div>
                  <div className="col-xxl-3 col-lg-5">
                    <div className="row">
                      <div className="col-xxl-5 col-lg-5">
                        <input
                          type="date"
                          className="input franchiseDetailInput"
                          name="start_date"
                          onChange={(e) => {
                            handleGymChange(e, data.id);
                          }}
                        />
                      </div>
                      <div className="col-xxl-5 col-lg-5">
                        <input
                          type="date"
                          name="end_date"
                          className="input franchiseDetailInput"
                          onChange={(e) => {
                            handleGymChange(e, data.id);
                          }}
                        />
                      </div>
                      <div className="col-xxl-2 col-lg-2">
                        <button
                          className={`btn actionBtn mt-1 ${
                            index === 0 && "d-none"
                          }`}
                          onClick={() => handleDelete(index)}
                        >
                          <svg
                            fill="#ffffff"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="15px"
                            height="15px"
                          >
                            <path
                              d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A
                               1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 
                               14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652344 7 L 5.8925781 
                               20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 
                               21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {gymCreate && (
              <div className="text-end my-3">
                <button className="btn topCancel mx-2">Cancel</button>
                <button
                  className="btn saveBtn detailSaveBtn franchBtn"
                  onClick={() => {
                    handleGymSubmit();
                  }}
                >
                  {!isGymLoading ? (
                    "Save"
                  ) : (
                    <TailSpin heigth="15" width="45" color="white" />
                  )}
                </button>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FranchiseCreate;
