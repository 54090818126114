import { toast } from "react-toastify";

import {
  GET_TRANSECTION_FAIL,
  GET_TRANSECTION_REQUEST,
  GET_TRANSECTION_SUCCESS,
} from "./actionConstant";
import { GET_TRANSECTION_SHEET, GET_TRANSECTION_URL } from "../comman/urls";
import { getLoginUser } from "../comman/localStorage";
import { functionalityApiCall } from "../comman/connect";

const transectionRequested = () => ({
  type: GET_TRANSECTION_REQUEST,
});

const transectionSuccess = (data) => ({
  type: GET_TRANSECTION_SUCCESS,
  data,
});

const transectionFailure = (data) => ({
  type: GET_TRANSECTION_FAIL,
  data,
});

export const getTransection =
  (search, page, limit, filter, filterBy) => (dispatch) => {
    dispatch(transectionRequested());
    const { token } = getLoginUser();
    if (token) {
      const data = {
        page: page,
        limit: limit,
        sort: `updated_at ${filter ? filter : "desc"}`,
        search: [
          {
            tableNo: "0",
            search: search,
            matchFields: ["id", "amount", "type"],
          },
          {
            tableNo: "1",
            search: search,
            matchFields: ["first_name", "username", "email"],
          },
          {
            tableNo: "2",
            search: search,
            matchFields: ["name", "address"],
          },
        ],
        populate: [
          {
            tableName: "user",
            key: "user_id",
            projection: ["first_name", "username", "email"],
          },
          {
            tableName: "gym",
            key: "gym_id",
            projection: ["name", "address", "max_user_pax"],
          },
        ],
      };
      if (filterBy) {
        data.query = filterBy;
      }
      const url = GET_TRANSECTION_URL;
      const onSuccess = (response) => {
        dispatch(transectionSuccess(response));
      };

      const onFailure = (response) => {
        dispatch(transectionFailure(response));
        toast.error(response.message);
      };

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };
export const getTransectionSheet =
  (search, page, limit, filter, filterBy, success) => (dispatch) => {
    const { token } = getLoginUser();
    if (token) {
      const data = {
        page: page,
        limit: limit,
        sort: `updated_at ${filter ? filter : "desc"}`,
        search: [
          {
            tableNo: "0",
            search: search,
            matchFields: ["id", "amount", "type"],
          },
          {
            tableNo: "1",
            search: search,
            matchFields: ["first_name", "username", "email"],
          },
          {
            tableNo: "2",
            search: search,
            matchFields: ["name", "address"],
          },
        ],
        populate: [
          {
            tableName: "user",
            key: "user_id",
            projection: ["first_name", "username", "email"],
          },
          {
            tableName: "gym",
            key: "gym_id",
            projection: ["name", "address", "max_user_pax"],
          },
        ],
      };
      if (filterBy) {
        data.query = filterBy;
      }
      const url = GET_TRANSECTION_SHEET;
      const onSuccess = (response) => {
        success(response.data);
      };

      const onFailure = (response) => {
        toast.error(response.message);
      };

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };
