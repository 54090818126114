import moment from "moment";
import Chart from "react-google-charts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";

import TopHeader from "./TopHeader";
import { getEarning } from "../action/finance";
import Pic1 from "../Components/GymPics/user.png";
import PlusIcon from "../Components/svgImg/Plus.svg";

import GymSvg from "../Components/svgImg/GymOverView.svg";
import { getGroupDetails } from "../action/groupDetails";
import Spinner from "../Components/svgImg/Spinner.gif";
import { getGymCount, getGymUsers } from "../action/gymUsers";
import { getBookingCount, getBookings } from "../action/booking";
import FinanceSvg from "../Components/svgImg/FinanceOverView.svg";
import BookingSvg from "../Components/svgImg/BookingOverView.svg";
import { getGymDetails, getSlotCount } from "../action/gymDetails";

import "../Components/CSSfiles/Dashboard.css";
import "../Components/CSSfiles/FranchiseDashboard.css";

function Dashboard() {
  const currentDate = new Date();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [selectBook, setSelectBook] = useState("30 Days");
  const [today2, setToday2] = useState("30 Days");
  const [today3, setToday3] = useState("30 Days");
  const gymCount = useSelector((state) => state.gymDetail?.data?.count);
  const gymLoading = useSelector((state) => state.gymDetail?.isProcess);
  const slotCounters = useSelector((state) => state.gymDetail?.slots);
  const durationCounters = useSelector((state) => state.gymDetail?.duration);
  const userCount = useSelector((state) => state.gymUsers?.data?.count);
  const userCountation = useSelector((state) => state.gymUsers?.countation);
  const userLoading = useSelector((state) => state.gymUsers?.isProcess);

  const bookingList = useSelector((state) =>
    state.bookings?.data ? state?.bookings?.data : []
  );
  const userList = useSelector((state) =>
    state.gymUsers.data?.list ? state.gymUsers?.data?.list : []
  );
  const bookCount = useSelector(
    (state) => state.bookings?.count?.bookinCount[0]?.count
  );
  const chartData = useSelector((state) => state.bookings?.count?.chartData);
  const financeLoading = useSelector((state) => state.finance?.isEarning);
  const dailyEarning = useSelector((state) => state.finance?.earning?.daily);
  const customEarning = useSelector((state) => state.finance?.earning?.custom);

  useEffect(() => {
    dispatch(getSlotCount());
    dispatch(getGymDetails("", 1, 10));
    dispatch(getGroupDetails("", 1, 20));
    dispatch(getGymUsers("", 1, 4, "desc"));
    dispatch(getBookings("", "", 1, 4, "desc"));
    dispatch(
      getBookingCount(moment(new Date().getDate() - 30).format("YYYY-MM-DD"))
    );
    dispatch(
      getEarning(moment(new Date().getDate() - 30).format("YYYY-MM-DD"))
    );
    dispatch(
      getGymCount(moment(new Date().getDate() - 30).format("YYYY-MM-DD"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropdown = (name, e) => {
    if (e === "yesterday") {
      currentDate.setDate(currentDate.getDate() - 1);
    } else if (e === "week") {
      currentDate.setDate(currentDate.getDate() - 7);
    } else if (e === "30 Days") {
      currentDate.setDate(currentDate.getDate() - 30);
    }
    if (name === "booking") {
      dispatch(getBookingCount(moment(currentDate).format("YYYY-MM-DD")));
    } else if (name === "finance") {
      dispatch(getEarning(moment(currentDate).format("YYYY-MM-DD")));
    } else {
      dispatch(getGymCount(moment(currentDate).format("YYYY-MM-DD")));
    }
  };

  const chartDataList = [["Year", "Visitations", { role: "style" }]];
  chartData?.map((data, index) => {
    return chartDataList.push([
      (index + 1).toString(),
      data[0].value,
      "color: #b6cb1b",
    ]);
  });

  const options = {
    legend: "none",
    legendToggle: false,
    chartArea: {
      right: 0,
      top: 9,
      left: 0,
      bottom: 30,
    },
  };

  return (
    <div className="mainboxes">
      <TopHeader title={"Dashboard"} />
      <div className="row mb-3 mt-4">
        <div className="col-xxl-4 col-lg-6 boxSpacing">
          <div className="cardBody dashCardBoday">
            <div className="gymMain">
              <div>
                <img className="firsticon" src={GymSvg} alt="icon" />
                <span className="mx-3 totalGym">TOTAL GYM</span>
              </div>
              <h3 className="gymNumber">
                {gymLoading ? <img src={Spinner} alt={Spinner} /> : gymCount}
              </h3>
            </div>
            <div className="gymBottom">
              <button
                className="btn dashboardBtn"
                onClick={() => history("/gym-create")}
              >
                <img src={PlusIcon} alt="plus" /> New
              </button>
              <div className="d-flex align-items-center gymSubMain">
                <span className="dashSubTitle">SLOT</span>
                <p className="dashSubValue">{slotCounters}</p>
                <span className="dashSubTitle">DURATION</span>
                <p className="dashSubValue">{durationCounters}</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xxl-4 col-lg-6 boxSpacing">
          <div className="cardBody dashCardBoday">
            <div className="d-flex align-items-center justify-content-between ">
              <div className="d-flex align-items-center">
                <img className="groupIcon" src={GroupOverView} alt="icon" />
                <p className="m-0 totalGym mx-4">TOTAL GROUP</p>
              </div>
              <div className="d-flex align-items-center ">
                <p className="m-0 totalGroupValue">
                  {groupLoading ? (
                    <img src={Spinner} alt={Spinner} />
                  ) : (
                    groupCount
                  )}
                </p>
              </div>
            </div>
            <div className="gymBottom pt-4">
              <button
                className="btn dashboardBtn"
                onClick={() => history.push("/group-create")}
              >
                <img src={PlusIcon} alt="plus" /> New
              </button>
              <div className="d-flex align-items-center gymSubMain">
                <span className="dashSubTitle">LAST 30 DAYS</span>
                <p className="dashSubValue">34</p>
                <span className="dashSubTitle">ACTIVE</span>
                <p className="dashSubValue">235</p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-xxl-4 col-lg-6 boxSpacing">
          <div className="cardBody dashCardBoday">
            <div className="gymMain">
              <div>
                <img className="firsticon" src={GymSvg} alt="icon" />
                <span className="mx-3 totalGym">TOTAL USERS</span>
              </div>
              <h3 className="gymNumber numburColor">
                {userLoading ? <img src={Spinner} alt={Spinner} /> : userCount}
              </h3>
            </div>
            <div className="gymBottom">
              <button
                className="btn dashboardBtn"
                onClick={() => history("/user-listing")}
              >
                All Users
              </button>
              <div className="d-flex align-items-center gymSubMain">
                <DropdownButton
                  id="dropdown-basic-button"
                  title={today2}
                  className="bg-transparent lastBooking w-auto"
                  onSelect={(e) => {
                    e = e.split(",");
                    setToday2(e[0]);
                    handleDropdown("user", e[1]);
                  }}
                >
                  <Dropdown.Item eventKey={["Today", new Date()]}>
                    Today
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey={["Yesterday", new Date().getDay() - 1]}
                  >
                    Yesterday
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={"Week"}>Week</Dropdown.Item>
                  <Dropdown.Item eventKey={"30 Days"}>30 Days</Dropdown.Item>
                </DropdownButton>
                <p className="dashSubValue">{userCountation?.custom}</p>
                <span className="dashSubTitle">DAILY</span>
                <p className="dashSubValue">{userCountation?.daily}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-4 col-lg-6 boxSpacing">
          <div className="cardBody dashCardBoday">
            <div className="d-flex justify-content-end mt-3">
              <div className="booking">
                <img className="firsticon" src={BookingSvg} alt="icon" />
                <span className="mx-3 totalGym">BOOKING</span>
              </div>
              <DropdownButton
                id="dropdown-basic-button"
                title={selectBook}
                className="bg-transparent lastBooking w-auto py-1"
                onSelect={(e) => {
                  setSelectBook(e);
                  handleDropdown("booking", e);
                }}
              >
                <Dropdown.Item eventKey={"Today"}>Today</Dropdown.Item>
                <Dropdown.Item eventKey={"Yesterday"}>Yesterday</Dropdown.Item>
                <Dropdown.Item eventKey={"Week"}>Week</Dropdown.Item>
                <Dropdown.Item eventKey={"30 Days"}>30 Days</Dropdown.Item>
              </DropdownButton>
            </div>
            <div className="d-flex align-items-center justify-content-between overflow-hidden">
              <div className="d-flex align-items-center">
                <span className="dashSubTitle m-0">TOTAL SESSION</span>
                <p className="dashSubValue graphSubValue">{bookCount}</p>
              </div>
              <div className="bookingCharts">
                <Chart
                  width={"100%"}
                  height={90}
                  data={chartDataList}
                  options={options}
                  chartType="ColumnChart"
                  legendToggle={false}
                  loader={<div>Loading Chart...</div>}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-4 col-lg-6 boxSpacing">
          <div className="cardBody dashCardBoday">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div>
                <img className="firsticon" src={FinanceSvg} alt="icon" />
                <span className="mx-3 totalGym">FINANCE</span>
              </div>
              <div className="d-flex align-items-center py-2">
                <span className="gymSubTitle">DAILY EARNINGS</span>
                <p className="earning">
                  {financeLoading ? (
                    <img src={Spinner} alt={Spinner} />
                  ) : (
                    `$ ${dailyEarning?.duration * dailyEarning?.slot}`
                  )}
                </p>
              </div>
            </div>
            <div className="financeBottom pt-3">
              <DropdownButton
                id="dropdown-basic-button"
                title={today3 ? today3 : "Select..."}
                className="bg-transparent lastBooking w-auto"
                onSelect={(e) => {
                  setToday3(e);

                  handleDropdown("finance", e);
                }}
              >
                <Dropdown.Item eventKey={"Today"}>Today</Dropdown.Item>
                <Dropdown.Item eventKey={"Yesterday"}>Yesterday</Dropdown.Item>
                <Dropdown.Item eventKey={"Week"}>Week</Dropdown.Item>
                <Dropdown.Item eventKey={"30 Days"}>30 Days</Dropdown.Item>
              </DropdownButton>
              <div className="d-flex align-items-center financeSubMain">
                <span className="gymSubTitle text-uppercase">
                  LAST {today3}
                </span>
                <p className="gymSubValue">
                  {financeLoading ? (
                    <img src={Spinner} alt={Spinner} />
                  ) : (
                    `$ ${
                      parseInt(customEarning?.duration) +
                      parseInt(customEarning?.slot)
                    }`
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xxl-4 col-lg-6 boxSpacing">
          <div className="cardBody dashCardBoday">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <img className="firsticon" src={TotalReportSvg} alt="icon" />
                <span className="mx-3 totalGym">REPORT</span>
              </div>
              <div className="d-flex align-items-center py-3 ">
                <DropdownButton
                  id="dropdown-basic-button"
                  title={report ? report : "Select..."}
                  className="bg-transparent lastBooking w-auto "
                  onSelect={(e) => {
                    setReport(e);
                    handleDropdown("open_at", e);
                  }}
                // style={{width: "115px"}}
                >
                  <Dropdown.Item eventKey={"PDF"}>PDF</Dropdown.Item>
                  <Dropdown.Item eventKey={"XLS"}>XLS</Dropdown.Item>

                </DropdownButton>
              </div>
            </div>
            <div className="reportBottom pt-3">
              <div className="d-flex align-items-center">
                <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                <span className="mx-2 reportText">PDF</span>
                <div className="mx-2 d-flex align-items-center">
                  <InputGroup.Checkbox aria-label="Checkbox for following text input" />
                  <span className="mx-2 reportText">XLS</span>
                </div>
              </div>
              <div className="d-flex align-items-center reportSubMain">
                <button className="btn btn-secondary bg-black exportBtn">
                  Export
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row mb-3">
        <div className="col-xxl-6 col-lg-12 mb-3">
          <p className="m-0 dashSubTitle mb-3">LATEST BOOKING</p>
          <div className="borderShadowBox">
            <Table className="overflow-auto" borderless>
              <tbody>
                {bookingList.length > 0 && (
                  <tr>
                    <th className="profileLine"></th>
                    <th>User</th>
                    <th>Gym</th>
                    <th>Time</th>
                  </tr>
                )}
                {bookingList.length > 0 ? (
                  bookingList?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            src={item.user_id_image ? item.user_id_image : Pic1}
                            alt="icon"
                            className="dashboardProfilePhoto"
                          />
                        </td>
                        <td className="boldData">{item.user_id_username}</td>
                        <td>{item.gym_name}</td>
                        <td>{`${item.start_time} -> ${item.end_time}`}</td>
                      </tr>
                    );
                  })
                ) : (
                  <div className="text-center">No data</div>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="col-xxl-6 col-lg-12">
          <p className="m-0 dashSubTitle mb-3">LATEST USERS</p>
          <div className="borderShadowBox">
            <Table className="overflow-auto" borderless>
              <tbody>
                {userList.length > 0 && (
                  <tr>
                    <th className="profileLine"></th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Gender</th>
                    <th>Date</th>
                  </tr>
                )}
                {userList.length > 0 ? (
                  userList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            src={item.image ? item.image : Pic1}
                            alt="icon"
                            className="dashboardProfilePhoto"
                          />
                        </td>
                        <td className="boldData">{`${item.first_name} ${item.last_name}`}</td>
                        <td>{item.username}</td>
                        <td>{item.gender}</td>
                        <td>
                          {item.updated_at
                            ? moment(item.updated_at).format("hh:mm A")
                            : "Not available"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div className="text-center">No data</div>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
