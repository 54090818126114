import { toast } from "react-toastify";

import {
  functionalityApiCall,
  functionalityDeleteApiCall,
} from "../comman/connect";
import { getLoginUser } from "../comman/localStorage";
import { ADD_GROUP_URL, GET_GROUP_URL } from "../comman/urls";

import {
  ADD_GROUPDETAILS_FAIL,
  ADD_GROUPDETAILS_REQUEST,
  ADD_GROUPDETAILS_SUCCESS,
  DELETE_GROUPDETAILS_REQUEST,
  DELETE_GROUPDETAILS_SUCCESS,
  EDIT_GROUPDETAILS_FAIL,
  EDIT_GROUPDETAILS_REQUEST,
  EDIT_GROUPDETAILS_SUCCESS,
  GET_GROUPDETAILS_FAIL,
  GET_GROUPDETAILS_REQUEST,
  GET_GROUPDETAILS_SUCCESS,
} from "./actionConstant";

const addGroupDetailsRequest = () => ({
  type: ADD_GROUPDETAILS_REQUEST,
});

const addGroupDetailsSuccess = () => ({
  type: ADD_GROUPDETAILS_SUCCESS,
});

const addGroupDetailsFail = () => ({
  type: ADD_GROUPDETAILS_FAIL,
});

const getGroupDetailsRequest = () => ({
  type: GET_GROUPDETAILS_REQUEST,
});

const getGroupDetailsSuccess = (data) => ({
  type: GET_GROUPDETAILS_SUCCESS,
  data,
});

const getGroupDetailsFail = (data) => ({
  type: GET_GROUPDETAILS_FAIL,
  data,
});

const deleteGroupDetailsRequest = () => ({
  type: DELETE_GROUPDETAILS_REQUEST,
});

const deleteGroupDetailsSuccess = () => ({
  type: DELETE_GROUPDETAILS_SUCCESS,
});
const editGroupDetailsRequest = () => ({
  type: EDIT_GROUPDETAILS_REQUEST,
});

const editGroupDetailsSuccess = (data) => ({
  type: EDIT_GROUPDETAILS_SUCCESS,
  data,
});

const editGroupDetailsFail = (data) => ({
  type: EDIT_GROUPDETAILS_FAIL,
  data,
});

export const addGroupDetails = (requestData, successCallBack) => (dispatch) => {
  dispatch(addGroupDetailsRequest());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(addGroupDetailsSuccess(response));
      successCallBack(response.data);
    };
    const url = ADD_GROUP_URL;

    const onFailure = (response) => {
      dispatch(addGroupDetailsFail(response));
      toast.error(response.message || response.data.message);
    };

    functionalityApiCall("POST", url, requestData, onSuccess, onFailure, token);
  }
};

export const getGroupDetails =
  (search, page, limit, filter, filterColumn) => (dispatch) => {
    dispatch(getGroupDetailsRequest());
    const { token } = getLoginUser();
    if (token) {
      const data = {
        page: page,
        limit: limit,
        sort: `${
          filterColumn && filterColumn !== "All" ? filterColumn : "id"
        } ${filter ? filter : "asc"}`,
        search: [
          {
            tableNo: "0",
            search: search,
            matchFields: ["name"],
          },
        ],
      };
      const url = GET_GROUP_URL;
      const onSuccess = (response) => {
        dispatch(getGroupDetailsSuccess(response.data));
      };

      const onFailure = (response) => {
        dispatch(getGroupDetailsFail(response));
        toast.error(response.message);
      };

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };

export const editGroupDetail =
  (id, requestData, successCallBack) => (dispatch) => {
    dispatch(editGroupDetailsRequest());
    const { token } = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(editGroupDetailsSuccess(response));
        successCallBack(response);
      };
      const url = `${ADD_GROUP_URL}/${id}`;

      const onFailure = (response) => {
        dispatch(editGroupDetailsFail(response));
        toast.error(response.message || response.data.message);
      };

      functionalityApiCall(
        "PUT",
        url,
        requestData,
        onSuccess,
        onFailure,
        token
      );
    }
  };

export const deleteGroupDetail = (id) => (dispatch) => {
  dispatch(deleteGroupDetailsRequest());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(deleteGroupDetailsSuccess());
      toast.success("Deleted Successfully");
    };
    const url = `${ADD_GROUP_URL}/${id}`;

    functionalityDeleteApiCall(url, onSuccess, token);
  }
};
