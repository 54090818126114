import {
  GET_VACCINATION_FAIL,
  EDIT_VACCINATION_FAIL,
  GET_VACCINATION_SUCCESS,
  GET_VACCINATION_REQUEST,
  EDIT_VACCINATION_SUCCESS,
  EDIT_VACCINATION_REQUEST,
  SET_VACCINATION_REQUEST,
  SET_VACCINATION_SUCCESS,
  SET_VACCINATION_FAIL,
} from "../action/actionConstant";

const intialState = {
  status: "",
  data: null,
  isProcess: false,
  message: null,
  isVerify: false,
};

const vaccination = (state = intialState, action) => {
  const { data } = action;
  switch (action.type) {
    case GET_VACCINATION_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case GET_VACCINATION_SUCCESS:
      return {
        ...state,
        data: data,
        isProcess: false,
      };
    case GET_VACCINATION_FAIL:
      return {
        ...state,
        isProcess: false,
      };
    case EDIT_VACCINATION_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case EDIT_VACCINATION_SUCCESS:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case EDIT_VACCINATION_FAIL:
      return {
        ...state,
        isProcess: false,
      };
    case SET_VACCINATION_REQUEST:
      return {
        ...state,
        isVerify: true,
      };
    case SET_VACCINATION_SUCCESS:
      return {
        ...state,
        isVerify: false,
      };
    case SET_VACCINATION_FAIL:
      return {
        ...state,
        isVerify: false,
      };
    default:
      return state;
  }
};

export default vaccination;
