import Pic1 from "../Components/GymPics/user.png";

export const Items = [];
for (let index = 0; index < 15; index++) {
  Items.push({
    Photo: Pic1,
    ID: "001",
    Name: "131 Jalan Bukit Merah",
    Address: "131 Jalan Bukit Merah #0…",
  });
}

export const GymUsers = [];
for (let index = 0; index < 17; index++) {
  GymUsers.push({
    Photo: Pic1,
    id: index,
    bookingDate: "04.23. 2021",
    userName: "crazy boys",
    fName: "tom Lark",
    gender: "female",
    gStatus: "Gold",
    nBooking: 43,
    total: "$ 754.00",
  });
}

export const GroupData = [];
for (let index = 0; index < 5; index++) {
  GroupData.push({
    groupName: "ordinay",
    price: "$10",
    period: "Per Month",
    user: 3344,
    slot: 5,
    discount: "%20",
    book: "72 hour",
    advance: "7 days",
    cancellation: "50 %",
  });
}

export const bookingList = [];
for (let index = 0; index < 35; index++) {
  bookingList.push({
    bookingId: "0034343004",
    groupName: "ordinay",
    gymName: "131 Jalan Bukit Merah",
    userName: "crazy boys",
    type: "slot",
    date: "04.23. 2021    10:00 Pm -> 11:30 pm",
    total: "90 min",
    fee: "$ 14",
  });
}

export const reportData = [];
for (let index = 0; index < 23; index++) {
  reportData.push({
    Photo: Pic1,
    id: index,
    name: "131 Jalan Bukit Merah",
    address: "131 Jalan Bukit Merah #0…",
    booking: "3433",
    user: "5700",
    earning: "$7600 USD",
    duration: "8900 min",
  });
}

export const notificationList = [];
for (let index = 0; index < 18; index++) {
  notificationList.push({
    name: "User Notification " + index,
    description: "Notification email",
    date: "04.23. 2021",
  });
}

export const vaccinationList = [];
for (let index = 0; index < 18; index++) {
  vaccinationList.push({
    Photo: Pic1,
    id: index,
    date: "04.22. 2021",
    userName: "Crazy Boys",
    fName: "Tom Lark",
    attechment: "View Attechment",
  });
}

export const GroupDetailList = [];
for (let index = 0; index < 43; index++) {
  GroupDetailList.push({
    Photo: Pic1,
    id: index,
    status: "Active",
    userName: "Crazy Boys",
    fName: "Tom Lark",
    gender: "Female",
    gStatus: "Ordinary",
    joinDate: "07.21.2021 16:00",
    expiryDate: "07.21.2023 00:00",
    type: "Automatic",
  });
}

export const FinanceData = [];
for (let index = 0; index < 15; index++) {
  FinanceData.push({
    id: "0034343004",
    date: "04.23. 2021",
    type: "Booking",
    Photo: Pic1,
    fName: "Tom Lark",
    gender: "Female",
    amount: "$ 14",
  });
}

export const profileTableList = [];
for (let index = 0; index < 13; index++) {
  profileTableList.push({
    Photo: Pic1,
    id: "0034343004",
    type: "Slot",
    address: "131 Jalan Bukit Merah",
    date: "04.23. 2021    10:00 Pm -> 11:30 pm",
    price: "$7.00 Done",
    status: "0:45:23",
  });
}

export const chatUser = [];
for (let index = 0; index < 20; index++) {
  chatUser.push({
    Photo: Pic1,
    name: "Tom Larek",
    msg: "Hello How are you",
  });
}

export const DashboardFirst = [];
for (let index = 0; index < 4; index++) {
  DashboardFirst.push({
    Photo: Pic1,
    name: "Tom Larek",
    gym: "131 Jalan Bukit Merah",
    time: "10:00 Pm -> 11:30 pm",
  });
}
export const DashboardSecond = [];
for (let index = 0; index < 4; index++) {
  DashboardSecond.push({
    Photo: Pic1,
    name: "Tom Larek",
    user: "CreazyBoys",
    gender: "Male",
    date: "Today 10:22 PM",
  });
}
