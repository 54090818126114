import _ from "lodash";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";

import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
import Edit from "../Components/svgImg/Edit.svg";
import Delete from "../Components/svgImg/Delete.svg";
import { deleteGymUser, getGymUsers } from "../action/gymUsers";

import "../Components/CSSfiles/UserListing.css";

function Users() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [sort, setSort] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [filter, setFilter] = useState("Oldest");
  const [filedColumn, setFieldColumn] = useState("");
  const [filedFilter, setFieldFilter] = useState("All");
  const isLoading = useSelector((state) => state.gymUsers.isProcess);
  const userList = useSelector((state) =>
    state.gymUsers.data?.list ? state.gymUsers.data?.list : []
  );

  useEffect(() => {
    dispatch(getGymUsers("", 1, 200));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (data) => {
    dispatch(getGymUsers(data, 1, 200));
  };

  const handleFilter = (value) => {
    dispatch(getGymUsers("", 1, 10, value, filedColumn));
  };

  const handleColumnFilter = (value) => {
    dispatch(getGymUsers("", 1, 10, sort, value));
  };

  const debounce_fun = _.debounce(function (response) {
    handleSearch(response);
  }, 500);

  const handleDelete = () => {
    const succesCallBack = () => {
      dispatch(getGymUsers("", 1, 200));
      toast.error("Delete Successfully...");
    };
    dispatch(deleteGymUser(deleteId, succesCallBack));
    setModalShow(!modalShow);
  };

  const header = [
    {
      name: "Photo",
      selector: (row) => row.image,
      cell: (selectors) => (
        <img src={selectors.image} alt={"user profile"} className="userImage" />
      ),
    },
    { name: "ID", sortable: true, selector: (row) => row.id },
    {
      name: "Last Booking Date",
      selector: (row) => row.last_booking_date,
      sortable: true,
      headerStyle: { minWidth: "800px" },
    },
    { name: "User Name", sortable: true, selector: (row) => row.username },
    {
      name: "Name",
      sortable: true,
      selector: (row) => row.first_name,
      cell: (selectors) => selectors.first_name + " " + selectors.last_name,
    },
    {
      name: "Gender",
      sortable: true,
      selector: (row) => row.gender,
      isSortable: true,
      cell: (selectors) => (
        <td className="text-capitalize">{selectors.gender}</td>
      ),
    },
    {
      name: "Group Status",
      sortable: true,
      selector: (row) => row.group_id_name,
      isSortable: true,
      cell: (selectors) =>
        selectors.group_id_name ? selectors?.group_id_name : "-",
    },
    { name: "Number Of Booking", selector: (row) => row.number_of_bookings },
    {
      name: "Total Spent",
      sortable: true,
      selector: (row) => row.total_spent,
      isSortable: true,
      cell: (selectors) => (selectors.total_spent ? selectors.total_spent : 0),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (selectors) => (
        <div className="d-flex align-items-center ">
          <button
            className="btn actionBtn"
            onClick={() => {
              history("/user-edit", { state: selectors });
            }}
          >
            <img src={Edit} alt="edit" />
          </button>
          <button
            className="btn actionBtn"
            onClick={() => {
              setDeleteId(selectors.id);
              setModalShow(!modalShow);
            }}
          >
            <img src={Delete} alt="edit" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="mainboxes">
      <TopHeader title={"User Listing"} />
      <div className="row align-items-center searchMain pb-3 mb-3">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete?</h4>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setModalShow(!modalShow);
              }}
            >
              Close
            </button>
            <button
              className="btn enableBtnActive"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>
        <div className="col-xxl-6 col-lg-6 col-md-12 d-flex align-items-center ">
          <SearchBar placeholder={"Search User"} search={debounce_fun} />
          {/* <DropdownButton
            id="dropdown-basic-button"
            title="Last booking"
            className="bg-transparent lastBooking"
          >
            <Dropdown.Item href="#/action-1">Gym 1</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Gym 2</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Gym 3</Dropdown.Item>
          </DropdownButton> */}
        </div>
        <div className="col-xxl-4 col-lg-4 col-md-8 d-flex align-items-center tabletSpacing mobileCenter">
          <DropdownButton
            id="dropdown-basic-button"
            title={filedFilter}
            className="bg-transparent listDropDown"
            onSelect={(e) => {
              e = e.split(",");
              setFieldFilter(e[1]);
              setFieldColumn(e[0]);
              handleColumnFilter(e[0]);
            }}
          >
            <Dropdown.Item eventKey={["id", "All"]}>All</Dropdown.Item>
            <Dropdown.Item eventKey={["total_spent", "Total Spent"]}>
              Total Spent
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton
            id="dropdown-basic-button"
            title={filter}
            className="bg-transparent listDropDown mobileDrop"
            onSelect={(e) => {
              setFilter(e === "desc" ? "Most Recent" : "Oldest");
              setSort(e);
              handleFilter(e);
            }}
          >
            <Dropdown.Item eventKey={"desc"}>Most Recent</Dropdown.Item>
            <Dropdown.Item eventKey={"asc"}>Oldest</Dropdown.Item>
          </DropdownButton>
        </div>
        {/* <div className="col-xxl-2 col-lg-2 col-md-4 tabletSpacing">
          <button
            className="btn createGymBtn tabletGymBtn"
            onClick={() => history("user-create")}
          >
            Create User
          </button>
        </div> */}
      </div>
      <div className="row">
        <div className="table-responsive">
          {isLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : (
            <DataTable columns={header} data={userList} pagination />
          )}
        </div>
      </div>
    </div>
  );
}

export default Users;
