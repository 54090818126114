import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./reducer/index";

const customMiddleWare = (store) => (next) => (action) => {
  next(action, store);
};
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["login"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(
  persistedReducer,
  compose(applyMiddleware(thunk, customMiddleWare))
);

export default store;
