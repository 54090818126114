import _ from "lodash";
import dayjs from "dayjs";
import moment from "moment";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker as DatePicker } from "@mui/x-date-pickers/DateTimePicker";

import {
  editGymDetails,
  getGymDetails,
  deleteGymDetail,
} from "../action/gymDetails";
import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
import Edit from "../Components/svgImg/Edit.svg";
import Delete from "../Components/svgImg/Delete.svg";

import "../Components/CSSfiles/GymListing.css";

function GymListing(props) {
  const index = 1;
  const history = useNavigate();
  const dispatch = useDispatch();
  const [sort, setSort] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [filter, setFilter] = useState("Oldest");
  const [currentGym, setCurrentGym] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  // const [selectedGym, setSelectedGym] = useState([]);
  const [filedFilter, setFieldFilter] = useState("All");
  const [filedColumn, setFieldColumn] = useState("");
  const [showDateRange, setShowDateRange] = useState(false);
  const gymList = useSelector((state) =>
    state.gymDetail.gymList ? state.gymDetail.gymList : []
  );
  const isLoading = useSelector((state) => state.gymDetail.data?.isProcess);
  const [selectionRange, setSelectionRange] = useState({
    startDate: dayjs(),
    endDate: dayjs(),
    key: "selection",
  });

  const applyCallback = (startDate, endDate) => {
    console.log("Apply Callback");
    console.log(dayjs(startDate));
    console.log(endDate);
    let data = selectionRange;

    if (startDate) {
      data.startDate = new Date(startDate);
    }
    if (endDate) {
      data.endDate = new Date(endDate);
    }
    console.log(data);
    setSelectionRange({ ...data });
  };

  useEffect(() => {
    dispatch(getGymDetails("", 1, 100));
  }, [dispatch]);

  const handleDelete = () => {
    dispatch(deleteGymDetail(deleteId));
    setModalShow(!modalShow);
    dispatch(getGymDetails("", 1, 100));
  };

  const handleActiveService = (e, id) => {
    const succesCallBack = () => {
      dispatch(getGymDetails("", 1, 100));
    };
    let gymObj = {
      id: id,
      service_status: e ? "1" : "0",
    };
    dispatch(editGymDetails([gymObj], succesCallBack));
  };

  const handleSaveService = () => {
    const succesCallBack = () => {
      dispatch(getGymDetails("", 1, 100));
    };
    let gymObj = {
      id: currentGym,
      service_start: moment(selectionRange.startDate)
        .utc()
        .format("YYYY-MM-DD  HH:mm:ss"),
      service_end: moment(selectionRange.endDate)
        .utc()
        .format("YYYY-MM-DD  HH:mm:ss"),
    };
    console.log("\n\n\n\n\n", gymObj);
    dispatch(editGymDetails([gymObj], succesCallBack));
    setShowDateRange(!showDateRange);
  };
  console.log(selectionRange);
  const handleSearch = (data) => {
    dispatch(getGymDetails(data, 1, 200));
  };

  const debounce_fun = _.debounce(function (response) {
    handleSearch(response);
  }, 500);

  const handleFilter = (value) => {
    dispatch(getGymDetails("", 1, 10, value, filedColumn));
  };

  const handleColumnFilter = (value) => {
    dispatch(getGymDetails("", 1, 10, sort, value));
  };

  // const handleAddGym = (e, data) => {
  //   let arr = selectedGym;
  //   if (arr.length > 0) {
  //     let gym = _.find(arr, function (g) {
  //       if (g.id === data.id) return g;
  //     });
  //     if (gym) {
  //       _.remove(arr, function (g) {
  //         return g.id === data.id;
  //       });
  //     } else {
  //       arr.push(data);
  //     }
  //   } else {
  //     arr.push(data);
  //   }

  //   setSelectedGym(arr);
  // };
  const header = [
    // {
    //   title: "",
    //   prop: "",
    //   cell: (props) => (
    //     <InputGroup.Checkbox
    //       aria-label="Checkbox for following text input"
    //       name="isAdd"
    //       onChange={(e) => handleAddGym(e, props)}
    //     />
    //   )
    // },
    {
      name: "Photo",
      selector: (row) => row.images,
      cell: (selectors) => (
        <td>
          <img
            src={selectors.images ? selectors.images.split(",")[0] : ""}
            alt="img"
            className="gymPhoto"
          />
        </td>
      ),
    },
    { name: "Id", sortable: true, selector: (row) => row.id },
    { name: "Name", sortable: true, selector: (row) => row.name },
    { name: "Address", sortable: true, selector: (row) => row.address },
    { name: "Gym type", sortable: true, selector: (row) => row.gym_type },
    { name: "Area", width: "5%", sortable: true, selector: (row) => row.area },
    {
      name: "Gym Servicing Mode",
      width: "35%",
      selector: (row) => row.service_status,
      cell: (selectors) => (
        <td className="Gym_Servicing_Model">
          <div className="form-switch p-0 custom-control custom-switch d-flex align-items-center">
            <input
              className="form-check-input checkboxes"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              defaultChecked={selectors.service_status === 1 ? true : false}
              disabled
              onChange={(e) => {
                handleActiveService(e, selectors.id);
              }}
            />
            {/* <label className="form-check-label" for="flexSwitchCheckDefault" /> */}
            <span className="mx-2">
              <span
                className={
                  selectors.service_status === 1
                    ? "switchText activeSwitch"
                    : "switchText 123"
                }
                onClick={(e) => {
                  handleActiveService(true, selectors.id);
                }}
              >
                ON
              </span>
              <span
                className={
                  selectors.service_status === 0 || !selectors.service_status
                    ? "switchText deactive"
                    : "switchText"
                }
                onClick={(e) => {
                  handleActiveService(false, selectors.id);
                }}
              >
                OFF
              </span>
            </span>
            {selectors.service_status === 1 && (
              <>
                <span
                  className={`switchTime mx-2 ${index === 1 && "activeSwitch"}`}
                >
                  {selectors.service_start &&
                    moment(selectors.service_start).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                </span>
                <>
                  <button
                    className="btn btn-border mx-2"
                    onClick={() => {
                      setSelectionRange({
                        startDate: new Date(selectors.service_start),
                        endDate: new Date(selectors.service_end),
                        key: "selection",
                      });
                      setShowDateRange(!showDateRange);
                      setCurrentGym(selectors.id);
                    }}
                  >
                    Schedule
                  </button>
                </>
              </>
            )}
          </div>
        </td>
      ),
    },
    {
      name: "Start Date",
      sortable: true,
      selector: (row) => row.start_date,
      cell: (selectors) => moment(selectors.start_date).format("DD/MM/YYYY"),
    },
    {
      name: "End Date",
      sortable: true,
      selector: (row) => row.end_date,
      cell: (selectors) => moment(selectors.end_date).format("DD/MM/YYYY"),
    },
    {
      name: "Action",
      selector: "action",
      cell: (selectors) => (
        <div className="d-flex align-items-center">
          <button
            className="btn actionBtn"
            onClick={() => {
              history("/gym-detail", { state: selectors });
            }}
          >
            <img src={Edit} alt="edit" />
          </button>
          <button
            className="btn actionBtn"
            onClick={() => {
              setModalShow(!modalShow);
              setDeleteId(selectors.id);
            }}
          >
            <img src={Delete} alt="delete" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="mainboxes">
      <TopHeader title={"Gym"} />
      <div className="row align-items-center searchMain pb-3 mb-3">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Gym
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete?</h4>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setModalShow(!modalShow);
              }}
            >
              Close
            </button>
            <button
              className="btn enableBtnActive"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showDateRange}
          onHide={() => setShowDateRange(false)}
        >
          <Modal.Header closeButton>Schedule</Modal.Header>
          <Modal.Body className="date-selector">
            {/* <div className='rangePicker' key={counter}> */}

            {/* <DateTimePicker
              onChange={(value) => applyCallback(value)}
              value={selectionRange.startDate}
              calendarIcon={null}
              format={"y-MM-dd h:mm:ss a"}
              className={"start-date-picker"}
            /> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div>
                <DatePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Start Date"
                  value={selectionRange.startDate}
                  onChange={(newValue) => {
                    applyCallback(newValue);
                  }}
                  className="start-date-picker"
                />
              </div>
              {/* <div>End Date :</div>
              <DateTimePicker
                onChange={(value) => applyCallback("", value)}
                value={selectionRange.endDate}
                calendarIcon={null}
                format={"y-MM-dd h:mm:ss a"}
                className={"start-date-picker"}
                minDate={selectionRange.startDate}
              /> */}
              <br />
              <DatePicker
                renderInput={(props) => <TextField {...props} />}
                label="End Date"
                value={selectionRange.endDate}
                minDate={selectionRange.startDate}
                onChange={(newValue) => {
                  applyCallback("", newValue);
                }}
              />
            </LocalizationProvider>
            {/* <DateRangePicker
              ranges={[selectionRange]}
              showSelectionPreview={false}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
              onChange={(item) => {
                setSelectionRange(item.selection);
              }}
            /> */}

            {/* </div> */}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary cancelBtn"
              onClick={() => {
                setShowDateRange(!showDateRange);
              }}
            >
              Close
            </button>
            <button
              className="btn enableBtnActive"
              onClick={() => {
                handleSaveService();
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>

        <div className="col-xxl-4 col-lg-4 col-md-6 col-sm-12">
          <SearchBar placeholder={"Search Gym"} search={debounce_fun} />
        </div>
        <div className="col-xxl-4 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center mobileFilter">
          <DropdownButton
            id="dropdown-basic-button"
            title={filedFilter}
            className="bg-transparent listDropDown"
            onSelect={(e) => {
              e = e.split(",");
              setFieldFilter(e[1]);
              setFieldColumn(e[0]);
              handleColumnFilter(e[0]);
            }}
          >
            <Dropdown.Item eventKey={["start_date", "Start Date"]}>
              Start Date
            </Dropdown.Item>
            <Dropdown.Item eventKey={["end_date", "End Date"]}>
              End Date
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton
            id="dropdown-basic-button"
            title={filter}
            className="bg-transparent listDropDown filterDrop"
            onSelect={(e) => {
              setFilter(e === "desc" ? "Most Recent" : "Oldest");
              setSort(e);
              handleFilter(e);
            }}
          >
            <Dropdown.Item eventKey={"desc"}>Most Recent</Dropdown.Item>
            <Dropdown.Item eventKey={"asc"}>Oldest</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="col-xxl-2 col-lg-2">
          {/* <button
            className="btn createGymBtn"
          // onClick={() => history("/gym-create")}
          >
            Add Gym
          </button> */}
        </div>
        <div className="col-xxl-2 col-lg-2 d-flex justify-content-end">
          <button
            className="btn createGymBtn"
            onClick={() => history("/gym-create")}
          >
            Create New Gym
          </button>
        </div>
      </div>
      {/* {showDateRange && currentGym ? (
        <div className="rangePicker">
          <DateRangePicker
            ranges={[selectionRange]}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            onChange={(item) => {
              setSelectionRange(item.selection);
            }}
          />
        </div>
      ) : null} */}
      <div className="row">
        <div className="table-responsive">
          {isLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : (
            <DataTable columns={header} data={gymList} pagination />
          )}
        </div>
      </div>
    </div>
  );
}

export default GymListing;
