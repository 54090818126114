export const serverUrl = "http://43.245.111.101:8081";

export const LOGIN_URL = `${serverUrl}/api/v1/web/auth/login`;
export const SIGNUP_URL = `${serverUrl}/api/v1/web/auth/register`;
export const FORGOTPASS_URL = `${serverUrl}/api/v1/web/auth/forgot-password`;
export const RESETPASS_URL = `${serverUrl}/api/v1/web/auth/reset-password`;
export const GET_FRANCHISE_URL = `${serverUrl}/api/v1/web/franchise/paginate`;
export const ADD_FRANCHISE_URL = `${serverUrl}/api/v1/web/franchise`;
export const ADD_GYMDETAILS_URL = `${serverUrl}/api/v1/web/gym`;
export const EDIT_GYMDETAILS_URL = `${serverUrl}/api/v1/web/gym/update`;
export const GET_GYM_URL = `${serverUrl}/api/v1/web/gym/paginate`;
export const ADD_GROUP_URL = `${serverUrl}/api/v1/web/group`;
export const GET_GROUP_URL = `${serverUrl}/api/v1/web/group/paginate`;
export const GET_GYMUSER_URL = `${serverUrl}/api/v1/web/user/paginate`;
export const EDIT_GYMUSER_URL = `${serverUrl}/api/v1/web/user`;
export const DELETE_GYMUSER_URL = `${serverUrl}/api/v1/web/user`;
export const GET_VACCINATION_URL = `${serverUrl}/api/v1/web/vaccination/paginate`;
export const EDIT_VACCINATION_URL = `${serverUrl}/api/v1/web/vaccination`;
export const GET_BOOKINGS_URL = `${serverUrl}/api/v1/web/booking/paginate`;
export const DELETE_BOOKING_URL = `${serverUrl}/api/v1/web/booking`;
export const IMAGE_UPLOAD_URL = `${serverUrl}/api/v1/web/common/file/upload`;
export const GET_TRANSECTION_URL = `${serverUrl}/api/v1/web/transactions/paginate`;
export const GET_TRANSECTION_SHEET = `${serverUrl}/api/v1/web/transactions/export-csv`;
export const GET_CHAT_URL = `${serverUrl}/api/v1/web/chat/conversations/paginate`;
export const CREATE_CHAT_URL = `${serverUrl}/api/v1/web/chat/conversations`;
export const SEND_MSG_URL = `${serverUrl}/api/v1/web/chat/messages`;
export const GET_MSG_URL = `${serverUrl}/api/v1/web/chat/messages-list/paginate`;
export const GET_FEEDBACK_URL = `${serverUrl}/api/v1/web/feedback/paginate`;
export const GET_LOGIN_USER_URL = `${serverUrl}/api/v1/web/auth/get-auth-user`;
export const GET_FINANCE_URL = `${serverUrl}/api/v1/web/dashboard/finance`;
export const GET_REPORT_URL = `${serverUrl}/api/v1/web/reports/paginate`;
export const GET_BOOKING_URL = `${serverUrl}/api/v1/web/dashboard/bookings`;
export const GET_DASHBOARD_GYM_URL = `${serverUrl}/api/v1/web/dashboard/gym`;
export const GET_FINANCE_EARNING_URL = `${serverUrl}/api/v1/web/dashboard/finance/earnings`;
export const GET_GROUP_EARNING_URL = `${serverUrl}/api/v1/web/dashboard/finance/group-earnings`;
export const GET_FINANCE_WALLET_URL = `${serverUrl}/api/v1/web/dashboard/finance/wallet`;
export const SET_VACCINATION_URL = `${serverUrl}/api/v1/web/vaccination/multi-update`;
export const GET_NOTIFICATION_URL = `${serverUrl}/api/v1/web/notification/paginate`;
export const SEND_NOTIFICATION_URL = `${serverUrl}/api/v1/web/common/send-notification`;
export const GET_DASHBOARD_COUNT_URL = `${serverUrl}/api/v1/web/dashboard/users`;
export const DOOR_OPEN_URL = `${serverUrl}/api/v1/web/gym/door-open`;
export const READ_ALL_MSG = `${serverUrl}/api/v1/web/chat/read-all`;
export const UNREAD_MSG = `${serverUrl}/api/v1/web/chat/mark-as-unread`;
export const DEBIT_AMOUNT_URL = `${serverUrl}/api/v1/web/user/debit-wallet-amount`;
export const CREDIT_AMOUNT_URL = `${serverUrl}/api/v1/web/user/credit-wallet-amount`;
export const UNREAD_FEEDBACK_URL = `${serverUrl}/api/v1/web/chat/unread-count`;
export const CHANGE_CHAT_STATUS = `${serverUrl}/api/v1/web/chat/update-status`;
