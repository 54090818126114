import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Group from "../../../Components/GymPics/Group-21-1.png";
import Group1 from "../../../Components/GymPics/Group-21-2.png";
import Group2 from "../../../Components/GymPics/Group-21-3.png";

import styles from "./index.module.scss";

export default function Swipper() {
  return (
    <>
      <Swiper
        direction={"vertical"}
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          type: "bullets",
          renderBullet: function (index, className) {
            return `<span class="${className}"><b></b></span>`;
          },
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={Group} className={styles.bannerImg} alt="group" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Group1} className={styles.bannerImg} alt="group" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Group2} className={styles.bannerImg} alt="group" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
