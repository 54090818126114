import { toast } from "react-toastify";
import {
  ADD_GYMDETAILS_URL,
  DOOR_OPEN_URL,
  EDIT_GYMDETAILS_URL,
  GET_DASHBOARD_GYM_URL,
  GET_GYM_URL,
} from "../comman/urls";
import {
  ADD_GYMDETAILS_FAIL,
  ADD_GYMDETAILS_REQUEST,
  ADD_GYMDETAILS_SUCCESS,
  DELETE_GYMDETAILS_REQUEST,
  DELETE_GYMDETAILS_SUCCESS,
  EDIT_GYMDETAILS_FAIL,
  EDIT_GYMDETAILS_REQUEST,
  EDIT_GYMDETAILS_SUCCESS,
  GET_GYMDETAILS_FAIL,
  GET_GYMDETAILS_REQUEST,
  GET_GYMDETAILS_SUCCESS,
  GET_GYMCOUNTS_SUCCESS,
} from "./actionConstant";
import {
  functionalityApiCall,
  functionalityDeleteApiCall,
  wihtoutBodyApiCall,
} from "../comman/connect";
import { getLoginUser } from "../comman/localStorage";

const addGymDetailsRequest = () => ({
  type: ADD_GYMDETAILS_REQUEST,
});

const addGymDetailsSuccess = () => ({
  type: ADD_GYMDETAILS_SUCCESS,
});

const addGymDetailsFail = () => ({
  type: ADD_GYMDETAILS_FAIL,
});

const getGymDetailsRequest = () => ({
  type: GET_GYMDETAILS_REQUEST,
});

const getGymDetailsSuccess = (data) => ({
  type: GET_GYMDETAILS_SUCCESS,
  data,
});

const getGymDetailsFail = () => ({
  type: GET_GYMDETAILS_FAIL,
});
const deleteGymDetailsRequest = () => ({
  type: DELETE_GYMDETAILS_REQUEST,
});

const deleteGymDetailsSuccess = () => ({
  type: DELETE_GYMDETAILS_SUCCESS,
});
const editGymDetailsRequest = () => ({
  type: EDIT_GYMDETAILS_REQUEST,
});

const editGymDetailsSuccess = (data) => ({
  type: EDIT_GYMDETAILS_SUCCESS,
  data,
});

const editGymDetailsFail = (data) => ({
  type: EDIT_GYMDETAILS_FAIL,
  data,
});

const getGymCountsSuccess = (data) => ({
  type: GET_GYMCOUNTS_SUCCESS,
  data,
});

export const addGymDetails = (requestData, successCallBack) => (dispatch) => {
  dispatch(addGymDetailsRequest());
  const { token } = getLoginUser();
  if (token) {
    const data = {
      gyms: requestData,
    };

    const onSuccess = (response) => {
      dispatch(addGymDetailsSuccess(response));
      getGymDetails("", 1, 10);
      successCallBack(response);
    };
    const url = ADD_GYMDETAILS_URL;

    const onFailure = (response) => {
      dispatch(addGymDetailsFail(response));
      toast.error(response.message || response.data.message);
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const getGymDetails =
  (search, page, limit, filter, filterColumn) => (dispatch) => {
    dispatch(getGymDetailsRequest());
    const { token } = getLoginUser();
    if (token) {
      const data = {
        page: page,
        limit: limit,
        sort: `${filterColumn ? filterColumn : "id"} ${
          filter ? filter : "asc"
        }`,
        search: [
          {
            tableNo: 0,
            search: search,
            matchFields: ["name", "id", "address", "area"],
          },
        ],
      };
      const url = GET_GYM_URL;
      const onSuccess = (response) => {
        dispatch(getGymDetailsSuccess(response.data));
      };

      const onFailure = (response) => {
        dispatch(getGymDetailsFail(response));
        toast.error(response.message);
      };

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };

export const editGymDetails = (requestData, successCallBack) => (dispatch) => {
  dispatch(editGymDetailsRequest());
  const { token } = getLoginUser();
  if (token) {
    const data = {
      gyms: requestData,
    };

    const onSuccess = (response) => {
      dispatch(editGymDetailsSuccess(response));
      successCallBack(response);
    };
    const url = EDIT_GYMDETAILS_URL;

    const onFailure = (response) => {
      dispatch(editGymDetailsFail(response));
      toast.error(response.message || response.data.message);
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const deleteGymDetail = (id) => (dispatch) => {
  dispatch(deleteGymDetailsRequest());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(deleteGymDetailsSuccess());
      toast.success("Deleted Successfully");
    };
    const url = `${ADD_GYMDETAILS_URL}/${id}`;

    functionalityDeleteApiCall(url, onSuccess, token);
  }
};

export const getSlotCount = () => (dispatch) => {
  const { token } = getLoginUser();
  if (token) {
    const url = GET_DASHBOARD_GYM_URL;
    const onSuccess = (response) => {
      dispatch(getGymCountsSuccess(response.data));
    };

    const onFailure = (response) => {
      toast.error(response.message);
    };

    wihtoutBodyApiCall("GET", url, onSuccess, onFailure, token);
  }
};

export const setOpenDoor = (data) => (dispatch) => {
  dispatch(getGymDetailsRequest());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {};

    const onFailure = (response) => {
      toast.error(response.message);
    };

    functionalityApiCall(
      "POST",
      DOOR_OPEN_URL,
      data,
      onSuccess,
      onFailure,
      token
    );
  }
};
