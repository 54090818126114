import React, { useState } from "react";
import { logoutUser } from "../action/user";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../Components/CSSfiles/TopHeader.css";
import Pic1 from "../Components/GymPics/user.png";

function TopHeader(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.login?.loginUser?.user);
  const user = useSelector((state) => state.login?.userDetails);
  const [isOpen, setIsOpen] = useState(false);
  const handleLogout = () => {
    const logoutCallBack = () => {
      history("/");
    };
    dispatch(logoutUser(logoutCallBack));
    setIsOpen(false);
  };

  return (
    <div className="topbar">
      <div className="topTitle">
        <span>{props.title}</span>
      </div>
      <div className="buttons">
        {/* <div className="iconDiv">
          <img src={emailSvg} alt="icon" />
          <img src={TopBarNotif} alt="icon" />
        </div> */}
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="d-block "
        >
          <span className="user-name">
            {user && user?.first_name + " " + user?.last_name}
          </span>
          <img
            src={user?.image ? user?.image : Pic1}
            alt="profile"
            className="profile-image"
          />
          {isOpen ? (
            <div className="popoverPart">
              {/* <div>
                <button className="btn">Profile-Setting</button>
              </div> */}
              <div>
                <button className="btn" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
