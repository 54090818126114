import {
  GET_GYMUSER_FAIL,
  EDIT_GYMUSER_FAIL,
  GET_GYMUSER_SUCCESS,
  GET_GYMUSER_REQUEST,
  EDIT_GYMUSER_SUCCESS,
  EDIT_GYMUSER_REQUEST,
  GET_USERCOUNT_REQUEST,
  GET_USERCOUNT_SUCCESS,
  GET_USERCOUNT_FAIL,
} from "../action/actionConstant";
import Pic1 from "../Components/GymPics/user.png";

const intialState = {
  status: "",
  data: null,
  isProcess: false,
  message: null,
  countation: null,
};

const gymUsers = (state = intialState, action) => {
  const { data } = action;
  switch (action.type) {
    case GET_GYMUSER_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case GET_GYMUSER_SUCCESS:
      let item = data.data;
      if (data.data?.list) {
        item?.list.forEach((e) => {
          e.image = e.image ? e.image : Pic1;
        });
      }
      return {
        ...state,
        data: item,
        isProcess: false,
      };
    case GET_GYMUSER_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case EDIT_GYMUSER_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case EDIT_GYMUSER_SUCCESS:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case EDIT_GYMUSER_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
        message: data.message || data.data.message,
      };
    case GET_USERCOUNT_REQUEST:
      return {
        ...state,
        countation: null,
      };
    case GET_USERCOUNT_SUCCESS:
      return {
        ...state,
        countation: data,
      };
    case GET_USERCOUNT_FAIL:
      return {
        ...state,
        countation: data,
      };
    default:
      return state;
  }
};
export default gymUsers;
