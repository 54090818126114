import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Rectangle from "../../../Components/GymPics/Rectangle-136.jpg";
import Rectangle1 from "../../../Components/GymPics/Rectangle-137.jpg";
import Rectangle2 from "../../../Components/GymPics/Rectangle-138.jpg";

import styles from "./index.module.scss";

function Space() {
  return (
    <div className="py-5">
      <div className={styles.locationMain}>
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={6} className="d-flex justify-content-center">
              <img src={Rectangle} className={styles.bannerImg} alt="group" />
            </Col>
            <Col lg={6} md={6}>
              <div className={styles.contentMain}>
                <h2>Your Space</h2>
                <h3>
                  Own the space. Enjoy full access to the gym <br /> equipment
                  with zero waiting time.
                </h3>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center my-5">
            <Col lg={6} xs={{ order: 2 }} md={{ span: 6, order: 1 }}>
              <div className={styles.contentMain}>
                <h2>Your Choices</h2>
                <h3>
                  Choose your preferred location and personalize
                  <br /> your workout down to the lights and music.
                </h3>
              </div>
            </Col>
            <Col
              lg={6}
              md={{ span: 6, order: 2 }}
              xs={{ order: 1 }}
              className="d-flex justify-content-center"
            >
              <img src={Rectangle1} className={styles.bannerImg} alt="group" />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={6} md={6} className="d-flex justify-content-center">
              <img src={Rectangle2} className={styles.bannerImg} alt="group" />
            </Col>
            <Col lg={6} md={6}>
              <div className={styles.contentMain}>
                <h2>Your Passion</h2>
                <h3>
                  A place where you can be yourself. Not sure how <br /> to
                  reach your fitness goals? Let our personal
                  <br /> trainers be part of your health and fitness
                  <br /> journey.
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Space;
