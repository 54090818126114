import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";

import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
// import { getGymUsers } from "../action/gymUsers";
import PreviewImg from "../Components/svgImg/Eye.svg";
import RepeatImg from "../Components/svgImg/Repeat.svg";
import { imageUploadApiCall } from "../comman/connect";
import { getNotification, sendNotification } from "../action/notification";

import "../Components/CSSfiles/Notifications.css";

function Notifications(props) {
  const dispatch = useDispatch();
  // const [deleteId, setDeleteId] = useState();
  const [filter, setFilter] = useState("Oldest");
  const [endDate, setEndDate] = useState("");
  // const [tokens, setTokens] = useState([]);
  const [notificationInfo, setNotificationInfo] = useState({});
  const [uploadImage, setUploadImage] = useState();
  const [startDate, setStartDate] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);
  const isLoading = useSelector((state) => state.notification?.isProcess);
  const isSendLoading = useSelector((state) => state.notification?.isCreate);
  const notificationList = useSelector((state) =>
    state.notification.notificationList?.list
      ? state.notification?.notificationList?.list
      : []
  );
  const [previewData, setPreviewData] = useState({});
  // const isUserLoading = useSelector((state) => state.gymUsers.isProcess);
  // const userList = useSelector((state) =>
  //   state.gymUsers.data?.list ? state.gymUsers.data?.list : []
  // );

  useEffect(() => {
    dispatch(getNotification("", 1, 200));
    // dispatch(getGymUsers("", 1, 200, null, null, { type: "customer" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = () => {
    setModalShow(!modalShow);
  };

  const handleSearch = (data) => {
    dispatch(getNotification(data, 1, 200));
  };

  const debounce_fun = _.debounce(function (response) {
    handleSearch(response);
  }, 500);

  const handleFilter = (value) => {
    dispatch(getNotification("", 1, 10, value));
  };

  // const handleUserSearch = (data) => {
  //   dispatch(getGymUsers(data, 1, 200));
  // };

  // const debounce_user_fun = _.debounce(function (response) {
  //   handleUserSearch(response);
  // }, 500);

  const handleFilterData = () => {
    console.log("startDate", startDate);
    let filterObj = {
      created_at: {
        min: `${startDate} 00:00:00`,
        max: `${endDate} 23:59:59`,
      },
    };
    dispatch(getNotification("", 1, 200, null, filterObj));
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    dispatch(getNotification("", 1, 200));
  };

  // let arr = [];
  // const handleSelectedUser = (e, token) => {
  //   if (e.target.checked) {
  //     arr.push(token);
  //   } else {
  //     _.remove(arr, function (n) {
  //       return n === token;
  //     });
  //   }
  //   setTokens(arr);
  // };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setNotificationInfo((obj) => ({ ...obj, [name]: value }));
  };

  const handleUploadImages = (e) => {
    const onSuccess = (response) => {
      setUploadImage(response);
      let Obj = notificationInfo;
      Obj.imageUrl = response;
    };
    imageUploadApiCall(e.target.files, onSuccess);
  };

  const handleNotificaiton = () => {
    let obj = notificationInfo;
    // obj.tokens = tokens;
    if (obj.title && obj.title !== "" && obj.body && obj.body !== "") {
      const successCallBack = (response) => {
        setIsCreateModal(!isCreateModal);
        dispatch(getNotification("", 1, 200));
        toast.success(response);
        setUploadImage("");
      };
      dispatch(sendNotification(obj, successCallBack));
    } else {
      toast.error("Fields are required!");
    }
  };

  const handleNotificationView = (data) => {
    setPreviewData(data);
  };

  const handleRepeatNotifiaciton = (data) => {
    let obj = {
      body: data?.body,
      title: data?.title,
      imageUrl:
        data?.image_url !== "https://via.placeholder.com/150"
          ? data?.image_url
          : "",
    };
    const successCallBack = (response) => {
      dispatch(getNotification("", 1, 300));
      toast.success("Notification Resend Successfully...");
    };
    dispatch(sendNotification(obj, successCallBack));
  };

  const header = [
    { name: "Id", sortable: true, selector: (row) => row.id },
    { name: "Title", sortable: true, selector: (row) => row.title },
    {
      name: "Description",
      sortable: true,
      selector: (row) => row.description,
    },
    {
      name: "Image",
      selector: (row) => row.image_url,
      cell: (selectors) =>
        selectors.image_url && selectors.image_url !== "" ? (
          <img
            src={selectors.image_url}
            alt={selectors.image_url}
            className="gymPhoto"
          />
        ) : (
          <img
            src={"https://via.placeholder.com/150"}
            alt={"notiificationImage"}
            className="gymPhoto"
          />
        ),
    },
    {
      name: "Creation Date",
      sortable: true,
      selector: (row) => row.created_at,
      cell: (selectors) => moment(selectors.created_at).format("DD/MM/YYYY"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (selectors) => (
        <div className="d-flex align-items-center">
          <button
            className="btn previewBtn"
            onClick={() => {
              setIsViewModal(!isViewModal);
              handleNotificationView(selectors);
            }}
          >
            <img src={PreviewImg} alt="delete" />
          </button>
          <button
            data-bs-placement="top"
            title="Send this again"
            className="btn previewBtn mx-2"
            onClick={() => {
              handleRepeatNotifiaciton(selectors);
            }}
            disabled={isSendLoading ? true : false}
          >
            <img src={RepeatImg} alt="delete" className="repeatImg" />
          </button>
        </div>
      ),
    },
  ];

  // const userHeader = [
  //   {
  //     title: "",
  //     prop: "",
  //     cell: (props) => (
  //       <InputGroup.Checkbox
  //         aria-label="Checkbox for following text input"
  //         name="id"
  //         // onChange={(e) => handleSelectedUser(e, props.firebase_token)}
  //       />
  //     ),
  //   },
  //   {
  //     title: "Photo",
  //     prop: "image",
  //     cell: (props) => (
  //       <img src={props.image} alt={"user profile"} className="userImage" />
  //     ),
  //   },
  //   { title: "ID", prop: "id" },
  //   { title: "Last Booking Date", prop: "last_booking_date" },
  //   { title: "User Name", prop: "username" },
  //   {
  //     title: "Name",
  //     prop: "first_name",
  //     cell: (props) => props.first_name + " " + props.last_name,
  //   },
  //   {
  //     title: "Gender",
  //     prop: "gender",
  //     cell: (props) => <td className="text-capitalize">{props.gender}</td>,
  //   },
  // ];

  return (
    <div className="mainboxes">
      <TopHeader title={"Notification Listing"} />
      <div className="row align-items-center searchMain pb-3 mb-3">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Notification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete?</h4>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setModalShow(!modalShow);
              }}
            >
              Close
            </button>
            <button
              className="btn enableBtnActive"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={isCreateModal}
          onHide={() => setIsCreateModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Send Notification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="debitModal">
              {/* <SearchBar
                placeholder={"Search User"}
                search={debounce_user_fun}
              />
              <div className="userListSection">
                <div className="table-responsive">
                  {isUserLoading ? (
                    <div className="spinner">
                      <TailSpin heigth="75" width="100" color="black" />
                    </div>
                  ) : (
                    <Datatable
                      tableHeaders={userHeader}
                      tableBody={userList}
                      rowsPerPage={12}
                      labels={customLabels}
                    />
                  )}
                </div>
              </div> */}
              <div className="my-3">
                <p className="gymDetailBottom">Title</p>
                <input
                  type="text"
                  className="input franchiseDetailInput detailPriceSlot"
                  name={"title"}
                  onChange={handleInput}
                />
              </div>
              <div>
                <p className="gymDetailBottom">Description</p>
                <textarea
                  rows={3}
                  type="text"
                  className="input franchiseDetailInput detailPriceSlot"
                  name={"body"}
                  onChange={handleInput}
                />
              </div>
              <div className="row">
                <div className="col-xxl-6">
                  <p className="gymDetailBottom">Image</p>
                  <input
                    type="file"
                    className="input franchiseDetailInput detailPriceSlot"
                    name={"imageUrl"}
                    onChange={(e) => handleUploadImages(e)}
                  />
                </div>
                {uploadImage && uploadImage !== "" ? (
                  <div className="col-xxl-6">
                    <img
                      src={uploadImage}
                      alt="uploadImage"
                      className="notificationImage"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setIsCreateModal(!isCreateModal);
              }}
            >
              Close
            </button>
            <button
              className="btn enableBtnActive"
              onClick={() => {
                handleNotificaiton();
              }}
            >
              {!isSendLoading ? (
                "Save"
              ) : (
                <TailSpin heigth="15" width="45" color="white" />
              )}
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={isViewModal}
          onHide={() => setIsViewModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          className="viewModal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Notification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="viewNotificationMain d-flex align-items-center">
              <div>
                <h6>Image:</h6>
                <img
                  className="notificationImage mt-1"
                  src={previewData?.image_url}
                  alt="Notification img"
                />
              </div>
              <div className="mx-3">
                <div className="my-4">
                  <h6>Title</h6>
                  <p>{previewData?.title}</p>
                </div>
                <div>
                  <h6>Description</h6>
                  <p>{previewData?.description}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setIsViewModal(!isViewModal);
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        <div className="col-xxl-2 col-lg-4 col-md-6 col-sm-12 searchSec">
          <SearchBar
            placeholder={"Search Notification"}
            search={debounce_fun}
          />
        </div>
        <div className="col-xxl-2 col-lg-4 col-md-6 col-sm-12 d-flex align-items-center mobileTopSpacing">
          <div className="minWidth">Start Date : </div>
          <input
            type="date"
            value={startDate}
            className="startDate"
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </div>
        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12 mobileEndDateSec tabletTopSpacing">
          <div className="minWidth">End Date : </div>
          <input
            type="date"
            value={endDate}
            className="startDate"
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </div>
        <div className="col-xxl-2 col-lg-3 col-md-6 col-sm-5 d-flex align-items-center topSpacing tabletTopSpacing">
          <button className="btn sortBtn reportExport " onClick={handleClear}>
            Clear
          </button>
          <button
            className="btn createGymBtn exportBtn searchBtn mobileFilterBtn"
            onClick={handleFilterData}
          >
            Filter
          </button>
        </div>
        <div
          className="col-xxl-1 col-lg-2 col-md-2 col-sm-5 d-flex align-items-center  
        p-0 topSpacing mobileOldestWidth"
        >
          <DropdownButton
            id="dropdown-basic-button"
            title={filter}
            className="bg-transparent listDropDown"
            onSelect={(e) => {
              setFilter(e === "desc" ? "Most Recent" : "Oldest");
              handleFilter(e);
            }}
          >
            <Dropdown.Item eventKey={"desc"}>Most Recent</Dropdown.Item>
            <Dropdown.Item eventKey={"asc"}>Oldest</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="col-xxl-2 col-lg-2  col-md-6 col-sm-6 topSpacing mobileNotiBtn">
          <button
            className="btn createGymBtn mobileFilterBtn"
            onClick={() => {
              setIsCreateModal(!isCreateModal);
            }}
          >
            Create Notification
          </button>
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          {isLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : (
            <DataTable columns={header} data={notificationList} pagination />
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
