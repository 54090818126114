import {
  GET_REPORT_LIST_FAIL,
  GET_REPORT_LIST_REQUEST,
  GET_REPORT_LIST_SUCCESS
} from "../action/actionConstant";

const intialState = {
  reportList: null,
  isProcess: false,
  message: null
};

const report = (state = intialState, action) => {
  const { data } = action;
  switch (action.type) {
    case GET_REPORT_LIST_REQUEST:
      return {
        ...state,
        isProcess: true
      };
    case GET_REPORT_LIST_SUCCESS:
      let item = data.data?.list;
      if (item) {
        item.forEach((e) => {
          if (e.images) e.images = e.images.split(",");
        });
      }
      return {
        ...state,
        isProcess: false,
        message: data.message,
        reportList: item ? item : []
      };

    case GET_REPORT_LIST_FAIL:
      return {
        ...state,
        isProcess: false,
        reportList: null,
        message: data?.message
      };
    default:
      return state;
  }
};

export default report;
