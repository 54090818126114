import { useState } from "react";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { DropdownDate } from "react-dropdown-date";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import TopHeader from "./TopHeader";
import { validationFields } from "../comman/Hooks";
import { editFranchise } from "../action/franchise";

import "../Components/CSSfiles/FranchiseCreate.css";

function FranchiseEdit(props) {
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const defaultValue = state;
  const isLoading = useSelector((state) => state.franchise.isProcess);
  const [formErrors, setFormErrors] = useState([]);
  const [franchiseDetail, setFranchiseDetail] = useState({
    company_name: defaultValue.company_name,
    dob: defaultValue.dob,
    email: defaultValue.email,
    first_name: defaultValue.first_name,
    gender: defaultValue.gender,
    last_name: defaultValue.last_name,
    mobile: defaultValue.mobile,
    unique_id: defaultValue.unique_id,
  });
  const [date, setDate] = useState(defaultValue.dob);
  const [gender, setGender] = useState(
    defaultValue.gender ? defaultValue.gender : "Gender"
  );

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFranchiseDetail((franchiseDetail) => ({
      ...franchiseDetail,
      [name]: value,
    }));
  };

  const handleDropdown = (e) => {
    if (e) setFranchiseDetail({ ...franchiseDetail, gender: e });
  };

  const handleDate = (props) => {
    setFranchiseDetail({ ...franchiseDetail, dob: props });
  };

  const handleSubmit = () => {
    const validFieldArray = validationFields(franchiseDetail);
    if (validFieldArray.length === 0) {
      setFormErrors([]);
      const succesCallBack = () => {
        history("/franchise");
      };
      dispatch(editFranchise(id, franchiseDetail, succesCallBack));
    } else {
      setFormErrors([...validFieldArray]);
      toast.error("Fileds are required");
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  return (
    <div className="mainboxes">
      <TopHeader title={"Franchise Edit"} />
      <div className="row align-items-center searchMain pb-2 mb-3">
        <div className="col-xxl-9 col-lg-9 flex-wrap d-flex align-items-center justify-content-between">
          <div>
            <p className="topBarTitle">Franchise ID</p>
            <p className="topDetail">{defaultValue?.unique_id}</p>
          </div>
          <div>
            <p className="topBarTitle">Franchise Name</p>
            <p className="topDetail">
              {defaultValue?.first_name + " " + defaultValue?.last_name}
            </p>
          </div>
          <div>
            <p className="topBarTitle">Company Name</p>
            <p className="topDetail">{defaultValue?.company_name}</p>
          </div>
          <div>
            <p className="topBarTitle">Gym Name</p>
            <p className="topDetail">131 Jalan Bukit Merah</p>
          </div>
          <div className="mobileTopSpacing">
            <p className="topBarTitle">Franchise Start Date</p>
            <p className="topDetail">04.23. 2021</p>
          </div>
          <div className="mobileTopSpacing">
            <p className="topBarTitle">Franchise End Date</p>
            <p className="topDetail">04.23. 2025</p>
          </div>
        </div>
        <div className="col-xxl-3 col-lg-3 d-flex align-items-center justify-content-end tabletTopSpacing">
          <button
            className="btn topCancel mx-2"
            onClick={() => {
              history("/franchise");
            }}
          >
            Cancel
          </button>
          <button className="btn saveBtn franchBtn" onClick={handleSubmit}>
            {!isLoading ? (
              "Save"
            ) : (
              <TailSpin heigth="15" width="45" color="white" />
            )}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-12 col-lg-12">
          <div className="borderShadowBox franchiseeDetailBox my-3">
            <h3 className="m-0 detailHeading mb-4">
              Display Franchisee Information:
            </h3>
            <Form>
              <div className="row">
                <div className="col-xxl-3 col-lg-3">
                  <div>
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Franchisee ID
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("unique_id") && "error"
                      }`}
                      name="unique_id"
                      onChange={handleInput}
                      defaultValue={defaultValue.unique_id}
                    />
                  </div>
                  <div className="mt-4">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Franchisee Company Name
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("company_name") && "error"
                      }`}
                      name="company_name"
                      onChange={handleInput}
                      defaultValue={defaultValue.company_name}
                    />
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-3 tabletTopSpacing">
                  <div>
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      First Name
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("first_name") && "error"
                      }`}
                      name="first_name"
                      onChange={handleInput}
                      defaultValue={defaultValue.first_name}
                    />
                  </div>
                  <div className="mt-4">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Email Address
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("email") && "error"
                      }`}
                      name="email"
                      onChange={handleInput}
                      defaultValue={defaultValue.email}
                    />
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-3 tabletTopSpacing">
                  <div>
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Last Name
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("last_name") && "error"
                      }`}
                      name="last_name"
                      onChange={handleInput}
                      defaultValue={defaultValue.last_name}
                    />
                  </div>
                  <div className="mt-4">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Mobile
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("mobile") && "error"
                      }`}
                      name="mobile"
                      onChange={handleInput}
                      defaultValue={defaultValue.mobile}
                    />
                  </div>
                </div>
                <div className="col-xxl-3 col-lg-3 tabletGender tabletTopSpacing">
                  <div>
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Gender
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={gender}
                      className={`bg-transparent franchiseDetailDrop ${
                        formErrors.includes("gender") && "error-drop"
                      }`}
                      onSelect={(e) => {
                        setGender(e);
                        handleDropdown(e);
                      }}
                    >
                      <Dropdown.Item eventKey={"Male"}>Male</Dropdown.Item>
                      <Dropdown.Item eventKey={"Female"}>Female</Dropdown.Item>
                      <Dropdown.Item eventKey={"Other"}>Other</Dropdown.Item>
                    </DropdownButton>
                  </div>
                  <div className="tabletDob">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Dob (optional)
                    </p>
                    <div
                      className={`d-flex align-items-center ${
                        formErrors.includes("dob") && "error-date"
                      }`}
                    >
                      <DropdownDate
                        selectedDate={date}
                        onDateChange={(date) => {
                          setDate(formatDate(date));
                          handleDate(formatDate(date));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FranchiseEdit;
