import { toast } from "react-toastify";

import {
  EDIT_VACCINATION_FAIL,
  GET_VACCINATION_FAIL,
  GET_VACCINATION_SUCCESS,
  GET_VACCINATION_REQUEST,
  EDIT_VACCINATION_REQUEST,
  EDIT_VACCINATION_SUCCESS,
  DELETE_VACCINATION_REQUEST,
  DELETE_VACCINATION_SUCCESS,
  SET_VACCINATION_FAIL,
  SET_VACCINATION_REQUEST,
  SET_VACCINATION_SUCCESS,
} from "./actionConstant";
import { getLoginUser } from "../comman/localStorage";
import {
  functionalityApiCall,
  functionalityDeleteApiCall,
} from "../comman/connect";
import {
  EDIT_VACCINATION_URL,
  GET_VACCINATION_URL,
  SET_VACCINATION_URL,
} from "../comman/urls";

const getVaccinationRequested = () => ({
  type: GET_VACCINATION_REQUEST,
});

const getVaccinationSuccess = (data) => ({
  type: GET_VACCINATION_SUCCESS,
  data,
});

const getVaccinationFailure = () => ({
  type: GET_VACCINATION_FAIL,
});
const editVaccinationRequested = () => ({
  type: EDIT_VACCINATION_REQUEST,
});

const editVaccinationSuccess = (data) => ({
  type: EDIT_VACCINATION_SUCCESS,
  data,
});

const editVaccinationFailure = () => ({
  type: EDIT_VACCINATION_FAIL,
});

const deleteVaccinationRequested = () => ({
  type: DELETE_VACCINATION_REQUEST,
});

const deleteVaccinationSuccess = (data) => ({
  type: DELETE_VACCINATION_SUCCESS,
  data,
});

const setVaccinationFailure = () => ({
  type: SET_VACCINATION_FAIL,
});

const setVaccinationRequested = () => ({
  type: SET_VACCINATION_REQUEST,
});

const setVaccinationSuccess = (data) => ({
  type: SET_VACCINATION_SUCCESS,
  data,
});

export const getVaccination =
  (search, page, limit, filter, filterColumn, query) => (dispatch) => {
    dispatch(getVaccinationRequested());
    const { token } = getLoginUser();
    if (token) {
      const data = {
        page: page,
        limit: limit,
        sort: `${filterColumn ? filterColumn : "id"} ${
          filter ? filter : "desc"
        }`,
        search: [
          {
            tableNo: "0",
            search: search,
            matchFields: ["id"],
          },
          {
            tableNo: "1",
            search: search,
            matchFields: ["first_name", "username", "email"],
          },
        ],
        populate: [
          {
            tableName: "user",
            key: "user_id",
            projection: ["first_name", "last_name", "image"],
          },
        ],
      };
      if (query) {
        data.query = query;
      }
      const url = GET_VACCINATION_URL;
      const onSuccess = (response) => {
        dispatch(getVaccinationSuccess(response.data));
      };

      const onFailure = (response) => {
        dispatch(getVaccinationFailure());
        toast.error(response.message);
      };

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };

export const getPendingVaccination = (successCallBack) => (dispatch) => {
  dispatch(getVaccinationRequested());
  const { token } = getLoginUser();
  if (token) {
    const data = {
      page: 1,
      limit: 1000,
      query: {
        status: "null",
      },
      sort: "updated_at asc",
    };
    const url = GET_VACCINATION_URL;
    const onSuccess = (response) => {
      dispatch(getVaccinationSuccess(response.data));
      successCallBack(response.data);
    };

    const onFailure = (response) => {};

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const editVaccination =
  (id, requestData, successCallBack) => (dispatch) => {
    dispatch(editVaccinationRequested());
    const { token } = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(editVaccinationSuccess(response));
        successCallBack(response);
      };
      const url = `${EDIT_VACCINATION_URL}/${id}`;

      const onFailure = (response) => {
        dispatch(editVaccinationFailure());
        toast.error(response.message || response.data.message);
      };

      functionalityApiCall(
        "PUT",
        url,
        requestData,
        onSuccess,
        onFailure,
        token
      );
    }
  };

export const deleteVaccination = (id) => (dispatch) => {
  dispatch(deleteVaccinationRequested());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = () => {
      dispatch(deleteVaccinationSuccess());
      toast.success("Reject Successfully");
    };
    const url = `${EDIT_VACCINATION_URL}/${id}`;

    functionalityDeleteApiCall(url, onSuccess, token);
  }
};

export const setVaccinationApprove = (data, successCallBack) => (dispatch) => {
  dispatch(setVaccinationRequested());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(setVaccinationSuccess());
      successCallBack();
      toast.success("Approved...");
    };

    const onFailure = (response) => {
      dispatch(setVaccinationFailure());
      toast.error(response.message);
    };

    functionalityApiCall(
      "POST",
      SET_VACCINATION_URL,
      data,
      onSuccess,
      onFailure,
      token
    );
  }
};
