import React from "react";
import { Col, Container, Row } from "react-bootstrap";

// import Button from "../Components/Button/Button";
import Group from "../../../Components/GymPics/Rectangle-125.jpg";

import styles from "./index.module.scss";
function PricingInfo() {
  return (
    <div className={styles.bookingMain}>
      <Container>
        <Row className="align-items-center justify-content-center py-5">
          <Col xl={5} lg={6} md={6}>
            <div className={styles.contentMain}>
              <h5>Pay only when you use</h5>
              <div className="text-start">
                <li>No Sign-up fees</li>
                <li>No Membership fees</li>
                <li>No Cancellation fees</li>
              </div>
              {/* <Button title={"pricing info"} secondary /> */}
            </div>
          </Col>
          <Col xl={5} lg={6} md={6} className="d-flex justify-content-center">
            <img src={Group} className={styles.bannerImg} alt="group" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PricingInfo;
