export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGIN_USER_DETAILS_REQUEST = "LOGIN_USER_DETAILS_REQUEST";
export const LOGIN_USER_DETAILS_SUCCESS = "LOGIN_USER_DETAILS_SUCCESS";
export const LOGIN_USER_DETAILS_FAIL = "LOGIN_USER_DETAILS_FAIL";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "LOGIN_SUCCESS";

export const FORGOTPASS_FAIL = "FORGOTPASS_FAIL";
export const FORGOTPASS_REQUEST = "FORGOTPASS_REQUEST";
export const FORGOTPASS_SUCCESS = "FORGOTPASS_SUCCESS";

export const RESETPASS_FAIL = "RESETPASS_FAIL";
export const RESETPASS_REQUEST = "RESETPASS_REQUEST";
export const RESETPASS_SUCCESS = "RESETPASS_SUCCESS";

export const ADD_FRANCHISE_FAIL = "ADD_FRANCHISE_FAIL";
export const ADD_FRANCHISE_REQUEST = "ADD_FRANCHISE_REQUEST";
export const ADD_FRANCHISE_SUCCESS = "ADD_FRANCHISE_SUCCESS";

export const GET_FRANCHISE_FAIL = "GET_FRANCHISE_FAIL";
export const GET_FRANCHISE_REQUEST = "GET_FRANCHISE_REQUEST";
export const GET_FRANCHISE_SUCCESS = "GET_FRANCHISE_SUCCESS";

export const EDIT_FRANCHISE_FAIL = "EDIT_FRANCHISE_FAIL";
export const EDIT_FRANCHISE_REQUEST = "EDIT_FRANCHISE_REQUEST";
export const EDIT_FRANCHISE_SUCCESS = "EDIT_FRANCHISE_SUCCESS";

export const DELETE_FRANCHISE_FAIL = "DELETE_FRANCHISE_FAIL";
export const DELETE_FRANCHISE_REQUEST = "DELETE_FRANCHISE_REQUEST";
export const DELETE_FRANCHISE_SUCCESS = "DELETE_FRANCHISE_SUCCESS";

export const ADD_GYMDETAILS_FAIL = "ADD_GYMDETAILS_FAIL";
export const ADD_GYMDETAILS_REQUEST = "ADD_GYMDETAILS_REQUEST";
export const ADD_GYMDETAILS_SUCCESS = "ADD_GYMDETAILS_SUCCESS";

export const GET_GYMDETAILS_FAIL = "GET_GYMDETAILS_FAIL";
export const GET_GYMDETAILS_REQUEST = "GET_GYMDETAILS_REQUEST";
export const GET_GYMDETAILS_SUCCESS = "GET_GYMDETAILS_SUCCESS";

export const EDIT_GYMDETAILS_FAIL = "EDIT_GYMDETAILS_FAIL";
export const EDIT_GYMDETAILS_REQUEST = "EDIT_GYMDETAILS_REQUEST";
export const EDIT_GYMDETAILS_SUCCESS = "EDIT_GYMDETAILS_SUCCESS";

export const DELETE_GYMDETAILS_FAIL = "DELETE_GYMDETAIL_FAIL";
export const DELETE_GYMDETAILS_REQUEST = "DELETE_GYMDETAIL_REQUEST";
export const DELETE_GYMDETAILS_SUCCESS = "DELETE_GYMDETAIL_SUCCESS";

export const ADD_GROUPDETAILS_FAIL = "ADD_GROUPDETAILS_FAIL";
export const ADD_GROUPDETAILS_REQUEST = "ADD_GROUPDETAILS_REQUEST";
export const ADD_GROUPDETAILS_SUCCESS = "ADD_GROUPDETAILS_SUCCESS";

export const GET_GROUPDETAILS_FAIL = "GET_GROUPDETAILS_FAIL";
export const GET_GROUPDETAILS_REQUEST = "GET_GROUPDETAILS_REQUEST";
export const GET_GROUPDETAILS_SUCCESS = "GET_GROUPDETAILS_SUCCESS";

export const EDIT_GROUPDETAILS_FAIL = "EDIT_GROUPDETAILS_FAIL";
export const EDIT_GROUPDETAILS_REQUEST = "EDIT_GROUPDETAILS_REQUEST";
export const EDIT_GROUPDETAILS_SUCCESS = "EDIT_GROUPDETAILS_SUCCESS";

export const DELETE_GROUPDETAILS_FAIL = "DELETE_GROUPDETAILS_FAIL";
export const DELETE_GROUPDETAILS_REQUEST = "DELETE_GROUPDETAILS_REQUEST";
export const DELETE_GROUPDETAILS_SUCCESS = "DELETE_GROUPDETAILS_SUCCESS";

export const GET_GYMUSER_FAIL = "GET_GYMUSER_FAIL";
export const GET_GYMUSER_REQUEST = "GET_GYMUSER_REQUEST";
export const GET_GYMUSER_SUCCESS = "GET_GYMUSER_SUCCESS";

export const EDIT_GYMUSER_FAIL = "EDIT_GYMUSER_FAIL";
export const EDIT_GYMUSER_REQUEST = "EDIT_GYMUSER_REQUEST";
export const EDIT_GYMUSER_SUCCESS = "EDIT_GYMUSER_SUCCESS";

export const GET_VACCINATION_FAIL = "GET_VACCINATION_FAIL";
export const GET_VACCINATION_REQUEST = "GET_VACCINATION_REQUEST";
export const GET_VACCINATION_SUCCESS = "GET_VACCINATION_SUCCESS";

export const EDIT_VACCINATION_FAIL = "EDIT_VACCINATION_FAIL";
export const EDIT_VACCINATION_REQUEST = "EDIT_VACCINATION_REQUEST";
export const EDIT_VACCINATION_SUCCESS = "EDIT_VACCINATION_SUCCESS";

export const DELETE_VACCINATION_REQUEST = "DELETE_VACCINATION_REQUEST";
export const DELETE_VACCINATION_SUCCESS = "DELETE_VACCINATION_SUCCESS";

export const GET_BOOKINGS_FAIL = "GET_BOOKINGS_FAIL";
export const GET_BOOKINGS_REQUEST = "GET_BOOKINGS_REQUEST";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";

export const DELETE_BOOKINGS_REQUEST = "DELETE_BOOKINGS_REQUEST";
export const DELETE_BOOKINGS_SUCCESS = "DELETE_BOOKINGS_SUCCESS";

export const GET_TRANSECTION_FAIL = "GET_TRANSECTION_FAIL";
export const GET_TRANSECTION_REQUEST = "GET_TRANSECTION_REQUEST";
export const GET_TRANSECTION_SUCCESS = "GET_TRANSECTION_SUCCESS";

export const GET_CHAT_FAIL = "GET_CHAT_FAIL";
export const GET_CHAT_REQUEST = "GET_CHAT_REQUEST";
export const GET_CHAT_SUCCESS = "GET_CHAT_SUCCESS";

export const GET_MSG_FAIL = "GET_MSG_FAIL";
export const GET_MSG_REQUEST = "GET_MSG_REQUEST";
export const GET_MSG_SUCCESS = "GET_MSG_SUCCESS";

export const GET_FEEDBACK_FAIL = "GET_FEEDBACK_FAIL";
export const GET_FEEDBACK_REQUEST = "GET_FEEDBACK_REQUEST";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_UNREAD_FEEDBACK = "GET_UNREAD_FEEDBACK";

export const CREATE_CHAT_FAIL = "CREATE_CHAT_FAIL";
export const CREATE_CHAT_REQUEST = "CREATE_CHAT_REQUEST";
export const CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS";

export const GET_FINANCE_FAIL = "GET_FINANCE_FAIL";
export const GET_FINANCE_REQUEST = "GET_FINANCE_REQUEST";
export const GET_FINANCE_SUCCESS = "GET_FINANCE_SUCCESS";

export const GET_REPORT_LIST_FAIL = "GET_REPORT_LIST_FAIL";
export const GET_REPORT_LIST_REQUEST = "GET_REPORT_LIST_REQUEST";
export const GET_REPORT_LIST_SUCCESS = "GET_REPORT_LIST_SUCCESS";

export const GET_BOOKCOUNT_FAIL = " GET_BOOKCOUNT_FAIL";
export const GET_BOOKCOUNT_REQUEST = " GET_BOOKCOUNT_REQUEST";
export const GET_BOOKCOUNT_SUCCESS = " GET_BOOKCOUNT_SUCCESS";

export const GET_GYMCOUNTS_SUCCESS = " GET_GYMCOUNTS_SUCCESS";

export const GET_EARNING_FAIL = " GET_EARNING_FAIL";
export const GET_EARNING_REQUEST = " GET_EARNING_REQUEST";
export const GET_EARNING_SUCCESS = " GET_EARNING_SUCCESS";

export const GET_GROUP_EARNING_FAIL = " GET_GROUP_EARNING_FAIL";
export const GET_GROUP_EARNING_REQUEST = " GET_GROUP_EARNING_REQUEST";
export const GET_GROUP_EARNING_SUCCESS = " GET_GROUP_EARNING_SUCCESS";

export const GET_WALLET_FAIL = " GET_WALLET_FAIL";
export const GET_WALLET_REQUEST = " GET_WALLET_REQUEST";
export const GET_WALLET_SUCCESS = " GET_WALLET_SUCCESS";

export const SET_VACCINATION_FAIL = " SET_VACCINATION_FAIL";
export const SET_VACCINATION_REQUEST = " SET_VACCINATION_REQUEST";
export const SET_VACCINATION_SUCCESS = " SET_VACCINATION_SUCCESS";

export const GET_NOTIFICATION_FAIL = " GET_NOTIFICATION_FAIL";
export const GET_NOTIFICATION_REQUEST = " GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = " GET_NOTIFICATION_SUCCESS";

export const SEND_NOTIFICATION_FAIL = " SEND_NOTIFICATION_FAIL";
export const SEND_NOTIFICATION_REQUEST = " SEND_NOTIFICATION_REQUEST";
export const SEND_NOTIFICATION_SUCCESS = " SEND_NOTIFICATION_SUCCESS";

export const GET_USERCOUNT_FAIL = " GET_USERCOUNT_FAIL";
export const GET_USERCOUNT_REQUEST = " GET_USERCOUNT_REQUEST";
export const GET_USERCOUNT_SUCCESS = " GET_USERCOUNT_SUCCESS";

export const DEBIT_AMOUNT_FAIL = " DEBIT_AMOUNT_FAIL";
export const DEBIT_AMOUNT_REQUEST = " DEBIT_AMOUNT_REQUEST";
export const DEBIT_AMOUNT_SUCCESS = " DEBIT_AMOUNT_SUCCESS";

export const CREDIT_AMOUNT_FAIL = " CREDIT_AMOUNT_FAIL";
export const CREDIT_AMOUNT_REQUEST = " CREDIT_AMOUNT_REQUEST";
export const CREDIT_AMOUNT_SUCCESS = " CREDIT_AMOUNT_SUCCESS";

export const UPDATE_CHAT_STATUS_FAIL = " UPDATE_CHAT_STATUS_FAIL";
export const UPDATE_CHAT_STATUS_REQUEST = " UPDATE_CHAT_STATUS_REQUEST";
export const UPDATE_CHAT_STATUS_SUCCESS = " UPDATE_CHAT_STATUS_SUCCESS";
