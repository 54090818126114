import { toast } from "react-toastify";

import { GET_REPORT_URL } from "../comman/urls";
import {
  GET_REPORT_LIST_FAIL,
  GET_REPORT_LIST_REQUEST,
  GET_REPORT_LIST_SUCCESS,
} from "./actionConstant";
import { functionalityApiCall } from "../comman/connect";

const getReportRequested = () => ({
  type: GET_REPORT_LIST_REQUEST,
});

export const getReportSuccess = (data) => ({
  type: GET_REPORT_LIST_SUCCESS,
  data,
});

const getReportFailure = (data) => ({
  type: GET_REPORT_LIST_FAIL,
  data,
});

export const getReportList = (data, token) => (dispatch) => {
  dispatch(getReportRequested());

  const onSuccess = async (response) => {
    dispatch(getReportSuccess(response));
  };

  const onFailure = (response) => {
    dispatch(getReportFailure(response));
    toast.error(response.message);
  };

  functionalityApiCall(
    "POST",
    GET_REPORT_URL,
    data,
    onSuccess,
    onFailure,
    token
  );
};
