import {
  GET_FEEDBACK_FAIL,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_SUCCESS,
  GET_UNREAD_FEEDBACK,
} from "../action/actionConstant";

const intialState = {
  status: "",
  feedbackList: null,
  isProcess: false,
  unreadCount: null,
};

const feedback = (state = intialState, action) => {
  switch (action.type) {
    case GET_FEEDBACK_REQUEST:
      return {
        ...state,
        feedbackList: null,
        isProcess: true,
      };
    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackList: action.payload,
        isProcess: false,
      };
    case GET_UNREAD_FEEDBACK:
      return {
        ...state,
        unreadCount: action.payload,
      };
    case GET_FEEDBACK_FAIL:
      return {
        ...state,
        isProcess: false,
      };
    default:
      return state;
  }
};

export default feedback;
