import moment from "moment";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { DropdownDate } from "react-dropdown-date";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import TopHeader from "./TopHeader";
import { getBookings } from "../action/booking";
import { editGymUser } from "../action/gymUsers";
import { validationFields } from "../comman/Hooks";
import { getVaccination } from "../action/vaccination";
import { imageUploadApiCall } from "../comman/connect";
import CameraIcon from "../Components/svgImg/Camera.svg";
import { getGroupDetails } from "../action/groupDetails";
import MessageIcon from "../Components/svgImg/Message.svg";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { creditAmount, debitAmount } from "../action/payment";

import "../Components/CSSfiles/FranchiseCreate.css";

function UserEdit(props) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const defaultValue = state;
  const [formErrors, setFormErrors] = useState([]);
  const [gymUser, setGymUser] = useState({
    username: defaultValue?.username,
    first_name: defaultValue?.first_name,
    email: defaultValue?.email,
    last_name: defaultValue?.last_name,
    contact_no: defaultValue?.contact_no,
    gender: defaultValue?.gender,
    dob: defaultValue?.dob,
  });
  const [debitDetail, setDebitDetail] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [groupDate, setGroupDate] = useState(defaultValue?.group_join_date);
  const [groupStatus, setGroupStatus] = useState(
    defaultValue?.group_id_name ? defaultValue?.group_id_name : "Select..."
  );
  const [bookings, setBookings] = useState(true);
  const [transferValue, setTransferValue] = useState();
  const [date, setDate] = useState(
    defaultValue?.date_of_birth ? defaultValue?.date_of_birth : new Date()
  );
  const [type, setType] = useState(defaultValue.type);
  const isLoading = useSelector((state) => state.franchise.isProcess);
  const [defaultImage, setDefaultImage] = useState(defaultValue?.image);
  const [gender, setGender] = useState(
    defaultValue.gender ? defaultValue.gender : "Gender"
  );
  const loginUser = useSelector((state) => state.login?.userDetails);
  const bookingList = useSelector((state) =>
    state.bookings.data ? state.bookings?.data : []
  );
  const latestBook = bookingList?.slice(0, 5) ? bookingList?.slice(0, 5) : [];
  const vaccinationList = useSelector((state) =>
    state.vaccination.data?.list ? state.vaccination?.data?.list : []
  );
  const vacStatus = vaccinationList[0]?.status
    ? vaccinationList[0]?.status
    : "Not Approved";
  const isDebitCredit = useSelector((state) => state.payment?.isProcess);

  const groupList = useSelector((state) =>
    state.groupDetail.data?.list ? state.groupDetail.data?.list : []
  );
  useEffect(() => {
    dispatch(getBookings("", "", 1, 200, "desc", { user_id: defaultValue.id }));
    dispatch(
      getVaccination("", 1, 100, null, null, { user_id: defaultValue.id })
    );
    dispatch(getGroupDetails("", 1, 20));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setGymUser((gymUser) => ({
      ...gymUser,
      [name]: value,
    }));
  };

  const handelDebitInput = (e) => {
    const { name, value } = e.target;
    if (name === "debit_amount") {
      setDebitDetail((obj) => ({ ...obj, [name]: parseInt(value) }));
      setTransferValue(parseInt(value));
    } else {
      setDebitDetail((obj) => ({ ...obj, [name]: value }));
    }
  };

  const handleBooking = () => {
    setBookings(!bookings);
  };

  const handleDropdown = (name, e) => {
    setGymUser((gymUser) => ({
      ...gymUser,
      [name]: e,
    }));
  };

  const handleUploadImages = (e) => {
    const onSuccess = (response) => {
      setDefaultImage(response);
      let userObj = gymUser;
      userObj.image = response;
    };
    imageUploadApiCall(e.target.files, onSuccess);
  };

  const handleDate = (props) => {
    setGymUser({ ...gymUser, date_of_birth: props });
  };

  const handleGroupDate = (e) => {
    setGymUser({ ...gymUser, group_join_date: moment(e).format("YYYY-MM-DD") });
  };

  const handleDeposite = () => {
    if (
      debitDetail.debit_amount &&
      debitDetail.debit_amount !== "" &&
      debitDetail.comment &&
      debitDetail.comment !== ""
    ) {
      const succesCallBack = () => {
        if (paymentType) {
          defaultValue.wallet_amount -= transferValue;
        } else {
          defaultValue.wallet_amount += transferValue;
        }
        setModalShow(!modalShow);
      };
      if (paymentType) {
        dispatch(debitAmount(defaultValue?.id, debitDetail, succesCallBack));
      } else {
        dispatch(creditAmount(defaultValue?.id, debitDetail, succesCallBack));
      }
    } else {
      toast.error("Fields are required...");
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleSubmit = () => {
    const validFieldArray = validationFields(gymUser);
    if (validFieldArray.length === 0) {
      setFormErrors([]);
      const succesCallBack = () => {
        history("/user-listing");
      };
      dispatch(editGymUser(defaultValue.id, gymUser, succesCallBack));
    } else {
      setFormErrors([...validFieldArray]);
      toast.error("Fileds are required");
    }
  };

  const header = [
    { name: "Booking ID", sortable: true, selector: (row) => row.id },
    { name: "Gym Name", sortable: true, selector: (row) => row.gym_name },
    {
      name: "User Name",
      selector: (row) => row.user_id_username,
    },
    { name: "Type", sortable: true, selector: (row) => row.type },
    {
      name: "Date / Time",
      sortable: true,
      selector: (row) => row.created_at,
      cell: (selectors) => moment(selectors.created_at).format("DD/MM/YYYY"),
    },
    { name: "Start Time", sortable: true, selector: (row) => row.start_time },
    { name: "End Time", sortable: true, selector: (row) => row.end_time },
    {
      name: "Total Duration",
      sortable: true,
      selector: (row) => row.total_duration,
    },
    { name: "Fees", sortable: true, selector: (row) => row.booking_amount },
  ];

  return (
    <>
      <div className="mainboxes">
        <TopHeader
          title={`${defaultValue?.first_name} ${defaultValue?.last_name}`}
        />
        <div className="row align-items-center justify-content-end searchMain pb-2 mb-3">
          <div className="col-xxl-3 col-lg-3 d-flex align-items-center justify-content-end">
            <button
              className="btn topCancel mx-2"
              onClick={() => {
                history("/user-listing");
              }}
            >
              Cancel
            </button>
            <button className="btn saveBtn franchBtn" onClick={handleSubmit}>
              {!isLoading ? (
                "Update"
              ) : (
                <TailSpin heigth="15" width="45" color="white" />
              )}
            </button>
          </div>
        </div>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Debit Amount
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="debitAmountModal">
              <div className="my-3">
                <p className="gymDetailBottom">Amount</p>
                <input
                  type="number"
                  className="input franchiseDetailInput detailPriceSlot"
                  name={"debit_amount"}
                  onChange={handelDebitInput}
                />
              </div>
              <div>
                <p className="gymDetailBottom">Comment</p>
                <input
                  type="text"
                  className="input franchiseDetailInput detailPriceSlot"
                  name={"comment"}
                  onChange={handelDebitInput}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setModalShow(!modalShow);
              }}
            >
              Close
            </button>
            <button
              className="btn enableBtnActive"
              onClick={() => {
                handleDeposite();
              }}
            >
              {!isDebitCredit ? (
                paymentType ? (
                  "Debit"
                ) : (
                  "Credit"
                )
              ) : (
                <TailSpin heigth="15" width="45" color="white" />
              )}
            </button>
          </Modal.Footer>
        </Modal>
        {/* 
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={creditModalShow}
          onHide={() => setCreditModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Credit Amount
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="debitAmountModal">
              <div className="my-3">
                <p className="gymDetailBottom">Amount</p>
                <input
                  type="number"
                  className="input franchiseDetailInput detailPriceSlot"
                  name={"credit_amount"}
                  onChange={handelCreditInput}
                />
              </div>
              <div>
                <p className="gymDetailBottom">Comment</p>
                <input
                  type="text"
                  className="input franchiseDetailInput detailPriceSlot"
                  name={"comment"}
                  onChange={handelCreditInput}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setCreditModalShow(!creditModalShow);
              }}
            >
              Close
            </button>
            <button
              className="btn enableBtnActive"
              onClick={() => {
                handleCredit();
              }}
            >
              Credit
            </button>
          </Modal.Footer>
        </Modal> */}

        <div className="row">
          <div className="col-xxl-3 col-lg-12">
            <div className="row">
              <div className="col-xxl-12 col-lg-6 mt-3">
                <div className="borderShadowBox profilePicBox slideImageBox">
                  <div className="UserImage">
                    {defaultImage && (
                      <img
                        className="profileImage"
                        src={defaultImage}
                        alt="profile"
                      />
                    )}
                    {loginUser?.type === defaultValue?.type && (
                      <button className="btn chnagePicBtn">
                        <input
                          type="file"
                          name="images"
                          multiple
                          className="picInput"
                          onChange={(e) => {
                            handleUploadImages(e);
                          }}
                        ></input>
                        <img src={CameraIcon} alt="cam" className="mx-1" />{" "}
                        Upload Photo
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xxl-12 col-lg-6">
                <div className="borderShadowBox userWalletBox">
                  <div className="msgBtnDiv">
                    <button className="btn msgUserBtn">
                      <img src={MessageIcon} alt="cam" className="mx-2" />{" "}
                      Message User
                    </button>
                  </div>
                  <div className="userWalletDiv">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="userBoxTitle">Wallet Balance</span>
                        <p className="m-0 userBoxValue changeValuecolor mt-1">
                          {`$ ${
                            defaultValue?.wallet_amount
                              ? defaultValue?.wallet_amount
                              : 0
                          }  USD`}
                        </p>
                      </div>
                      <div>
                        <span className="userBoxTitle">Total Spent</span>
                        <p className="m-0 userBoxValue mt-1">
                          ${" "}
                          {defaultValue?.total_spent
                            ? defaultValue?.total_spent
                            : 0}
                        </p>
                      </div>
                    </div>
                    <div className="cdBtns">
                      <button
                        className="btn userCDBtn"
                        onClick={() => {
                          setModalShow(!modalShow);
                          setPaymentType(false);
                        }}
                      >
                        Credit
                      </button>
                      <button
                        className="btn userCDBtn mx-2"
                        onClick={() => {
                          setModalShow(!modalShow);
                          setPaymentType(true);
                        }}
                      >
                        Debit
                      </button>
                    </div>
                  </div>
                  <div
                    className={`${
                      loginUser?.type !== "SUPER_ADMIN"
                        ? "cardDetailMain"
                        : "adminCardDetailMain"
                    }`}
                  >
                    <p className="userBoxTitle">Default Credit Card</p>
                    <div className="d-flex justify-content-between align-items-end">
                      <div>
                        <span className="userBoxTitle">My Card TEB</span>
                        <p className="m-0 mt-1 fs-5">**** **** **** 4545</p>
                      </div>
                      {loginUser?.type !== "SUPER_ADMIN" && (
                        <button className="btn userCDBtn">Credit</button>
                      )}
                    </div>
                  </div>
                  <div className="cdBtnsDiv">
                    <button className="btn userCDBtn">Delete User</button>
                    <button className="btn userCDBtn mx-2">Ban User</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-9 col-lg-12">
            <div className="borderShadowBox userProfileBox my-3">
              <div className="row">
                <div className="col-xxl-4 col-lg-4">
                  <div>
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      User Name
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("username") && "error"
                      }`}
                      name="username"
                      onChange={handleInput}
                      defaultValue={defaultValue.first_name}
                    />
                  </div>
                  <div className="mt-4">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      First Name
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("first_name") && "error"
                      }`}
                      name="first_name"
                      onChange={handleInput}
                      defaultValue={defaultValue.first_name}
                    />
                  </div>
                  <div className="mt-4">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Email Address
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("email") && "error"
                      }`}
                      name="email"
                      onChange={handleInput}
                      defaultValue={defaultValue.email}
                    />
                  </div>
                </div>

                <div className="col-xxl-4 col-lg-4">
                  <div className="d-flex align-items-center tabletTopSpace">
                    <div className="groupDrop px-1">
                      <p className="topBarTitle franchiseeDetailBoxTitle">
                        Group Status
                      </p>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={groupStatus}
                        className={`bg-transparent profileDrop ${
                          formErrors.includes("group_id") && "error"
                        }`}
                        onSelect={(e) => {
                          e = e.split(",");
                          setGroupStatus(e[1]);
                          handleDropdown("group_id", e[0]);
                        }}
                      >
                        {groupList?.map((item, index) => (
                          <Dropdown.Item
                            key={item?.id.toString()}
                            eventKey={[item?.id, item?.name]}
                          >
                            s{item?.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </div>
                    <div className="expiryDrop px-1">
                      <p className="topBarTitle franchiseeDetailBoxTitle">
                        Group Expiry Date
                      </p>
                      <input
                        type="date"
                        className={`input franchiseDetailInput startDate ${
                          formErrors.includes("group_join_date") && "error"
                        }`}
                        onChange={(e) => {
                          setGroupDate(e.target.value);
                          handleGroupDate(e.target.value);
                        }}
                        defaultValue={groupDate}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Last Name
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("last_name") && "error"
                      }`}
                      name="last_name"
                      onChange={handleInput}
                      defaultValue={defaultValue.last_name}
                    />
                  </div>
                  <div className="mt-4">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Mobile
                    </p>
                    <input
                      type="text"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("contact_no") && "error"
                      }`}
                      name="contact_no"
                      onChange={handleInput}
                      defaultValue={defaultValue.contact_no}
                    />
                  </div>
                </div>

                <div className="col-xxl-3 col-lg-3 tabletTopSpace">
                  <div>
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Group Renewal Status
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Automatic"
                      className="bg-transparent groupThirdDrop"
                    >
                      <Dropdown.Item href="#/action-1">Male</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Female</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Other</Dropdown.Item>
                    </DropdownButton>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="mt-4">
                      <p className="topBarTitle franchiseeDetailBoxTitle">
                        Gender
                      </p>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={gender}
                        className={`bg-transparent franchiseDetailDrop w-90 ${
                          formErrors.includes("gender") && "error"
                        }`}
                        onSelect={(e) => {
                          setGender(e);
                          handleDropdown("gender", e);
                        }}
                      >
                        <Dropdown.Item eventKey={"Male"}>Male</Dropdown.Item>
                        <Dropdown.Item eventKey={"Female"}>
                          Female
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={"Other"}>Other</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className="mobileGender mt-4 mx-3">
                      <p className="topBarTitle franchiseeDetailBoxTitle">
                        Type
                      </p>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={type}
                        className="bg-transparent franchiseDetailDrop"
                        onSelect={(e) => {
                          e = e.split(",");
                          setType(e[1]);
                          handleDropdown("type", e[0]);
                        }}
                        disabled={
                          loginUser?.type === "SUPER_ADMIN" ||
                          loginUser?.type === "GYM_ADMIN"
                            ? false
                            : true
                        }
                      >
                        {/* <Dropdown.Item eventKey={["SUPER_ADMIN", "Super admin"]}>
                        Super admin
                      </Dropdown.Item> */}
                        <Dropdown.Item
                          eventKey={["FRANCHISE_ADMIN", "Franchise admin"]}
                        >
                          Franchise admin
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={["FRANCHISE_STAFF", "Franchise staff"]}
                        >
                          Franchise staff
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={["GYM_ADMIN", "Gym admin"]}>
                          Gym admin
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={["GYM_STAFF", "Gym staff"]}>
                          Gym staff
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={["CUSTOMER", "Customer"]}>
                          Customer
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                  <div className="mt-4 dobSection">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Dob (optional)
                    </p>
                    <div
                      className={`d-flex align-items-center ${
                        formErrors.includes("dob") && "error-date"
                      }`}
                    >
                      <DropdownDate
                        selectedDate={date}
                        onDateChange={(date) => {
                          setDate(formatDate(date));
                          handleDate(formatDate(date));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="verifyProfileMain">
                <div>
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Vaccination Status
                  </p>
                  <input
                    type="text"
                    className="input franchiseDetailInput"
                    value={vacStatus === "Approve" ? "Verified" : vacStatus}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="borderShadowBox profileTableBox my-3">
              <div className="d-flex align-items-center mb-2">
                <p
                  className={`btn ${
                    bookings ? "bookingsBtnActive" : "bookingsBtn"
                  }`}
                  onClick={() => handleBooking(1)}
                >
                  Current bookings
                </p>
                <p
                  className={`btn mx-4 ${
                    !bookings ? "bookingsBtnActive" : "bookingsBtn"
                  }`}
                  onClick={() => handleBooking(2)}
                >
                  booking history
                </p>
              </div>
              <div className="row">
                <div className="table-responsive latestBookingShow">
                  {isLoading ? (
                    <div className="spinner">
                      <TailSpin heigth="75" width="100" color="black" />
                    </div>
                  ) : (
                    <DataTable
                      columns={header}
                      data={bookings ? latestBook : bookingList}
                      pagination
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserEdit;
