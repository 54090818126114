import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";

export const Pagination = ({ data, changePage, perPage }) => {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    Paginate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * perPage) % data.length;
    setItemOffset(newOffset);
  };

  const Paginate = () => {
    changePage(currentItems);
  };
  Paginate();

  return (
    <ReactPaginate
      previousLabel={"previous"}
      nextLabel={"next"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={pageCount}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      pageClassName={"page-item"}
      activeClassName={"active"}
      pageLinkClassName={"page-link"}
      previousLinkClassName={"page-link"}
      nextLinkClassName={"page-link"}
    />
  );
};
