/* eslint-disable  no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable  no-useless-computed-key */
import moment from "moment";
import { toast } from "react-toastify";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import TimePicker from "react-time-picker";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import AliceCarousel from "react-alice-carousel";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { Dropdown, DropdownButton, InputGroup } from "react-bootstrap";

import TopHeader from "./TopHeader";
import { getFranchise } from "../action/franchise";
import { addGymDetails } from "../action/gymDetails";
import CameraIcon from "../Components/svgImg/Camera.svg";
import { getTimeStops, imageUploadApiCall } from "../comman/connect";

import "../Components/CSSfiles/GymDetail.css";
import "../../node_modules/react-slideshow-image/dist/styles.css";
import "../../node_modules/react-alice-carousel/lib/alice-carousel.css";
import { validationFields } from "../comman/Hooks";

function GymCreate() {
  const index = 1;
  const [slots, setSlots] = useState([]);
  // const [enable, setEnable] = useState(false);
  // const target = useRef(null);
  const history = useNavigate();
  const dispatch = useDispatch();
  // const [show, setShow] = useState(false);
  const [value, setValue] = useState(null);
  const [enable24, setEnable24] = useState(false);
  const [enablePeak, setEnablePeak] = useState(false);
  const [enableSuperPeak, setEnableSuperPeak] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [qrImage, setQrImage] = useState();
  const [schedual, setSchedual] = useState(false);
  const [operationEnd, setOperationEnd] = useState("23:59");
  const [formErrors, setFormErrors] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [franchise, setFranchise] = useState("select...");
  const [gymType, setGymType] = useState("select...");
  const [operationStart, setOperationStart] = useState("08:00");
  const [defaultImages, setDefaultImages] = useState([]);
  const [minSlot, setMinSlot] = useState();
  const [gymDetail, setGymDetail] = useState({
    address: null,
    area: null,
    available_equipments: null,
    description_1: null,
    google_map_url: null,
    gym_type: null,
    images: null,
    location: null,
    max_user_pax: null,
    minutes_per_slot: null,
    open_at: null,
    peak_hour_price: null,
    peak_hour_thirty_min_price: null,
    peak_hours_max: null,
    peak_hours_min: null,
    qr_code: null,
    service_end: null,
    service_start: null,
    start_date: null,
    end_date: null,
    super_peak_hour_price: null,
    super_peak_hour_thirty_min_price: null,
    super_peak_hours_max: null,
    super_peak_hours_min: null,
  });
  const [peakHour, setPeakHour] = useState("select...");
  const [totalSlot, setTotalSlot] = useState();
  const [peakHourTo, setPeakHourTo] = useState("select...");
  // const [peakHourOne, setPeakHourOne] = useState();
  // const [peakHourTwo, setPeakHourTwo] = useState();
  const [superHour, setSuperHour] = useState();
  const [superHourTo, setSuperHourTo] = useState();
  const [lat, setLat] = useState(21.17024);
  const [long, setLong] = useState(72.831062);
  const [isDuration, setIsDuration] = useState(false);
  const isLoading = useSelector((state) => state.gymDetail.isProcess);
  const frnchiseList = useSelector((state) => state.franchise.data?.list);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  useEffect(() => {
    dispatch(getFranchise("", 1, 10));
  }, [dispatch]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (
      name === "max_user_pax" ||
      name === "area" ||
      name === "first_hour_price" ||
      name === "first_hour_thirty_min_price" ||
      name === "peak_hour_price" ||
      name === "peak_hour_thirty_min_price" ||
      name === "no_peak_hours"
    ) {
      setGymDetail((gymDetail) => ({
        ...gymDetail,
        [name]: parseInt(value),
      }));
    } else {
      setGymDetail((gymDetail) => ({
        ...gymDetail,
        [name]: value,
      }));
    }
  };

  const handleCheck = (e) => {
    const { name } = e.target;
    const value = e.target.checked;
    if (name === "twenty_four_seven") {
      if (value) {
        setEnable24(!enable24);
        setSlots(getTimeStops("00:00", "23:59", minSlot ? minSlot : "30"));
      } else {
        setEnable24(!enable24);
      }
    }
    if (name === "no_peak_hours") {
      if (value) {
        setPeakHourTo("Select...");
        setPeakHour("Select...");
        let gymObj = gymDetail;
        gymObj.peak_hours_min = null;
        gymObj.peak_hours_max = null;
        setEnablePeak(!enablePeak);
      } else {
        setEnablePeak(!enablePeak);
      }
    }
    if (name === "no_super_peak_hours") {
      if (value) {
        setSuperHourTo("Select...");
        setSuperHour("Select...");
        let gymObj = gymDetail;
        gymObj.super_peak_hours_max = null;
        gymObj.super_peak_hours_min = null;
        setEnableSuperPeak(!enableSuperPeak);
      } else {
        setEnableSuperPeak(!enableSuperPeak);
      }
    }
    setGymDetail((gymDetail) => ({
      ...gymDetail,
      [name]: value,
    }));
  };
  // const arr = [];
  // const handleEquipments = (e) => {
  //   const { name, checked } = e.target;
  //   if (checked) {
  //     arr.push(name);
  //   } else {
  //     arr.map((data, index) => {
  //       if (data === name) {
  //         arr.splice(index, 1);
  //       }
  //     });
  //   }
  // };

  const handleSubmit = () => {
    let gymObj = gymDetail;
    gymObj.service_start = moment(selectionRange.startDate).format(
      "YYYY-MM-DD  HH:mm:ss"
    );
    gymObj.service_end = moment(selectionRange.endDate).format(
      "YYYY-MM-DD  HH:mm:ss"
    );
    gymObj.location = {
      latitude: lat,
      longitude: long,
    };
    gymObj.google_map_url = value?.label ? value?.label : null;
    const validFieldArray = validationFields(gymObj);
    if (validFieldArray.length === 0) {
      setFormErrors([]);
      const succesCallBack = () => {
        history("/gym-listing");
      };
      dispatch(addGymDetails([gymObj], succesCallBack));
    } else {
      setFormErrors([...validFieldArray]);
      toast.error("Fileds are required");
    }
    console.log("validFieldArray", validFieldArray);
  };

  const handleDropdown = (name, e) => {
    // if (name == "open_at" || name == "close_at" || name == "peak_hours_min" || name == "peak_hours_max") {
    // setGymDetail({
    // ...gymDetail,
    // [name]: parseInt(e),
    // });
    // } else {
    setGymDetail({
      ...gymDetail,
      [name]: e,
    });
    // }
  };

  // const slideItems = {
  //   0: {
  //     items: 1,
  //   },
  //   1024: {
  //     items: 10,
  //   },
  // };
  const gymImageSlide = {
    0: {
      items: 1,
    },
    1024: {
      items: 1,
    },
  };

  const handleMapClick = () => {
    const location = {
      latitude: lat,
      longitude: long,
    };
    setGymDetail((gymDetail) => ({
      ...gymDetail,
      ["location"]: location,
    }));
  };

  const handleActiveService = (e) => {
    setSchedual(!schedual);
    let gymObj = gymDetail;
    gymObj.service_status = e.target.checked;
  };

  const handleUploadImages = (e) => {
    const onSuccess = (response) => {
      setDefaultImages(response.split(","));
      let gymObj = gymDetail;
      gymObj.images = response;
    };
    imageUploadApiCall(e.target.files, onSuccess);
  };

  const handleUploadQR = (e) => {
    const onSuccess = (response) => {
      setQrImage(response);
      let gymObj = gymDetail;
      gymObj.qr_code = response;
    };
    imageUploadApiCall(e.target.files, onSuccess);
  };

  // const GymEquipment = [];
  // for (let index = 0; index < 13; index++) {
  //   GymEquipment.push(
  //     <div className="text-center">
  //       <div className="gymIconDiv">
  //         <span>
  //           <img src={gym} alt="icon" />
  //         </span>
  //       </div>
  //       <div className="slideInput mt-3">
  //         <InputGroup.Checkbox
  //           type="checkbox"
  //           name={index}
  //           onChange={(e) => {
  //             handleEquipments(e);
  //           }}
  //           aria-label="Checkbox for following text input my-2 justify-content-center"
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  const defImages = [];
  if (defaultImages.length > 0) {
    defaultImages.map((data) => {
      defImages.push(
        <img src={data} alt={data} className="profileImage w-100 h-100" />
      );
    });
  } else {
    defImages.push(<h5 className="uploadImage">Upload Image</h5>);
  }

  const GoogleMapExample = withGoogleMap((props) => (
    <GoogleMap
      defaultCenter={{ lat: lat, lng: long }}
      defaultZoom={13}
      onClick={(e) => {
        setLat(e.latLng.lat());
        setLong(e.latLng.lng());
        handleMapClick();
      }}
    >
      <Marker position={{ lat: lat, lng: long }} />
    </GoogleMap>
  ));

  value &&
    geocodeByAddress(value.label ? value?.label : "")
      .then((results, Status) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLat(lat);
        setLong(lng);
        setLatLong();
      });

  const setLatLong = () => {
    const location = {
      latitude: lat,
      longitude: long,
    };
    setGymDetail((gymDetail) => ({
      ...gymDetail,
      ["location"]: location,
    }));
  };

  return (
    <div className="mainboxes">
      <TopHeader title={"Create New Gym"} />
      <div className="row align-items-center searchMain pb-2 mb-3">
        <div className="topSection  flex-wrap">
          <div className="col-xxl-5 col-lg-5 col-md-6 d-flex align-items-center">
            <p className="gymTopBarTitle">Down time</p>
            <div className="topDetail topGroupDetail">
              <div className="form-switch custom-control custom-switch p-0 d-flex align-items-center">
                <input
                  className="form-check-input checkboxes"
                  type="checkbox"
                  role="switch"
                  value={schedual}
                  id="flexSwitchCheckDefault"
                  onChange={(e) => {
                    handleActiveService(e);
                  }}
                />
                <label
                  className="form-check-label"
                  for="flexSwitchCheckDefault"
                >
                  <span className="mx-2">
                    <span
                      className={`${
                        schedual ? "switchText activeSwitch" : "switchText"
                      }`}
                    >
                      ON
                    </span>
                    <span
                      className={`${
                        !schedual ? "switchText deactive" : "switchText"
                      }`}
                    >
                      OFF
                    </span>
                  </span>
                </label>
                {schedual && (
                  <>
                    <span
                      className={`switchTime mx-2 ${
                        index === 1 && "activeSwitch"
                      }`}
                    >
                      {moment(selectionRange.startDate).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </span>
                    <>
                      <button
                        className="btn btn-border mx-2"
                        onClick={() => setShowDateRange(!showDateRange)}
                      >
                        Schedule
                      </button>
                      {showDateRange && (
                        <div className="rangePicker">
                          <DateRangePicker
                            ranges={[selectionRange]}
                            showSelectionPreview={false}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            direction="horizontal"
                            onChange={(item) => {
                              setSelectionRange(item.selection);
                            }}
                          />
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn saveBtn downBtn m-2"
                              onClick={() => {
                                setShowDateRange(!showDateRange);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <div className="col-xxl-4 col-lg-4 col-md-6 d-flex align-items-center mobileTrace tabletSizeSpace">
            <p className="gymTopBarTitle">TraceTogether</p>
            <div className="topDetail topGroupDetail">
              <button
                className={`btn enableBtn ${enable && "enableBtnActive"}`}
                onClick={() => setEnable(!enable)}
              >
                Enable
              </button>
              <button
                className={`btn disableBtn ${!enable && "disableBtnActive"}`}
                onClick={() => setEnable(!enable)}
              >
                Disable
              </button>
            </div>
          </div> */}
          {/* <div className="d-flex align-items-center">
            <p className="gymTopBarTitle">Open door</p>
            <div className="topDetail topGroupDetail">
              <button className="btn btn-border doorBtn detailBtn">
                {"<> Open Door"}
              </button>
            </div>
          </div> */}
          <div className="col-xxl-3 col-lg-3 col-md-6 d-flex align-items-center hotizontalAlign mobileTopSpace">
            <button
              className="btn topCancel mx-2"
              onClick={() => {
                history("/gym-listing");
              }}
            >
              Cancel
            </button>
            <button
              className="btn saveBtn franchBtn"
              onClick={() => {
                handleSubmit();
              }}
            >
              {!isLoading ? (
                "Save"
              ) : (
                <TailSpin heigth="15" width="45" color="white" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xxl-4 col-lg-12">
          <div className="row">
            <div className="col-xxl-12 col-lg-6 mt-3">
              <div className="borderShadowBox profilePicBox slideImageBox">
                <div className="gymImagesSlide">
                  <AliceCarousel
                    mouseTracking
                    items={defImages}
                    responsive={gymImageSlide}
                  />
                </div>
                <button className="btn chnagePicBtn">
                  <input
                    type="file"
                    name="images"
                    multiple
                    className="picInput"
                    onChange={(e) => {
                      handleUploadImages(e);
                    }}
                  ></input>
                  <img
                    src={CameraIcon}
                    alt="cam"
                    className="mx-1 cursor-pointer"
                  />{" "}
                  Upload Photo
                </button>
              </div>
            </div>

            <div className="col-xxl-12 col-lg-6">
              <div className="borderShadowBox userWalletBox">
                <div className="mt-4">
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Address
                  </p>
                  <input
                    type="text"
                    className={`input franchiseDetailInput ${
                      formErrors.includes("address") && "error"
                    }`}
                    name="address"
                    onChange={handleInput}
                  />
                </div>
                <div className="mt-4">
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Google Maps URL
                  </p>
                  {/* <input
                    type="text"
                    className="input franchiseDetailInput"
                    name="google_map_url"
                    onChange={handleInput}
                  /> */}
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyCHMAdJH0T0nfMFglo9KbpJcnZ2-DfepyA"
                    lePlacesAutocomplete
                    placeholder="Search"
                    fetchDetails={true}
                    selectProps={{
                      isClearable: true,
                      value,
                      onChange: setValue,
                    }}
                  />
                </div>
                <div className="mt-5">
                  <div style={{ height: "300px", width: "100%" }}>
                    <GoogleMapExample
                      containerElement={
                        <div style={{ height: `100%`, width: "100%" }} />
                      }
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-8 col-lg-12">
          <div className="borderShadowBox userProfileBox my-3">
            <div className="row">
              {/* <div className="col-xxl-5 col-lg-6 col-md-12 col-sm-12 nameSection">
                <div className="createFranchiseListSec">
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Franchise List
                  </p>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={franchise}
                    disabled
                    className="bg-transparent"
                    onSelect={(e) => {
                      let value = e.split(",");
                      setFranchise(value[1]);
                      handleDropdown("franchise_id", parseInt(value[0]));
                    }}
                  >
                    {frnchiseList &&
                      frnchiseList.map((data) => {
                        return (
                          <Dropdown.Item
                            eventKey={[data.id, data.company_name]}
                          >
                            {data.company_name}
                          </Dropdown.Item>
                        );
                      })}
                  </DropdownButton>
                </div>
                <div className="createGymNameSec">
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Name Of Gym
                  </p>
                  <input
                    type="text"
                    className="input franchiseDetailInput"
                    name="name"
                    onChange={handleInput}
                  />
                </div>
              </div> */}
              <div className="col-xxl-8 col-lg-8 col-md-7 col-sm-12 mobileOperation">
                <div className="d-flex align-items-end justify-content-between">
                  <div className="detailTHreeDrop w-100">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Operation Start
                    </p>
                    <span className="timePicker">
                      <TimePicker
                        disabled={enable24 ? true : false}
                        disableClock={true}
                        format="HH:mm"
                        onChange={(value) => {
                          setOperationStart(value);
                          handleDropdown("open_at", value);
                          if (value && operationEnd && minSlot) {
                            setSlots(
                              getTimeStops(value, operationEnd, minSlot)
                            );
                          }
                        }}
                        value={operationStart}
                      />
                    </span>
                  </div>
                  <span className="to">To</span>
                  <div className="detailTHreeDrop w-100">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Operation End
                    </p>
                    <span className="timePicker">
                      <TimePicker
                        disabled={enable24 ? true : false}
                        disableClock={true}
                        format="H:m"
                        onChange={(value) => {
                          setOperationEnd(value);
                          handleDropdown("close_at", value);
                          if (operationStart && value && minSlot) {
                            setSlots(
                              getTimeStops(operationStart, value, minSlot)
                            );
                          }
                        }}
                        value={operationEnd}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4 col-md-5 col-sm-12 d-flex align-items-end">
                <div className="detailCheckmain">
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    name="twenty_four_seven"
                    onChange={handleCheck}
                  />
                  <span className="checkboxText">24 hours Operation</span>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-xxl-4 col-lg-4 col-md-8">
                <div className="dateSection">
                  <div className="detailTHreeDrop">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Start date
                    </p>
                    <input
                      type="date"
                      className={`input franchiseDetailInput startDate ${
                        formErrors.includes("start_date") && "error"
                      }`}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        handleDropdown("start_date", e.target.value);
                      }}
                    />
                  </div>
                  <span className="to">To</span>
                  <div className="detailTHreeDrop">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      End Date
                    </p>
                    <input
                      type="date"
                      className={`input franchiseDetailInput startDate ${
                        formErrors.includes("end_date") && "error"
                      }`}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        handleDropdown("end_date", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-xxl-7 col-lg-7 col-md-12">
                <div className="align-items-center justify-content-between mobileArea">
                  <div className="w-100">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Max User Pax
                    </p>
                    <input
                      type="number"
                      className={`input franchiseDetailInput detailPriceSlot ${
                        formErrors.includes("max_user_pax") && "error"
                      }`}
                      name="max_user_pax"
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mobileAriaSpace w-100">
                    <p className="topBarTitle franchiseeDetailBoxTitle">area</p>
                    <div className="areaInput">
                      <input
                        type="number"
                        className={`input franchiseDetailInput detailPriceSlot ${
                          formErrors.includes("area") && "error"
                        }`}
                        name="area"
                        onChange={handleInput}
                      />
                      <span>sqm</span>
                    </div>
                  </div>
                  <div className="fullWidth">
                    <p className="topBarTitle franchiseeDetailBoxTitle">Type</p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={gymType}
                      className={`bg-transparent innerSpace ${
                        formErrors.includes("gym_type") && "error"
                      }`}
                      onSelect={(e) => {
                        setGymType(e);
                        handleDropdown("gym_type", e);
                        if (e === "DURATION") {
                          setIsDuration(true);
                        } else {
                          setIsDuration(false);
                        }
                      }}
                    >
                      <Dropdown.Item eventKey={"SLOT"}>SLOT</Dropdown.Item>
                      <Dropdown.Item eventKey={"DURATION"}>
                        DURATION
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-lg-3 col-md-6 mobileSlot">
                <div className="d-flex align-items-end  justify-content-end">
                  <div className="slotBtn">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Minutes Per Slot
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={minSlot ? minSlot : "select..."}
                      className={`bg-transparent ${
                        formErrors.includes("minutes_per_slot") && "error"
                      }`}
                      onSelect={(e) => {
                        setMinSlot(e);
                        handleDropdown("minutes_per_slot", e);
                        if (operationStart && operationEnd && e) {
                          setSlots(
                            getTimeStops(operationStart, operationEnd, e)
                          );
                        }
                      }}
                      disabled={isDuration ? true : false}
                    >
                      <Dropdown.Item eventKey={30}>30</Dropdown.Item>
                      <Dropdown.Item eventKey={45}>45</Dropdown.Item>
                      <Dropdown.Item eventKey={60}>60</Dropdown.Item>
                    </DropdownButton>
                  </div>
                  {/* <div className="slotBtn">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Slot Per Day
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={totalSlot ? totalSlot : "select..."}
                      className="bg-transparent slotDrop"
                      onSelect={(e) => {
                        setTotalSlot(e);
                        handleDropdown("slots_per_day", e);
                      }}
                      disabled={isDuration ? true : false}
                    >
                      {slots.length > 0 ? (
                        slots.map((data, index) => (
                          <Dropdown.Item eventKey={index + 1}>
                            {index + 1}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item>select...</Dropdown.Item>
                      )}
                    </DropdownButton>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              {schedual && (
                <>
                  <div className="col-xxl-6 col-lg-6 mobileHour">
                    <div className="minBlockPrice">
                      <p className="topBarTitle franchiseeDetailBoxTitle">
                        Price (30 min block)
                      </p>
                      <input
                        type="number"
                        className={`input franchiseDetailInput ${
                          formErrors.includes("first_hour_price") && "error"
                        }`}
                        name="first_hour_price"
                        onChange={handleInput}
                      />
                    </div>
                    <div className="minBlockPrice mobileHourSpace">
                      <p className="topBarTitle franchiseeDetailBoxTitle">
                        Price (15 min block)
                      </p>
                      <input
                        type="number"
                        className={`input franchiseDetailInput ${
                          formErrors.includes("first_hour_thirty_min_price") &&
                          "error"
                        }`}
                        name="first_hour_thirty_min_price"
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-4 col-lg-6">
                    <div>
                      <p className="topBarTitle franchiseeDetailBoxTitle">
                        Normal Price
                      </p>
                      <div className="priceInput">
                        <input
                          type="number"
                          className={`input franchiseDetailInput detailPriceSlot${
                            formErrors.includes("first_hour_price") && "error"
                          }`}
                          name="first_hour_price"
                          onChange={handleInput}
                        />

                        <span>$</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-lg-2 d-flex align-items-end">
                    <div className="detailCheckmain">
                      <InputGroup.Checkbox
                        aria-label="Checkbox for following text input"
                        name="no_peak_hours"
                        onChange={handleCheck}
                      />
                      <span className="checkboxText">No Peak Hour</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row mt-4">
              <div className="col-xxl-6 col-lg-6">
                <div className="d-flex align-itmes-center justify-content-between">
                  <div className="detailPriceSlot">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Price Peak (30 min block)
                    </p>
                    <div className="priceInput">
                      <input
                        type="number"
                        className={`input franchiseDetailInput ${
                          formErrors.includes("peak_hour_price") && "error"
                        }`}
                        name="peak_hour_price"
                        onChange={handleInput}
                      />

                      <span>$</span>
                    </div>
                  </div>
                  <div className="minBlockPrice">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Price (15 min block)
                    </p>
                    <div className="priceInput">
                      <input
                        type="number"
                        className={`input franchiseDetailInput ${
                          formErrors.includes("peak_hour_thirty_min_price") &&
                          "error"
                        }`}
                        name="peak_hour_thirty_min_price"
                        onChange={handleInput}
                      />
                      <span>$</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4 tabletSizeSpace">
                <div className="d-flex align-items-end justify-content-between">
                  <div className="fullWidthBtn">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Peak Hour
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={peakHour}
                      className={`bg-transparent slotDrop ${
                        formErrors.includes("peak_hours_min") && "error"
                      }`}
                      onSelect={(e) => {
                        setPeakHour(e);
                        handleDropdown("peak_hours_min", e);
                      }}
                      disabled={enablePeak}
                    >
                      {slots.length > 0 ? (
                        slots.map((data, index) => (
                          <Dropdown.Item key={index} eventKey={data}>
                            {data}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item>select...</Dropdown.Item>
                      )}
                    </DropdownButton>
                  </div>
                  <span className="to">To</span>
                  <div className="fullWidthBtn">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={peakHourTo}
                      className={`bg-transparent slotDrop ${
                        formErrors.includes("peak_hours_max") && "error"
                      }`}
                      onSelect={(e) => {
                        setPeakHourTo(e);
                        handleDropdown("peak_hours_max", e);
                      }}
                      disabled={enablePeak}
                    >
                      {slots.length > 0 ? (
                        slots.map((data, index) => (
                          <Dropdown.Item key={index} eventKey={data}>
                            {data}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item>select...</Dropdown.Item>
                      )}
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-lg-2 d-flex align-items-end">
                <div className="detailCheckmain">
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    name="no_peak_hours"
                    onChange={handleCheck}
                  />
                  <span className="checkboxText">No Peak Hour</span>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-xxl-6 col-lg-6">
                <div className="d-flex align-itmes-center justify-content-between">
                  <div className="detailPriceSlot">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Price super off-Peak (30 min block)
                    </p>
                    <div className="priceInput">
                      <input
                        type="number"
                        className={`input franchiseDetailInput ${
                          formErrors.includes("super_peak_hour_price") &&
                          "error"
                        }`}
                        name="super_peak_hour_price"
                        onChange={handleInput}
                      />
                      <span>$</span>
                    </div>
                  </div>
                  <div className="minBlockPrice">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Price (15 min block)
                    </p>
                    <div className="priceInput">
                      <input
                        type="number"
                        name="super_peak_hour_thirty_min_price"
                        className={`input franchiseDetailInput ${
                          formErrors.includes(
                            "super_peak_hour_thirty_min_price"
                          ) && "error"
                        }`}
                        onChange={handleInput}
                      />
                      <span>$</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4 tabletSizeSpace">
                <p className="topBarTitle franchiseeDetailBoxTitle">
                  Super off-Peak Hour
                </p>
                <div className="d-flex align-items-end justify-content-between">
                  <div className="fullWidthBtn">
                    {/* <span className="timePicker">
                      <TimePicker disabled={enableSuperPeak} disableClock={true} format="h:m a" onChange={(value) => {
                        setSuperHour(value);
                        handleDropdown("super_peak_hours_min", value);
                      }}
                        value={superHour}
                      />
                    </span> */}
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={superHour ? superHour : "Select..."}
                      className={`bg-transparent slotDrop ${
                        formErrors.includes("super_peak_hours_min") && "error"
                      }`}
                      onSelect={(e) => {
                        setSuperHour(e);
                        handleDropdown("super_peak_hours_min", e);
                      }}
                      disabled={enableSuperPeak}
                    >
                      {slots.length > 0 ? (
                        slots.map((data, index) => (
                          <Dropdown.Item key={index} eventKey={data}>
                            {data}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item>select...</Dropdown.Item>
                      )}
                    </DropdownButton>
                  </div>
                  <span className="to">To</span>
                  <div className="fullWidthBtn">
                    {/* <span className="timePicker">
                      <TimePicker disabled={enableSuperPeak} disableClock={true} format="h:m a" onChange={(value) => {
                        setSuperHourTo(value);
                        handleDropdown("super_peak_hours_max", value);
                      }}
                        value={superHourTo}
                      />
                    </span> */}
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={superHourTo ? superHourTo : "Select..."}
                      className={`bg-transparent slotDrop ${
                        formErrors.includes("super_peak_hours_max") && "error"
                      }`}
                      onSelect={(e) => {
                        setSuperHourTo(e);
                        handleDropdown("super_peak_hours_max", e);
                      }}
                      disabled={enableSuperPeak}
                    >
                      {slots.length > 0 ? (
                        slots.map((data, index) => (
                          <Dropdown.Item key={index} eventKey={data}>
                            {data}
                          </Dropdown.Item>
                        ))
                      ) : (
                        <Dropdown.Item>select...</Dropdown.Item>
                      )}
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-lg-2 d-flex align-items-end">
                <div className="detailCheckmain">
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    name="no_super_peak_hours"
                    onChange={handleCheck}
                  />
                  <span className="checkboxText">
                    No Price Super off-Peak Hours
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="borderShadowBox userProfileBox my-3">
            <div className="row">
              <div className="col-xxl-10 col-lg-10">
                <div>
                  <p className="gymDetailBottom">Description</p>
                  <textarea
                    rows={8}
                    type="text"
                    className={`input franchiseDetailInput detailPriceSlot ${
                      formErrors.includes("description_1") && "error"
                    }`}
                    name={"description_1"}
                    onChange={handleInput}
                  />
                </div>
                {/* <div className="mt-4">
                  <p className="gymDetailBottom">Description 2</p>
                  <div className="gymDetailBottomDesc">
                    <input
                      type="text"
                      className="input franchiseDetailInput detailPriceSlot"
                      name={"description_2"}
                      onChange={handleInput}
                    />
                  </div>
                </div> */}
              </div>
              <div className="col-xxl-2 col-lg-2 qrSpacing">
                <div className="qrMain">
                  <div>
                    {qrImage ? (
                      <img src={qrImage} alt="QR" className="qrImg" />
                    ) : (
                      "Upload"
                    )}
                  </div>
                  <span
                    className={`btn uploadQrBtn mt-3 ${
                      formErrors.includes("qr_image") && "error"
                    }`}
                  >
                    <input
                      type="file"
                      name="qr_image"
                      multiple
                      className="qrPicInput"
                      onChange={(e) => {
                        handleUploadQR(e);
                      }}
                    />
                    Upload QR
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="slide-container  pt-3">
                  <p className="gymDetailBottom mb-4">Equipments</p>
                  {/* <AliceCarousel
                    mouseTracking
                    items={GymEquipment}
                    responsive={slideItems}
                  /> */}
                  <textarea
                    rows={5}
                    type="text"
                    className={`input franchiseDetailInput detailPriceSlot ${
                      formErrors.includes("available_equipments") && "error"
                    }`}
                    name={"available_equipments"}
                    onChange={handleInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GymCreate;
