import {
  GET_BOOKINGS_FAIL,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_REQUEST,
  GET_BOOKCOUNT_REQUEST,
  GET_BOOKCOUNT_SUCCESS,
  GET_BOOKCOUNT_FAIL,
} from "../action/actionConstant";

const intialState = {
  status: "",
  data: null,
  isProcess: false,
  message: null,
  count: null,
  chartDate: null,
};

const bookings = (state = intialState, action) => {
  switch (action.type) {
    case GET_BOOKINGS_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        data: action?.data,
        isProcess: false,
      };
    case GET_BOOKINGS_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case GET_BOOKCOUNT_REQUEST:
      return {
        ...state,
        count: null,
        chartDate: null,
        isProcess: true,
      };
    case GET_BOOKCOUNT_SUCCESS:
      return {
        ...state,
        count: action.data,
        chartDate: action.data,
      };
    case GET_BOOKCOUNT_FAIL:
      return {
        ...state,
        isProcess: false,
        count: action.data,
        chartDate: action.data,
      };
    default:
      return state;
  }
};
export default bookings;
