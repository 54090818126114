import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";

import TopHeader from "./TopHeader";
import { TailSpin } from "react-loader-spinner";
import { getReportList } from "../action/report";
import { getLoginUser } from "../comman/localStorage";

import "../Components/CSSfiles/ReportPage.css";

function Report(props) {
  //for pagination
  const dispatch = useDispatch();
  // const [gymId, setGymId] = useState("");
  const [gymName, setGymName] = useState("Select Gym");
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const isLoading = useSelector((state) => state.report.isProcess);
  const gymList = useSelector((state) =>
    state.gymDetail.gymList ? state.gymDetail.gymList : []
  );
  const reportList = useSelector((state) =>
    state.report.reportList ? state.report.reportList : []
  );

  const { token } = getLoginUser();
  useEffect(() => {
    dispatch(
      getReportList(
        {
          page: 1,
          limit: 100,
        },
        token
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleClear = () => {
  //   setGymName("select gym");
  //   setGymId("");
  //   setEndDate("");
  //   setStartDate("");
  //   dispatch(
  //     getReportList(
  //       {
  //         page: 1,
  //         limit: 10,
  //       },
  //       token
  //     )
  //   );
  // };

  // const handleSearch = () => {
  //   dispatch(
  //     getReportList(
  //       {
  //         page: 1,
  //         limit: 10,
  //         query: {
  //           gym_id: gymId,
  //           startDate: startDate,
  //           endDate: endDate,
  //         },
  //       },
  //       token
  //     )
  //   );
  // };.

  const handleGymfilter = (id) => {
    dispatch(
      getReportList(
        {
          page: 1,
          limit: 100,
          query: {
            gym_id: id,
          },
        },
        token
      )
    );
  };

  const header = [
    {
      name: "Photo",
      selector: (row) => row.images,
      cell: (selectors) => (
        <td>
          <img
            src={selectors.images !== null ? selectors.images[0] : ""}
            alt="img"
            className="gymPhoto"
          />
        </td>
      ),
    },
    { name: "Id", sortable: true, selector: (row) => row.id },
    { name: "Name", sortable: true, selector: (row) => row.name },
    { name: "Address", sortable: true, selector: (row) => row.address },
    {
      name: "Total Booking ",
      sortable: true,
      selector: (row) => row.total_bookings,
    },
    { name: "Total User", sortable: true, selector: (row) => row.total_users },
    {
      name: "Total Earning",
      sortable: true,
      selector: (row) => row.total_booking_amount,
      cell: (selectors) =>
        selectors.total_booking_amount
          ? "$" + selectors.total_booking_amount
          : "-",
    },
    {
      name: "Total Duration ",
      sortable: true,
      selector: (row) => row.total_duration,
      cell: (selectors) =>
        selectors.total_duration ? selectors.total_duration : "-",
    },
    // {
    //   name: "Export",
    //   prop: "",
    //   cell: (props) => (
    //     <div className="d-flex align-items-center">
    //       <button className="btn sortBtn reportExport">Export</button>
    //     </div>
    //   )
    // }
  ];
  return (
    <div className="mainboxes">
      <TopHeader title={"Report Listing"} />
      <div className="row align-items-center searchMain pb-3 mb-3">
        <div className="col-xxl-6 col-lg-6 d-flex align-items-center">
          <DropdownButton
            id="dropdown-basic-button"
            title={gymName}
            className="bg-transparent lastBooking mobileGymWidth"
            onSelect={(e) => {
              e = e.split(",");
              setGymName(e[1]);
              handleGymfilter(e[0]);
            }}
          >
            <Dropdown.Item eventKey={["", "All"]}>All</Dropdown.Item>
            {gymList?.map((item, index) => {
              return (
                <Dropdown.Item key={index} eventKey={[item.id, item.name]}>
                  {item.name}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
          {/* <div className="d-flex dateSection align-items-center ms-4">
            <div className="minWidth">Start Date : </div>
            <input
              type="date"
              className="input franchiseDetailInput startDate ms-2"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </div>
          <div className="d-flex dateSection align-items-center mx-3">
            <div className="minWidth">End Date : </div>
            <input
              type="date"
              value={endDate}
              className="input franchiseDetailInput startDate ms-2"
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div> */}
        </div>
        {/* <div className="col-xxl-6 col-lg-6 d-flex align-items-center  justify-content-between">
          <div className="d-flex align-items-center secondSelection">
            <button className="btn sortBtn reportExport " onClick={handleClear}>
              Clear
            </button>
            <button
              className="btn createGymBtn exportBtn searchBtn"
              onClick={handleSearch}
            >
              Search
            </button> */}
        {/* <DropdownButton
              id="dropdown-basic-button"
              title={gymType}
              className="bg-transparent lastBooking"
              onSelect={(e) => {
                setGymType(e);
              }}
            >
              <Dropdown.Item eventKey={"Slot"}>Slot</Dropdown.Item>
              <Dropdown.Item eventKey={"Duration"}>Duration</Dropdown.Item>
            </DropdownButton>

            <DropdownButton
              id="dropdown-basic-button"
              title="User Category"
              className="bg-transparent lastBooking"
            >
              <Dropdown.Item href="#/action-1">Gym 1</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Gym 2</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Gym 3</Dropdown.Item>
            </DropdownButton> */}
        {/* </div> */}
        {/* <button className="btn createGymBtn exportBtn">
            Exported Selected
          </button> */}
        {/* </div> */}
      </div>
      <div className="row">
        <div className="table-responsive">
          {isLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : (
            <DataTable columns={header} data={reportList} pagination />
          )}
        </div>
      </div>
    </div>
  );
}

export default Report;
