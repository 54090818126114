import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import conveience from "../../../Components/GymPics/Group-101.svg";
import privacy from "../../../Components/GymPics/Group-102.svg";
import demand from "../../../Components/GymPics/Group-103.svg";

import styles from "./index.module.scss";
function Offers() {
  const offer = [
    {
      image: conveience,
      title: "CONVENIENCE",
      points: [
        "Find Pods in Shopping Malls, under HDB blocks, and NParks around you.",
        "Book, pay, & access with The Gym Pod App!",
      ],
    },
    {
      image: privacy,
      title: "PRIVACY",
      points: [
        "Enjoy a solo workout session, or invite your friends along.",
        "Train with a personal trainer and avoid distractions.",
      ],
    },
    {
      image: demand,
      title: "ON-DEMAND",
      points: [
        "Pay for only what you need. No waste!",
        "No sign-up fees or cancellation fees. No questions asked.",
      ],
    },
  ];
  return (
    <div className={styles.offerMain}>
      <Container>
        <Row>
          {offer.map((item, index) => (
            <Col key={index} lg={4} md={12}>
              <div className={styles.contentMain}>
                <img src={item.image} alt={item.title} />
                <div>
                  <h1>{item.title}</h1>
                  {item.points.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Offers;
