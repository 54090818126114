import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
import { validationFields } from "../comman/Hooks";
import AddGroup from "../Components/svgImg/AddGroup.svg";
import RemoveGroup from "../Components/svgImg/Close.svg";
import { editGroupDetail } from "../action/groupDetails";
import { editGymUser, getGymUsers } from "../action/gymUsers";

import "../Components/CSSfiles/GroupDetails.css";

function GroupDetails() {
  const { state } = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState([]);
  const defaultValue = state;
  const groupId = defaultValue.id;
  const [groupDetail, setGroupDetail] = useState({
    advance_booking: defaultValue.advance_booking,
    book_amendment: defaultValue.book_amendment,
    cancellation_fee: defaultValue.cancellation_fee,
    discount: defaultValue.discount,
    max_slot: defaultValue.max_slot,
    name: defaultValue.name,
    period: defaultValue.period,
    price: defaultValue.price,
  });
  const [month, setMonth] = useState(
    defaultValue.period ? defaultValue.period : "Per Month..."
  );
  const isUserLoading = useSelector((state) => state.gymUsers.isProcess);
  const userList = useSelector((state) => state.gymUsers.data?.list);
  const [userData, setUserData] = useState(userList);

  useEffect(() => {
    dispatch(getGymUsers("", 1, 200));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newArr = [];
    if (userList) {
      userList.forEach((item) => {
        !item.group_id && newArr.push(item);
      });
      setUserData([...newArr]);
    }
  }, [userList]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setGroupDetail((groupDetail) => ({
        ...groupDetail,
        [name]: value,
      }));
    } else {
      setGroupDetail((groupDetail) => ({
        ...groupDetail,
        [name]: parseInt(value),
      }));
    }
  };

  const handleDropdown = (name, e) => {
    setGroupDetail({
      ...groupDetail,
      [name]: parseInt(e),
    });
  };

  const handleSubmit = () => {
    const validFieldArray = validationFields(groupDetail);
    if (validFieldArray?.length === 0) {
      const successCallBack = () => {
        setFormErrors([]);
        history("/group-listing");
      };
      dispatch(editGroupDetail(defaultValue.id, groupDetail, successCallBack));
    } else {
      setFormErrors([...validFieldArray]);
      toast.error("Fields are required");
    }
  };

  const handleSearch = (data) => {
    dispatch(getGymUsers(data, 1, 200));
  };

  const debounce_fun = _.debounce(function (response) {
    handleSearch(response);
  }, 500);

  const handleAddMember = (id) => {
    let obj = {
      group_id: groupId,
      group_join_date: moment(new Date()).format("YYYY-MM-DD  HH:mm:ss"),
    };

    const succesCallBack = () => {
      dispatch(getGymUsers("", 1, 200));
      toast.success("Added...");
    };
    dispatch(editGymUser(id, obj, succesCallBack));
  };

  const handleRemoveMember = (id) => {
    let obj = {
      group_id: null,
      group_join_date: null,
    };

    const succesCallBack = () => {
      dispatch(getGymUsers("", 1, 200));
      toast.success("Removed...");
    };
    dispatch(editGymUser(id, obj, succesCallBack));
  };

  const header = [
    {
      name: "Photo",
      selector: (row) => row.image,
      cell: (selectors) => (
        <img src={selectors.image} alt={"user profile"} className="userImage" />
      ),
    },
    { name: "ID", selector: (row) => row.id, sortable: true },
    {
      name: "Last Booking Date",
      selector: (row) => moment(row.last_booking_date).format("DD/MM/YYYY"),
      sortable: true,
    },
    { name: "User Name", selector: (row) => row.username, sortable: true },
    { name: "First Name", selector: (row) => row.first_name, sortable: true },
    { name: "Gender", selector: (row) => row.gender, sortable: true },
    {
      name: "Group Status",
      selector: (row) => row.group_id_name,
      cell: (selectors) =>
        selectors.group_id_name ? selectors.group_id_name : "-",
      sortable: true,
    },
    {
      name: "Number Of Booking",
      selector: (row) => row.number_of_bookings,
      sortable: true,
    },
    {
      name: "Total Spent",
      selector: (row) => row.total_spent,
      cell: (selectors) => (selectors.total_spent ? selectors.total_spent : 0),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (selectors) => {
        if (!selectors.group_id && selectors.group_id !== groupId) {
          return (
            <div className="d-flex align-items-center">
              <button
                className="btn addGroupBtn"
                onClick={() => handleAddMember(selectors.id)}
              >
                <img src={AddGroup} alt="edit" />
              </button>
            </div>
          );
        } else if (selectors.group_id && selectors.group_id === groupId) {
          return (
            <div className="d-flex align-items-center">
              <button
                className="btn addGroupBtn"
                onClick={() => handleRemoveMember(selectors.id)}
              >
                <img src={RemoveGroup} alt="edit" />
              </button>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];

  return (
    <div className="mainboxes">
      <TopHeader title={"Edit Group Detail"} />
      <div className="row align-items-center searchMain pb-2 mb-3">
        <div className="col-xxl-9 col-lg-9 flex-wrap d-flex align-items-center justify-content-between">
          <div className=" groupFieldWidth">
            <p className="topBarTitle">Group Name</p>
            <p className="topDetail topGroupDetail">{defaultValue.name}</p>
          </div>
          <div className=" groupFieldWidth">
            <p className="topBarTitle">Price</p>
            <p className="topDetail topGroupDetail">{defaultValue.price}</p>
          </div>
          <div className=" groupFieldWidth">
            <p className="topBarTitle">Discount %</p>
            <p className="topDetail topGroupDetail">{defaultValue.discount}</p>
          </div>
          <div className=" groupFieldWidth laptopSpace">
            <p className="topBarTitle">Advance Booking #</p>
            <p className="topDetail topGroupDetail">
              {defaultValue.advance_booking} days
            </p>
          </div>
          <div className=" groupFieldWidth laptopSpace">
            <p className="topBarTitle">Cencellation Fee</p>
            <p className="topDetail topGroupDetail">
              {defaultValue.cancellation_fee}
            </p>
          </div>
          <div className=" groupFieldWidth laptopSpace">
            <p className="topBarTitle">Max Current Slot</p>
            <p className="topDetail topGroupDetail">{defaultValue.max_slot}</p>
          </div>
          <div className=" groupFieldWidth laptopSpace">
            <p className="topBarTitle">Booking amendment #</p>
            <p className="topDetail topGroupDetail">
              {defaultValue.book_amendment} Hour
            </p>
          </div>
        </div>
        <div className="col-xxl-3 col-lg-3 col-sm-12  d-flex align-items-center justify-content-end">
          <button
            className="btn topCancel mx-2"
            onClick={() => {
              history("/group-listing");
            }}
          >
            Cancel
          </button>
          <button
            className="btn saveBtn groupUpdateBtn"
            onClick={() => handleSubmit()}
          >
            Save
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-12 col-lg-12">
          <div className="borderShadowBox franchiseeDetailBox my-3">
            <div className="row">
              <div className="col-xxl-2 col-lg-2 tabletGroupNameSize">
                <p className="topBarTitle">Group Name</p>
                <input
                  type="text"
                  className={`input franchiseDetailInput ${
                    formErrors.includes("name") && "error"
                  }`}
                  name="name"
                  defaultValue={defaultValue.name}
                  onChange={handleInput}
                />
              </div>
              <div className="col-xxl-10 col-lg-10 groupInputDiv flex-wrap">
                <div className="widthFour px-2">
                  <p className="topBarTitle">Price</p>
                  <div className="priceInput">
                    <input
                      type="number"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("price") && "error"
                      }`}
                      name="price"
                      pattern="[0-9]*"
                      defaultValue={defaultValue.price}
                      onChange={handleInput}
                    />
                    <span>$</span>
                  </div>
                </div>
                <div className="px-2 widthFour">
                  <p className="topBarTitle">Period</p>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={month === 1 ? "1 Month" : "1 Year"}
                    defaultValue={defaultValue.period}
                    className={`bg-transparent notificationDrop ${
                      formErrors.includes("period") && "error"
                    }`}
                    onSelect={(e) => {
                      e = e.split(",");
                      setMonth(e[1]);
                      handleDropdown("period", e[0]);
                    }}
                  >
                    <Dropdown.Item eventKey={[1, "1 Month"]}>
                      1 Month
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={[2, "1 Year"]}>
                      1 Year
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
                <div className="px-2 widthFour">
                  <p className="topBarTitle">Max Current Slot</p>
                  <input
                    type="number"
                    className={`input franchiseDetailInput ${
                      formErrors.includes("max_slot") && "error"
                    }`}
                    name="max_slot"
                    pattern="[0-9]*"
                    defaultValue={defaultValue.max_slot}
                    onChange={handleInput}
                  />
                </div>
                <div className="px-2 mb-2 widthFour">
                  <p className="topBarTitle">Discount %</p>
                  <div className="priceInput">
                    <input
                      type="number"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("discount") && "error"
                      }`}
                      name="discount"
                      pattern="[0-9]*"
                      defaultValue={defaultValue.discount}
                      onChange={handleInput}
                    />
                    <span>%</span>
                  </div>
                </div>
                <div className="px-2 widthFour">
                  <p className="topBarTitle">Advance Booking #</p>
                  <input
                    type="number"
                    className={`input franchiseDetailInput ${
                      formErrors.includes("advance_booking") && "error"
                    }`}
                    name="advance_booking"
                    pattern="[0-9]*"
                    defaultValue={defaultValue.advance_booking}
                    onChange={handleInput}
                  />
                </div>
                <div className="px-2 widthTwo">
                  <p className="topBarTitle">Cancellation Fee</p>

                  <div className="priceInput">
                    <input
                      type="number"
                      className={`input franchiseDetailInput ${
                        formErrors.includes("cancellation_fee") && "error"
                      }`}
                      name="cancellation_fee"
                      pattern="[0-9]*"
                      defaultValue={defaultValue.cancellation_fee}
                      onChange={handleInput}
                    />
                    <span>%</span>
                  </div>
                </div>
                <div className="px-2 widthTwo">
                  <p className="topBarTitle">Booking amendment #</p>
                  <input
                    type="number"
                    className={`input franchiseDetailInput ${
                      formErrors.includes("book_amendment") && "error"
                    }`}
                    name="book_amendment"
                    pattern="[0-9]*"
                    defaultValue={defaultValue.book_amendment}
                    onChange={handleInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-xxl-12 col-lg-12 d-flex align-items-center">
          <h3
            className="m-0 detailHeading 
                  "
          >
            User Included The Group
          </h3>
          <div className="mx-3">
            <SearchBar placeholder={"Search User"} search={debounce_fun} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          {isUserLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : userData?.length > 0 ? (
            <DataTable
              columns={header}
              data={userData ? userData : []}
              pagination
            />
          ) : (
            <div className="d-flex justify-content-center">
              All users have been added to groups.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GroupDetails;
