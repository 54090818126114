import { toast } from "react-toastify";

import {
  GET_CHAT_FAIL,
  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  CREATE_CHAT_FAIL,
  CREATE_CHAT_REQUEST,
  CREATE_CHAT_SUCCESS,
  GET_MSG_FAIL,
  GET_MSG_REQUEST,
  GET_MSG_SUCCESS,
  UPDATE_CHAT_STATUS_REQUEST,
  UPDATE_CHAT_STATUS_SUCCESS,
  UPDATE_CHAT_STATUS_FAIL,
} from "./actionConstant";
import { getLoginUser } from "../comman/localStorage";
import { functionalityApiCall } from "../comman/connect";
import {
  CHANGE_CHAT_STATUS,
  CREATE_CHAT_URL,
  GET_CHAT_URL,
  GET_MSG_URL,
  READ_ALL_MSG,
  SEND_MSG_URL,
  UNREAD_MSG,
} from "../comman/urls";

const createChatRequested = () => ({
  type: CREATE_CHAT_REQUEST,
});

export const createChatSuccess = (data) => ({
  type: CREATE_CHAT_SUCCESS,
  payload: data,
});

const createChatFailure = (data) => ({
  type: CREATE_CHAT_FAIL,
  payload: data,
});

const getChatRequested = () => ({
  type: GET_CHAT_REQUEST,
});

const getChatSuccess = (data) => ({
  type: GET_CHAT_SUCCESS,
  payload: data,
});

const getChatFailure = () => ({
  type: GET_CHAT_FAIL,
});

const getMsgRequested = () => ({
  type: GET_MSG_REQUEST,
});

export const getMsgSuccess = (data) => ({
  type: GET_MSG_SUCCESS,
  payload: data,
});

const getMsgFailure = () => ({
  type: GET_MSG_FAIL,
});

const chatStatusRequested = () => ({
  type: UPDATE_CHAT_STATUS_REQUEST,
});

export const chatStatusSuccess = (data) => ({
  type: UPDATE_CHAT_STATUS_SUCCESS,
  payload: data,
});

const chatStatusFailure = () => ({
  type: UPDATE_CHAT_STATUS_FAIL,
});

export const getChatUserList =
  (search, page, limit, filter, filterColumn) => (dispatch) => {
    dispatch(getChatRequested());
    const { token } = getLoginUser();
    if (token) {
      const data = {
        page: page,
        limit: 1000,
        sort: `${filterColumn ? filterColumn : "id"} ${
          filter ? filter : "desc"
        }`,
        populate: [
          // {
          //   key: "staff_id",
          //   tableName: "user",
          //   projection: ["first_name", "last_name", "image"],
          // },
          {
            key: "customer_id",
            tableName: "user",
            projection: ["first_name", "last_name", "image"],
          },
        ],
      };
      const url = GET_CHAT_URL;
      const onSuccess = (response) => {
        dispatch(getChatSuccess(response.data));
      };

      const onFailure = (response) => {
        dispatch(getChatFailure());
        toast.error(response.message);
      };

      functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
    }
  };

export const searchChatUser = (search, arr) => (dispatch) => {
  if (search && search !== "" && search.length !== 0) {
    let searchData = arr?.filter((x) =>
      String(x.customer_id_first_name.toLowerCase()).includes(
        search.toLowerCase()
      )
    );
    dispatch(getChatSuccess(searchData));
  } else {
    dispatch(getChatUserList("", 1, 1000));
    dispatch(getChatSuccess(arr));
  }
};

export const readAllMsg = (conversationId, successCallBack) => (dispatch) => {
  const { token } = getLoginUser();
  const requestData = {
    conversation_id: conversationId,
  };
  if (token) {
    const onSuccess = (response) => {
      toast.success(response.message);
      successCallBack();
    };
    const url = READ_ALL_MSG;

    const onFailure = (response) => {
      toast.error("something unwanted");
    };

    functionalityApiCall("POST", url, requestData, onSuccess, onFailure, token);
  }
};
export const markAsUnread = (conversationId, successCallBack) => (dispatch) => {
  const { token } = getLoginUser();
  const requestData = {
    conversation_id: conversationId,
  };
  if (token) {
    const onSuccess = (response) => {
      toast.success(response.message);
      successCallBack();
    };
    const url = UNREAD_MSG;

    const onFailure = (response) => {
      toast.error("something unwanted");
    };

    functionalityApiCall("POST", url, requestData, onSuccess, onFailure, token);
  }
};

export const createChat = (oppositer_id, gymId) => (dispatch) => {
  dispatch(createChatRequested());
  const { token, user } = getLoginUser();
  const requestData = {
    staff_id: user.id,
    customer_id: oppositer_id,
    gym_id: gymId,
  };
  if (token) {
    const onSuccess = async (response) => {
      await dispatch(getChatUserList("", 1));
      dispatch(createChatSuccess(response.message));
      toast.success(response.message);
    };
    const url = CREATE_CHAT_URL;

    const onFailure = async (response) => {
      if (response.status === 400) {
        await dispatch(getChatUserList("", 1));
        dispatch(createChatFailure(response.message));
        toast.success(response.message);
      } else {
        toast.error("something unwanted");
      }
    };

    functionalityApiCall("POST", url, requestData, onSuccess, onFailure, token);
  }
};

export const sendMessage = (chat_id, message) => (dispatch) => {
  const { token } = getLoginUser();
  const requestData = {
    conversation_id: chat_id,
    message: message,
  };
  if (token) {
    const onSuccess = (response) => {};
    const url = SEND_MSG_URL;

    const onFailure = (response) => {
      if (response.status !== 400) {
        toast.error("Message not send");
      }
    };

    functionalityApiCall("POST", url, requestData, onSuccess, onFailure, token);
  }
};
export const changeChatStatus = (chat_id, successCallBack) => (dispatch) => {
  dispatch(chatStatusRequested());
  const { token } = getLoginUser();
  const requestData = {
    conversation_id: chat_id,
    status: false,
  };
  if (token) {
    const onSuccess = (response) => {
      successCallBack();
    };
    dispatch(chatStatusSuccess());
    const url = CHANGE_CHAT_STATUS;

    const onFailure = (response) => {
      dispatch(chatStatusFailure());
      if (response.status !== 400) {
        toast.error("Message not send");
      }
    };

    functionalityApiCall("POST", url, requestData, onSuccess, onFailure, token);
  }
};

export const getMessageList = (id) => (dispatch) => {
  dispatch(getMsgRequested());
  const { token } = getLoginUser();
  if (token) {
    const data = {
      page: 1,
      limit: 10,
      sort: "created_at desc",
      query: {
        conversation_id: id,
      },
      populate: [
        {
          key: "created_by",
          tableName: "user",
          projection: ["first_name", "last_name", "image"],
        },
      ],
    };
    const url = GET_MSG_URL;
    const onSuccess = (response) => {
      dispatch(getMsgSuccess(response.data));
    };

    const onFailure = (response) => {
      dispatch(getMsgFailure());
      toast.error(response.message);
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};
