import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";

import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
import Edit from "../Components/svgImg/Edit.svg";
import { TailSpin } from "react-loader-spinner";
import Delete from "../Components/svgImg/Delete.svg";
import { deleteFranchise, getFranchise } from "../action/franchise";

function Franchise() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState("Oldest");
  const [filedColumn, setFieldColumn] = useState("");
  const [filedFilter, setFieldFilter] = useState("All");
  const isLoading = useSelector((state) => state.franchise.isProcess);
  const franchiseList = useSelector((state) =>
    state.franchise.data?.list ? state.franchise?.data?.list : []
  );

  useEffect(() => {
    dispatch(getFranchise("", 1, 100));
  }, [dispatch]);

  const handleDelete = () => {
    dispatch(deleteFranchise(deleteId));
    setModalShow(!modalShow);
    dispatch(getFranchise("", 1, 100));
  };

  const handleFilter = (value) => {
    dispatch(getFranchise("", 1, 100, value, filedColumn));
  };

  const handleSearch = (data) => {
    dispatch(getFranchise(data, 1, 200));
  };

  const debounce_fun = _.debounce(function (response) {
    handleSearch(response);
  }, 500);

  const handleColumnFilter = (value) => {
    dispatch(getFranchise("", 1, 100, sort, value));
  };

  const header = [
    { name: "Franchise ID", selector: (row) => row.unique_id, sortable: true },
    {
      name: "Franchise Name",
      selector: (row) => row.company_name,
      sortable: true,
    },
    { name: "First Name", selector: (row) => row.first_name, sortable: true },
    { name: "Last Name", selector: (row) => row.last_name, sortable: true },
    { name: "Email", selector: (row) => row.email, sortable: true },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      cell: (selectors) => (
        <td className="text-capitalize">{selectors.gender}</td>
      ),
    },
    { name: "Mobile", selector: (row) => row.mobile, sortable: true },
    {
      name: "Start Date",
      selector: (row) => row.created_at,
      sortable: true,
      cell: (selectors) => {
        return moment(selectors.created_at).format("DD/MM/YYYY");
      },
    },
    { name: "Dob", selector: (row) => row.dob, sortable: true },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      cell: (selectors) => (
        <div className="d-flex align-items-center">
          <button
            className="btn actionBtn"
            onClick={() => {
              history(`/franchise/edit/${selectors.id}`, { state: selectors });
            }}
          >
            <img src={Edit} alt="edit" />
          </button>
          <button
            className="btn actionBtn"
            onClick={() => {
              setModalShow(!modalShow);
              setDeleteId(selectors.id);
            }}
          >
            <img src={Delete} alt="delete" />
          </button>
        </div>
      ),
    },
  ];
  return (
    <div className="mainboxes">
      <TopHeader title={"Franchise"} />
      <div className="row align-items-center searchMain pb-3 mb-3">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Franchise
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete?</h4>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setModalShow(!modalShow);
              }}
            >
              Close
            </button>
            <button
              className="btn enableBtnActive"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <div className="col-xxl-6 col-lg-6 col-md-4 col-sm-12">
          <SearchBar placeholder={"Search Franchise"} search={debounce_fun} />
        </div>
        <div className="col-xxl-4 col-lg-4 col-md-5 col-sm-12 d-flex align-items-center mobileMostCenter mobileTopSpacing">
          <DropdownButton
            id="dropdown-basic-button"
            title={filedFilter}
            className="bg-transparent listDropDown"
            onSelect={(e) => {
              e = e.split(",");
              setFieldFilter(e[1]);
              setFieldColumn(e[0]);
              handleColumnFilter(e[0]);
            }}
          >
            <Dropdown.Item eventKey={["id", "All"]}>All</Dropdown.Item>
            <Dropdown.Item eventKey={["created_at", "Start Date"]}>
              Start Date
            </Dropdown.Item>
            <Dropdown.Item eventKey={["unique_id", "Franchise ID"]}>
              Franchise ID
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton
            id="dropdown-basic-button"
            title={filter}
            className="bg-transparent listDropDown mobileDrop"
            onSelect={(e) => {
              setFilter(e === "desc" ? "Most Recent" : "Oldest");
              setSort(e);
              handleFilter(e);
            }}
          >
            <Dropdown.Item eventKey={"desc"}>Most Recent</Dropdown.Item>
            <Dropdown.Item eventKey={"asc"}>Oldest</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="col-xxl-2 col-lg-2 col-md-3 col-sm-12 mobileFranchiseCreate mobileTopSpacing">
          <Link to="/franchise-create">
            <button className="btn createGymBtn mobileFilterBtn">
              Create Franchise
            </button>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          {isLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : (
            <DataTable columns={header} data={franchiseList} pagination />
          )}
        </div>
      </div>
    </div>
  );
}

export default Franchise;
