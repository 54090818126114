import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import TopHeader from "./TopHeader";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import "../Components/CSSfiles/Notifications.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function NotificationNew(props) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => { }, [editorState]);

  return (
    <div className="mainboxes">
      <TopHeader title={"Create New Notification"} />
      <div className="row align-items-center justify-content-end searchMain pb-2 mb-3">
        <div className="col-xxl-3 col-lg-3 d-flex align-items-center justify-content-end">
          <button className="btn topCancel mx-2">Cancel</button>
          <button className="btn saveBtn">Save</button>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-12 col-lg-12">
          <div className="borderShadowBox franchiseeDetailBox my-3">
            <div className="row ">
              <div className="col-xxl-2 col-lg-2">
                <p className="topBarTitle franchiseeDetailBoxTitle">
                  Notification Name
                </p>
                <input
                  type="text"
                  className="input franchiseDetailInput"
                  value={"User Notification 1"}
                />
              </div>
              <div className="col-xxl-4 col-lg-4">
                <p className="topBarTitle franchiseeDetailBoxTitle">
                  Notification Description
                </p>
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Select Gym"
                  className="bg-transparent notificationDrop"
                >
                  <Dropdown.Item href="#/action-1">Gym 1</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Gym 2</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Gym 3</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
            <div className="row mt-5">
              <div className="comment">
                <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationNew;
