import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//svg files
import MenuSvg from "../Components/svgImg/Menu.svg";
import Menubar from "../Components/svgImg/MenuBar.svg";
import { getUnreadFeedbackCount } from "../action/feedback";
import { getPendingVaccination } from "../action/vaccination";
import { ReactComponent as GymSvg } from "../Components/svgImg/Gym.svg";
import { ReactComponent as CCTVSvg } from "../Components/svgImg/CCTV.svg";
import { ReactComponent as ReportSvg } from "../Components/svgImg/Report.svg";
import { ReactComponent as FinanceSvg } from "../Components/svgImg/Finance.svg";
import { ReactComponent as UserSvg } from "../Components/svgImg/UserListing.svg";
import { ReactComponent as GroupSvg } from "../Components/svgImg/GroupListing.svg";
import { ReactComponent as FeedbackSvg } from "../Components/svgImg/Feedback.svg";
import { ReactComponent as DashBoardSvg } from "../Components/svgImg/DashBoard.svg";
import { ReactComponent as FranchiseSvg } from "../Components/svgImg/Franchise.svg";
import { ReactComponent as BookingSvg } from "../Components/svgImg/BookingListing.svg";
import { ReactComponent as NotificationSvg } from "../Components/svgImg/Notifications.svg";

import "../../node_modules/react-pro-sidebar/dist/css/styles.css";

function MenuButton() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const screenWidth = window.innerWidth;
  const [toggled, settoggled] = useState(false);
  const [activeIndex, setactiveIndex] = useState();
  const [, setVaccinationList] = useState(0);
  const successCallBack = (response) => {
    setVaccinationList(response?.count);
  };
  const feedBackCount = useSelector((state) => state.feedback?.unreadCount);

  useEffect(() => {
    dispatch(getPendingVaccination(successCallBack));
    dispatch(getUnreadFeedbackCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleSidebar = () => {
    settoggled(!toggled);
  };

  // const notificationList = useSelector((state) =>
  //   state.notification.notificationList?.count
  //     ? state.notification?.notificationList?.count
  //     : 0
  // );

  const handleRouting = (route) => {
    history(route);
  };

  return (
    <>
      <button
        className="btn mobileSidebarBtn"
        onClick={() => handleToggleSidebar()}
      >
        <img src={Menubar} className="menuMobile" alt="Close" />
      </button>
      {toggled && (
        <div
          className="customOverly"
          onClick={() => handleToggleSidebar()}
        ></div>
      )}
      <div className="menuButton">
        <ProSidebar
          collapsed={screenWidth > 425 ? !toggled : toggled}
          toggled={screenWidth > 425 ? !toggled : toggled}
          handletogglesidebar={() => handleToggleSidebar()}
          breakPoint="sm"
        >
          <SidebarHeader>
            <div className="mb-2">
              <button
                className="button"
                onClick={() => {
                  handleToggleSidebar();
                }}
              >
                <img src={MenuSvg} alt="icon" />
              </button>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu>
              <MenuItem active={activeIndex === 1 && true}>
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu dashMenuBtn"
                      onClick={() => {
                        setactiveIndex(1);
                        history("/dashboard");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className="dashMenuIcons">
                          <DashBoardSvg />
                        </span>
                        <span className="menuTitle">Dashboard</span>
                      </div>
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem active={activeIndex === 2 && true}>
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu dashMenuBtn"
                      onClick={() => {
                        setactiveIndex(2);
                        handleRouting("/gym-listing");
                        history("/gym-listing");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className="dashMenuIcons">
                          <GymSvg />
                        </span>
                        <span className="menuTitle">GYMS</span>
                      </div>
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem active={activeIndex === 3 && true}>
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu"
                      onClick={() => {
                        setactiveIndex(3);
                        history("/user-listing");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className="dashMenuIcons">
                          <UserSvg />
                        </span>
                        <span className="menuTitle">Users</span>
                      </div>
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem active={activeIndex === 4 && true}>
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu"
                      onClick={() => {
                        setactiveIndex(4);
                        history("/group-listing");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className="dashMenuIcons">
                          <GroupSvg />
                        </span>
                        <span className="menuTitle">Groups</span>
                      </div>
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem active={activeIndex === 5 && true}>
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu"
                      onClick={() => {
                        setactiveIndex(5);
                        history("/bookings-listing");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className={activeIndex === 5 && "dashMenuIcons"}>
                          <BookingSvg />
                        </span>
                        <span className="menuTitle">Bookings</span>
                      </div>
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem active={activeIndex === 6 && true}>
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu"
                      onClick={() => {
                        setactiveIndex(6);
                        history("/report");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className={activeIndex === 6 && "dashMenuIcons"}>
                          <ReportSvg />
                        </span>
                        <span className="menuTitle">Report</span>
                      </div>
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem active={activeIndex === 7 && true}>
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu"
                      onClick={() => {
                        setactiveIndex(7);
                        history("/finance");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className={activeIndex === 7 && "dashMenuIcons"}>
                          <FinanceSvg />
                        </span>
                        <span className="menuTitle">Finance</span>
                      </div>
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem active={activeIndex === 8 && true}>
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu"
                      onClick={() => {
                        setactiveIndex(8);
                        history("/CCTV");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className={activeIndex === 8 && "dashMenuIcons"}>
                          <CCTVSvg />
                        </span>
                        <span className="menuTitle">CCTV</span>
                      </div>
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem
                active={activeIndex === 9 && true}
                className="position-relative"
              >
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu"
                      onClick={() => {
                        setactiveIndex(9);
                        history("/feedback");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className={activeIndex === 9 && "dashMenuIcons"}>
                          <FeedbackSvg />
                        </span>
                        <span className="menuTitle">Feedback</span>
                      </div>
                    </button>
                  </div>
                </div>

                <span
                  className={`position-absolute ranslate-middle badge rounded-pill notificationBadge bg-danger ${
                    toggled ? "notificationBadgeOpen" : "notificationBadge"
                  }`}
                >
                  {feedBackCount ? feedBackCount : 0}
                </span>
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem active={activeIndex === 10 && true}>
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu"
                      onClick={() => {
                        setactiveIndex(10);
                        history("/notifications-listing");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className={activeIndex === 10 && "dashMenuIcons"}>
                          <NotificationSvg />
                        </span>
                        <span className="menuTitle">Notification</span>
                      </div>
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            <Menu>
              <MenuItem active={activeIndex === 12 && true}>
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu"
                      onClick={() => {
                        setactiveIndex(12);
                        history("/franchise");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className={activeIndex === 12 && "dashMenuIcons"}>
                          <FranchiseSvg />
                        </span>
                        <span className="menuTitle">Franchise</span>
                      </div>
                    </button>
                  </div>
                </div>
              </MenuItem>
            </Menu>
            {/* <Menu>
              <MenuItem
                active={activeIndex === 13 && true}
                className="position-relative"
              >
                <div className={`icons ${toggled && "iconsActive"}`}>
                  <div className="dashBoardSvg">
                    <button
                      className="buttonMenu"
                      onClick={() => {
                        setactiveIndex(13);
                        history("/vaccination");
                        settoggled(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <span className={activeIndex === 13 && "dashMenuIcons"}>
                          <VaccineSvg />
                        </span>
                        <span className="menuTitle">Verify Vax</span>
                      </div>
                    </button>
                  </div>
                </div>
                <span
                  className={`position-absolute ranslate-middle badge rounded-pill notificationBadge bg-danger ${
                    toggled ? "vaccinationBadgeOpen" : "vaccinationBadge"
                  }`}
                >
                  {vaccinationList}
                  <span className="visually-hidden">unread messages</span>
                </span>
              </MenuItem>
            </Menu> */}
          </SidebarContent>
        </ProSidebar>
      </div>
    </>
  );
}

export default MenuButton;
