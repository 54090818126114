import { toast } from "react-toastify";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { resetPassword } from "../../action/user";
import { useDispatch, useSelector } from "react-redux";

import Logo from "../../Components/Logo/X-pod-01.png";
import Image from "../../Components/GymPics/loginImage.jpg";

//css files
import "../../Components/CSSfiles/LoginForgotPasswordRegistration.css";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const history = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();

  const isLoading = useSelector((state) => state.login.isProcess);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== "") {
      if (password === conPassword) {
        try {
          const successCallBack = (response) => {
            history("/login");
          };
          dispatch(resetPassword(token, password, successCallBack));
        } catch (error) {
          console.log("inDesign", error);
        }
      } else {
        toast.error("New and Confirm password are not matched!");
      }
    } else {
      toast.error("password is empty!");
    }
  };

  return (
    <div className="container">
      <div className="row authMain">
        <div className="col-xxl-8 col-lg-7 p-0">
          <div className="authImage">
            <img src={Image} alt="" />
          </div>
        </div>

        <div className="col-xxl-4 col-lg-5 p-0">
          <div className="authRightSide forgetRight">
            <div className="Logo">
              <img src={Logo} height={150} width={150} alt="" />
            </div>
            <div className="formLogin">
              <Form className="my-4">
                <Form.Group size="lg">
                  <input
                    type="password"
                    className="input"
                    id="Uname"
                    placeholder="New password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  ></input>
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                  <input
                    type="Password"
                    className="input mt-2"
                    id="Pass"
                    placeholder="Confirm password"
                    onChange={(e) => {
                      setConPassword(e.target.value);
                    }}
                  ></input>
                </Form.Group>
                <Button
                  block
                  size="lg"
                  type="submit"
                  className="authButton w-100"
                  onClick={handleSubmit}
                >
                  {!isLoading ? (
                    "Reset"
                  ) : (
                    <TailSpin heigth="25" width="45" color="white" />
                  )}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
