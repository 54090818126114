import play from "../../../Components/GymPics/CTA-1.png";
import app from "../../../Components/GymPics/CTA.png";

import styles from "./index.module.scss";

function Download() {
  return (
    <div className={`container ${styles.downloadMain}`}>
      <h2>Download The XPod App today! </h2>
      <div>
        <a
          href="https://play.google.com/store/apps/details?id=com.gym.xpod"
          target="_blank"
          rel="noreferrer"
        >
          <img src={play} alt="play" />
        </a>
        <img src={app} alt="app" />
      </div>
    </div>
  );
}

export default Download;
