import {
  ADD_FRANCHISE_FAIL,
  GET_FRANCHISE_FAIL,
  EDIT_FRANCHISE_FAIL,
  ADD_FRANCHISE_REQUEST,
  GET_FRANCHISE_REQUEST,
  DELETE_FRANCHISE_FAIL,
  GET_FRANCHISE_SUCCESS,
  ADD_FRANCHISE_SUCCESS,
  EDIT_FRANCHISE_REQUEST,
  EDIT_FRANCHISE_SUCCESS,
  DELETE_FRANCHISE_REQUEST,
  DELETE_FRANCHISE_SUCCESS,
} from "../action/actionConstant";

const intialState = {
  status: "",
  data: null,
  isProcess: false,
  message: null,
};

const franchise = (state = intialState, action) => {
  const { data } = action;
  switch (action.type) {
    case GET_FRANCHISE_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case GET_FRANCHISE_SUCCESS:
      return {
        ...state,
        data: data.data,
        isProcess: false,
      };
    case GET_FRANCHISE_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case ADD_FRANCHISE_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case ADD_FRANCHISE_SUCCESS:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case ADD_FRANCHISE_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
        message: data.message,
      };
    case EDIT_FRANCHISE_REQUEST:
      return {
        ...state,
        data: null,
        isProcess: true,
      };
    case EDIT_FRANCHISE_SUCCESS:
      return {
        ...state,
        data: null,
        isProcess: false,
      };
    case EDIT_FRANCHISE_FAIL:
      return {
        ...state,
        data: null,
        isProcess: false,
        message: data.message,
      };
    case DELETE_FRANCHISE_REQUEST:
      return {
        ...state,
        isProcess: true,
      };
    case DELETE_FRANCHISE_SUCCESS:
      return {
        ...state,
        isProcess: false,
      };
    case DELETE_FRANCHISE_FAIL:
      return {
        ...state,
        isProcess: false,
        message: data.message,
      };
    default:
      return state;
  }
};
export default franchise;
