import _ from "lodash";
import moment from "moment";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";

import {
  changeChatStatus,
  getChatUserList,
  getMessageList,
  getMsgSuccess,
  markAsUnread,
  readAllMsg,
  searchChatUser,
  sendMessage,
} from "../action/chat";
import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
import { serverUrl } from "../comman/urls";
import { TailSpin } from "react-loader-spinner";
import Pic1 from "../Components/GymPics/user.png";
import ImageIcon from "../Components/svgImg/Image.svg";
import { imageUploadApiCall } from "../comman/connect";
import AttachIcon from "../Components/svgImg/Attach.svg";
import { getUnreadFeedbackCount } from "../action/feedback";

import "../Components/CSSfiles/Feedback.css";

function FeedbackChat(props) {
  const socket = useRef();
  const history = useNavigate();
  const dispatch = useDispatch();
  const messageRef = useRef(null);
  const [message, setMessage] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [showSelected, setShowSelected] = useState(false);
  const [defaultImages, setDefaultImages] = useState([]);
  const [conversationId, setConversationId] = useState(
    props?.location?.state?.conversationDetails
      ? props?.location?.state?.conversationDetails?.id
      : ""
  );
  const [currrentChatUser, setCurrrentChatUser] = useState(
    props?.location?.state?.conversationDetails
      ? props?.location?.state?.conversationDetails
      : ""
  );

  const conversationList = useSelector((state) =>
    state.chat?.conversationList ? state.chat?.conversationList : []
  );

  const Messages = useSelector((state) => state.chat?.messageList);
  const MessagesLoading = useSelector((state) => state.chat?.isProcess);
  const [searchText, setSearchText] = useState("");
  const [MessageList, setMessageList] = useState(Messages);
  const ChatUsersLoading = useSelector((state) => state.chat?.isProcess);
  const user = useSelector((state) => state.login?.userDetails);
  socket.current = io(`${serverUrl}/chat?userId=${user?.id}`);

  useEffect(() => {
    dispatch(getChatUserList("", 1, 1000));

    if (conversationId) dispatch(getMessageList(conversationId));
    socket.current.connect();
    return () => {
      socket.current.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  socket.current.on("newMessage", (data) => {
    setMessageList([...MessageList, data.message]);
    executeScroll(messageRef);
  });

  const debounce_fun = _.debounce(function (response) {
    dispatch(searchChatUser(response, conversationList));
  }, 500);

  const handleSearch = (data) => {
    setSearchText(data);
    debounce_fun(data);
  };

  const handleOnSearch = () => {
    setIsSearch(!isSearch);
  };

  const handleOpenChat = (id, item) => {
    const successCallBack = async () => {
      await dispatch(getChatUserList("", 1, 1000));
    };

    dispatch(getMsgSuccess(null));
    setConversationId(id);
    setCurrrentChatUser(item);
    dispatch(getMessageList(id));
    dispatch(readAllMsg(id, successCallBack));
    setIsSearch(!isSearch);
    executeScroll(messageRef);
    handleSearch("");
    dispatch(getUnreadFeedbackCount());
  };
  useEffect(() => {
    executeScroll(messageRef);
    setMessageList(Messages);
  }, [Messages]);

  // const handleDeleteUser = (id) => {
  //   const successCallBack = () => {
  //     toast.error("User deleted...");
  //   };
  //   const data = {
  //     is_delete: 1
  //   };
  //   dispatch(editGymUser(id, data, successCallBack));
  // };
  // const handleBanUser = (id) => {
  //   const successCallBack = () => {
  //     toast.success("User Ban Successfully");
  //   };
  //   const data = {
  //     is_active: 0
  //   };
  //   dispatch(editGymUser(id, data, successCallBack));
  // };
  const handleSendMsg = () => {
    executeScroll(messageRef);
    dispatch(sendMessage(conversationId, message));
    let obj = {
      conversation_id: conversationId,
      message: message,
      attachments: null,
      created_at: new Date(),
      updated_at: new Date(),
      created_by: user?.id,
      created_by_first_name: user?.first_name,
    };
    setMessageList([...MessageList, obj]);
    setShowSelected(false);
    setMessage("");
  };

  function executeScroll(ref) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  const handleUploadImages = (e) => {
    const onSuccess = (response) => {
      setDefaultImages(response.split(","));
      setMessage(response);
    };
    imageUploadApiCall(e.target.files, onSuccess);
    setShowSelected(!showSelected);
  };

  const handleMarkAsUnread = (id) => {
    const successCallBack = () => {
      window.location.reload();
      history("/feedback");
    };
    dispatch(markAsUnread(id, successCallBack));
  };
  const handleCloseChat = (id) => {
    const successCallBack = () => {
      dispatch(getChatUserList("", 1, 1000));
      setConversationId(null);
    };
    dispatch(changeChatStatus(id, successCallBack));
  };

  function messageShowFun(item) {
    if (item.message.includes(`${serverUrl}/uploads/images/`)) {
      if (item.message.includes(",")) {
        let img = item.message.split(",");
        return img.map((data, index) => (
          <img src={data} key={index} alt={data} className="chatImage" />
        ));
      } else {
        if (item.message.includes(".pdf")) {
          return (
            <div className="fileView">
              <a target="_blank" rel="noreferrer" href={item.message} download>
                {item.message}
              </a>
            </div>
          );
        } else {
          return (
            <img src={item.message} alt={item.message} className="chatImage" />
          );
        }
      }
    } else {
      return item.message;
    }
  }

  return (
    <div className="mainboxes">
      <TopHeader title={"Feedback"} />
      <div className="row align-items-center searchMain pb-3 mb-3">
        <div className="col-xxl-8 col-lg-8 d-flex align-items-center searchMainInner">
          <div className="w-100" onClick={handleOnSearch}>
            <SearchBar
              placeholder={"Search Message Of User"}
              search={handleSearch}
              defaultValue={searchText}
            />
          </div>
        </div>
        {/* <div className="col-xxl-4 col-lg-4 d-flex align-items-center justify-content-end">
          <DropdownButton
            id="dropdown-basic-button"
            title="All"
            className="bg-transparent listDropDown"
          >
            <Dropdown.Item href="#/action-1">Gym 1</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Gym 2</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Gym 3</Dropdown.Item>
          </DropdownButton>
          <DropdownButton
            id="dropdown-basic-button"
            title="Most Recent"
            className="bg-transparent listDropDown newestBtn"
          >
            <Dropdown.Item href="#/action-1">Gym 1</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Gym 2</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Gym 3</Dropdown.Item>
          </DropdownButton>
        </div> */}
      </div>
      <div className="row">
        <div className="col-xxl-3 col-lg-3 conversationList">
          {ChatUsersLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : (
            <div>
              {conversationList?.map((item, index) => {
                const active = conversationId === item.id ? "activeChat" : "";
                if (item.status === 1) {
                  return (
                    <div
                      key={index}
                      className={`chatProfilMain d-flex align-itemas-center p-2 ${active}`}
                      onClick={() => {
                        handleOpenChat(item.id, item);
                      }}
                    >
                      <span className="chatProfileMain">
                        <img
                          src={
                            item.customer_id_image
                              ? item.customer_id_image
                              : Pic1
                          }
                          alt="Profile"
                          className="chatProfile"
                        />
                      </span>
                      <div className="chatUserSection w-100 position-relative">
                        <p className="m-0 chatUserName">{item?.title}</p>
                        <span className="chatLastMsg">
                          {item?.last_message?.includes(
                            `${serverUrl}/uploads/images/`
                          )
                            ? // <img src={CameraIcon} alt={"cam"} /> +
                              "Photo"
                            : item?.last_message}
                        </span>
                        {item?.unread_count_staff !== 0 && (
                          <span class="position-absolute top-50 chatBadge translate-middle badge rounded-pill bg-danger">
                            {item?.unread_count_staff}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
        <div className="col-xxl-9 col-lg-9 pt-2">
          {conversationId ? (
            <>
              <div className="d-flex align-items-end justify-content-between mb-4">
                <h4 className="m-0 openChatUser">{currrentChatUser.title}</h4>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-primary markUnreadBtn closeChatBtn mx-3"
                    onClick={() => handleCloseChat(conversationId)}
                  >
                    Close Chat
                  </button>
                  <button
                    className="btn btn-primary markUnreadBtn"
                    onClick={() => handleMarkAsUnread(conversationId)}
                  >
                    Mark As Unread
                  </button>
                </div>
                {/* <div className=" d-flex align-items-center">
                  <button
                    className="btn userCDBtn"
                    onClick={() =>
                      handleDeleteUser(currrentChatUser.customer_id)
                    }
                  >
                    Delete User
                  </button>
                  <button
                    className="btn userCDBtn mx-2"
                    onClick={() => handleBanUser(currrentChatUser.customer_id)}
                  >
                    Ban User
                  </button>
                  <div className="moreBtn">
                    <img src={threeBarIcon} alt="threeBarIcon" />
                  </div>
                </div> */}
              </div>
              <div className="startChatSection">
                {MessageList?.length
                  ? !MessagesLoading &&
                    MessageList?.map((item) => {
                      return (
                        <>
                          {item.created_by !== user?.id ? (
                            <div
                              className="recieve my-4"
                              ref={messageRef}
                              key={Math.random().toString()}
                            >
                              <div className="recieveBox d-flex align-itemas-center py-2">
                                <img
                                  src={Pic1}
                                  alt={item?.created_by_first_name
                                    ?.substring(0, 1)
                                    .toUpperCase()}
                                  className="chatProfile"
                                />
                                <div className="chatUserSection">
                                  <p className="m-0 recieveMsgDate">
                                    {moment(item.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>

                                  <span className="recieveMsg">
                                    {item.message}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : item.created_by === user?.id || user.user.id ? (
                            <div
                              className="send my-4"
                              ref={messageRef}
                              key={Math.random().toString()}
                            >
                              <div className="sendBox d-flex align-itemas-center justify-content-end">
                                <div className="chatSendUserSection">
                                  <p className="m-0 senderName">
                                    {`${user.first_name} ${user.last_name}`}
                                  </p>
                                  {/* <p className="m-0 senderSubject my-2">
                                  {user.type}
                                </p> */}

                                  <p className="m-0 senderMsg">
                                    {messageShowFun(item)}
                                  </p>
                                </div>
                                <img
                                  src={user.image ? user.image : Pic1}
                                  alt={user?.username
                                    ?.substring(0, 1)
                                    .toUpperCase()}
                                  className="chatProfile"
                                />
                              </div>
                            </div>
                          ) : null}
                        </>
                      );
                    })
                  : ""}
              </div>
            </>
          ) : (
            <div className="selectUserBanner">
              <h3>Select User</h3>
            </div>
          )}
          <div className="chatInputSection">
            <div className="chatInputInner">
              <a className="btn imageUploadBtn" href="#AttachIcon">
                <input
                  type="file"
                  accept=".pdf,.zip"
                  onChange={(e) => {
                    handleUploadImages(e);
                  }}
                />
                <img src={AttachIcon} alt="AttachIcon" />
              </a>
              <a className="btn imageUploadBtn" href="#ImageIcon">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={(e) => {
                    handleUploadImages(e);
                  }}
                />
                <img src={ImageIcon} alt="ImageIcon" />
              </a>
              {showSelected && defaultImages.length > 0 ? (
                <div className="imageShowSection">
                  {defaultImages.map((data, index) => (
                    <div key={index} className="showImage">
                      <img src={data} alt={data} />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <div className="sendingSection mt-2">
              <input
                type="text"
                className="input chatSendInput"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMsg();
                  }
                }}
              />
              <button
                className="btn btn-primary sendMsgBtn"
                onClick={(e) => handleSendMsg(e)}
                disabled={
                  message.length !== 0 ||
                  (defaultImages.length > 0 && conversationId)
                    ? false
                    : true
                }
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedbackChat;
