import { Col, Row } from "react-bootstrap";
import styles from "./index.module.scss";

function Copyright() {
  return (
    <div className={styles.downloadMain}>
      <div className="container">
        <Row>
          <Col lg={12} md={12}>
            <p className={styles.smartText}>
              Copyright @2023 <a href="xpod.com.sg">xpod.com.sg</a>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Copyright;
