const XLSX = require("xlsx");
const { saveAs } = require("file-saver");
export const handleDownload = (arr, name) => {
  let ws = XLSX.utils.json_to_sheet(arr);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
  const data = new Blob([excelBuffer], { type: "csv" });
  saveAs(data, `${name}.csv`);
};

export const validationFields = (fieldObj) => {
  const newArr = [];
  let fields = Object.keys(fieldObj);
  fields.forEach((item) => {
    fieldObj[item] === "" ||
      fieldObj[item] === null ||
      (typeof fieldObj[item] === "number" &&
        isNaN(fieldObj[item]) &&
        newArr.push(item));
  });
  return newArr;
};
