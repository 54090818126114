import {
  GET_EARNING_FAIL,
  GET_EARNING_REQUEST,
  GET_EARNING_SUCCESS,
  GET_FINANCE_FAIL,
  GET_FINANCE_REQUEST,
  GET_FINANCE_SUCCESS,
  GET_GROUP_EARNING_FAIL,
  GET_GROUP_EARNING_REQUEST,
  GET_GROUP_EARNING_SUCCESS,
  GET_WALLET_FAIL,
  GET_WALLET_REQUEST,
  GET_WALLET_SUCCESS,
} from "../action/actionConstant";

const intialState = {
  status: "",
  financeBy: null,
  isProcess: false,
  isEarning: false,
  earning: null,
  isWallet: false,
  wallet: null,
  isGroupEarn: false,
  groupEarn: null,
};

const finance = (state = intialState, action) => {
  switch (action.type) {
    case GET_FINANCE_REQUEST:
      return {
        ...state,
        financeBy: null,
        isProcess: true,
      };
    case GET_FINANCE_SUCCESS:
      return {
        ...state,
        financeBy: action.data,
        isProcess: false,
      };
    case GET_FINANCE_FAIL:
      return {
        ...state,
        isProcess: false,
      };
    case GET_EARNING_REQUEST:
      return {
        ...state,
        earning: null,
        isEarning: true,
      };
    case GET_EARNING_SUCCESS:
      return {
        ...state,
        earning: action.data,
        isEarning: false,
      };
    case GET_EARNING_FAIL:
      return {
        ...state,
        earning: action.data,
        isEarning: false,
      };
    case GET_WALLET_REQUEST:
      return {
        ...state,
        wallet: null,
        isWallet: true,
      };
    case GET_WALLET_SUCCESS:
      return {
        ...state,
        wallet: action.data,
        isWallet: false,
      };
    case GET_WALLET_FAIL:
      return {
        ...state,
        wallet: action.data,
        isWallet: false,
      };
    case GET_GROUP_EARNING_REQUEST:
      return {
        ...state,
        groupEarn: null,
        isGroupEarn: true,
      };
    case GET_GROUP_EARNING_SUCCESS:
      return {
        ...state,
        groupEarn: action.data,
        isGroupEarn: false,
      };
    case GET_GROUP_EARNING_FAIL:
      return {
        ...state,
        groupEarn: action.data,
        isGroupEarn: false,
      };
    default:
      return state;
  }
};

export default finance;
