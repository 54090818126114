import {
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAIL,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
} from "../action/actionConstant";

const intialState = {
  status: "",
  notificationList: null,
  isProcess: false,
  isCreate: false,
  count: null,
};

const notification = (state = intialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        notificationList: null,
        isProcess: true,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationList: action.data,
        count: action.data.count,
        isProcess: false,
      };
    case GET_NOTIFICATION_FAIL:
      return {
        ...state,
        isProcess: false,
      };
    case SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        isCreate: true,
      };
    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isCreate: false,
      };
    case SEND_NOTIFICATION_FAIL:
      return {
        ...state,
        isCreate: false,
      };
    default:
      return state;
  }
};

export default notification;
