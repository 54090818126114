import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import styles from "./index.module.scss";
import { Offcanvas } from "react-bootstrap";
import { menuBar } from "../LightHeader/LightHeader";

function SideHeader({ color }) {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div>
        <GiHamburgerMenu
          style={{ color: color }}
          onClick={() => {
            setVisible(true);
          }}
        />
        <Offcanvas
          placement="top"
          show={visible}
          onHide={() => {
            setVisible(false);
          }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          {/* <div className={styles.faveside}>
            <UnorderedListOutlined
              onClick={() => {
                setVisible(false);
              }}
            />
          </div> */}
          {menuBar?.map((item, index) => {
            return (
              <>
                <NavLink
                  key={index}
                  activeClassName={styles.selected}
                  className={styles.navLink}
                  exact
                  to={item.link}
                  onClick={handleClick}
                >
                  {item.name}
                </NavLink>
              </>
            );
          })}
        </Offcanvas>
      </div>
    </>
  );
}
export default SideHeader;
