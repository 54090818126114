import moment from "moment";
import { toast } from "react-toastify";
import {
  GET_EARNING_FAIL,
  GET_EARNING_REQUEST,
  GET_EARNING_SUCCESS,
  GET_FINANCE_FAIL,
  GET_FINANCE_REQUEST,
  GET_FINANCE_SUCCESS,
  GET_GROUP_EARNING_FAIL,
  GET_GROUP_EARNING_REQUEST,
  GET_GROUP_EARNING_SUCCESS,
  GET_WALLET_FAIL,
  GET_WALLET_REQUEST,
  GET_WALLET_SUCCESS,
} from "./actionConstant";
import {
  GET_FINANCE_EARNING_URL,
  GET_FINANCE_URL,
  GET_FINANCE_WALLET_URL,
  GET_GROUP_EARNING_URL,
} from "../comman/urls";
import { getLoginUser } from "../comman/localStorage";
import { functionalityApiCall, wihtoutBodyApiCall } from "../comman/connect";

const getFinanceRequested = () => ({
  type: GET_FINANCE_REQUEST,
});

const getFinanceSuccess = (data) => ({
  type: GET_FINANCE_SUCCESS,
  data,
});

const getFinanceFailure = () => ({
  type: GET_FINANCE_FAIL,
});

const getEarningRequested = () => ({
  type: GET_EARNING_REQUEST,
});

const getEarningSuccess = (data) => ({
  type: GET_EARNING_SUCCESS,
  data,
});

const getEarningFailure = (data) => ({
  type: GET_EARNING_FAIL,
  data,
});

const getWalletRequested = () => ({
  type: GET_WALLET_REQUEST,
});

const getWalletSuccess = (data) => ({
  type: GET_WALLET_SUCCESS,
  data,
});

const getWalletFailure = (data) => ({
  type: GET_WALLET_FAIL,
  data,
});

const getGroupEarningRequested = () => ({
  type: GET_GROUP_EARNING_REQUEST,
});

const getGroupEarningSuccess = (data) => ({
  type: GET_GROUP_EARNING_SUCCESS,
  data,
});

const getGroupEarningFailure = (data) => ({
  type: GET_GROUP_EARNING_FAIL,
  data,
});

export const getFinanceCount = (start_date) => (dispatch) => {
  dispatch(getFinanceRequested());
  const { token } = getLoginUser();
  if (token) {
    const data = {
      startDate: start_date,
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    };
    const url = GET_FINANCE_URL;
    const onSuccess = (response) => {
      dispatch(getFinanceSuccess(response.data));
    };

    const onFailure = (response) => {
      dispatch(getFinanceFailure());
      toast.error(response.message);
    };

    functionalityApiCall("POST", url, data, onSuccess, onFailure, token);
  }
};

export const getEarning = (start_date) => (dispatch) => {
  dispatch(getEarningRequested());
  const { token } = getLoginUser();
  if (token) {
    const data = {
      startDate: start_date,
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    };
    const onSuccess = (response) => {
      dispatch(getEarningSuccess(response.data));
    };
    const onFailure = (response) => {
      dispatch(getEarningFailure(response.data));
    };

    functionalityApiCall(
      "POST",
      GET_FINANCE_EARNING_URL,
      data,
      onSuccess,
      onFailure,
      token
    );
  }
};

export const getWallet = () => (dispatch) => {
  dispatch(getWalletRequested());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = (response) => {
      dispatch(getWalletSuccess(response.data));
    };
    const onFailure = (response) => {
      dispatch(getWalletFailure(response.data));
    };

    wihtoutBodyApiCall(
      "POST",
      GET_FINANCE_WALLET_URL,
      onSuccess,
      onFailure,
      token
    );
  }
};

export const getGroupEarning = (start_date) => (dispatch) => {
  dispatch(getGroupEarningRequested());
  const { token } = getLoginUser();
  if (token) {
    const data = {
      startDate: start_date,
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    };
    const onSuccess = (response) => {
      dispatch(getGroupEarningSuccess(response.data));
    };
    const onFailure = (response) => {
      dispatch(getGroupEarningFailure(response.data));
    };

    functionalityApiCall(
      "POST",
      GET_GROUP_EARNING_URL,
      data,
      onSuccess,
      onFailure,
      token
    );
  }
};
