import { toast } from "react-toastify";

import {
  FORGOTPASS_URL,
  GET_LOGIN_USER_URL,
  LOGIN_URL,
  RESETPASS_URL,
  SIGNUP_URL,
} from "../comman/urls";
import {
  LOGOUT,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  RESETPASS_FAIL,
  FORGOTPASS_FAIL,
  REGISTER_SUCCESS,
  REGISTER_REQUEST,
  RESETPASS_SUCCESS,
  RESETPASS_REQUEST,
  FORGOTPASS_REQUEST,
  FORGOTPASS_SUCCESS,
  LOGIN_USER_DETAILS_REQUEST,
  LOGIN_USER_DETAILS_SUCCESS,
  LOGIN_USER_DETAILS_FAIL,
} from "./actionConstant";
import { apiCall, fetchGetApiCall } from "../comman/connect";
import { setLoginUser } from "../comman/localStorage";

const loginRequested = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  data,
});

const loginFailure = (data) => ({
  type: LOGIN_FAIL,
  data,
});
const loginUserDetailsRequested = () => ({
  type: LOGIN_USER_DETAILS_REQUEST,
});

export const loginUserDetailsSuccess = (data) => ({
  type: LOGIN_USER_DETAILS_SUCCESS,
  data,
});
const loginUserDetailsFailure = (data) => ({
  type: LOGIN_USER_DETAILS_FAIL,
  data,
});
const logout = () => ({
  type: LOGOUT,
});

const registerRequested = () => ({
  type: REGISTER_REQUEST,
});

const registerSuccess = (data) => ({
  type: REGISTER_SUCCESS,
  data,
});

const registerFailure = (data) => ({
  type: REGISTER_FAIL,
  data,
});

const forgotPassRequested = () => ({
  type: FORGOTPASS_REQUEST,
});

const forgotPassSuccess = (data) => ({
  type: FORGOTPASS_SUCCESS,
  data,
});

const forgotPassFailure = (data) => ({
  type: FORGOTPASS_FAIL,
  data,
});

const resetPassRequested = () => ({
  type: RESETPASS_REQUEST,
});

const resetPassSuccess = (data) => ({
  type: RESETPASS_SUCCESS,
  data,
});

const resetPassFailure = (data) => ({
  type: RESETPASS_FAIL,
  data,
});

export const loginUser = (email, password, successCallBack) => (dispatch) => {
  dispatch(loginRequested());

  const url = LOGIN_URL;

  var userDetail = {
    username: email,
    password: password,
  };

  const onSuccess = async (response) => {
    dispatch(loginSuccess(response));
    setLoginUser(response.data);
    successCallBack(response);
  };

  const onFailure = (response) => {
    dispatch(loginFailure(response));
    toast.error(response.message);
  };

  apiCall("POST", url, userDetail, onSuccess, onFailure, dispatch);
};

export const logoutUser = (logoutCallBack) => (dispatch) => {
  dispatch(logout());
  setLoginUser(null);
  logoutCallBack();
};

export const signupUser = (reuqestData, successCallBack) => (dispatch) => {
  dispatch(registerRequested());

  const url = SIGNUP_URL;

  const onSuccess = (response) => {
    dispatch(registerSuccess(response));
    setLoginUser(response.data);
    successCallBack(response);
  };

  const onFailure = (response) => {
    dispatch(registerFailure(response));
    toast.error(response.message);
  };

  apiCall("POST", url, reuqestData, onSuccess, onFailure, dispatch);
};

export const forgotPassword =
  (reuqestData, successCallBack, failCallBack) => (dispatch) => {
    dispatch(forgotPassRequested());
    const url = FORGOTPASS_URL;
    const data = {
      email: reuqestData,
    };
    const onSuccess = (response) => {
      forgotPassSuccess(response);
      toast.success(response.message);
      successCallBack(response.token);
    };
    const onFailure = (response) => {
      forgotPassFailure(response);
      toast.error(response.message);
      failCallBack(response.message);
    };

    apiCall("POST", url, data, onSuccess, onFailure);
  };
export const resetPassword =
  (token, password, successCallBack) => (dispatch) => {
    dispatch(resetPassRequested());
    const url = RESETPASS_URL;
    const data = {
      token: token,
      password: password,
    };
    const onSuccess = (response) => {
      resetPassSuccess(response);
      toast.success(response.message);
      successCallBack(response);
    };
    const onFailure = (response) => {
      resetPassFailure(response);
      toast.error(response.message);
    };

    apiCall("POST", url, data, onSuccess, onFailure);
  };

export const getLoginUserDetails = () => (dispatch) => {
  dispatch(loginUserDetailsRequested());
  const url = GET_LOGIN_USER_URL;
  const onSuccess = (response) => {
    dispatch(loginUserDetailsSuccess(response));
  };

  const onFailure = (response) => {
    dispatch(loginUserDetailsFailure(response));
    toast.error(response.message);
  };

  fetchGetApiCall(url, onSuccess, onFailure);
};
