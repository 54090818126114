import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";

import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
import Edit from "../Components/svgImg/Edit.svg";
import Delete from "../Components/svgImg/Delete.svg";
import { deleteGroupDetail, getGroupDetails } from "../action/groupDetails";

function Groups() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState();
  const [filter, setFilter] = useState("Oldest");
  const [sort, setSort] = useState("");
  const [filedFilter, setFieldFilter] = useState("All");
  const [modalShow, setModalShow] = useState(false);
  const isLoading = useSelector((state) => state.groupDetail.isProcess);
  const groupList = useSelector((state) =>
    state.groupDetail.data?.list ? state.groupDetail.data?.list : []
  );

  useEffect(() => {
    dispatch(getGroupDetails("", 1, 20));
  }, [dispatch]);

  const handleDelete = () => {
    dispatch(deleteGroupDetail(deleteId));
    setModalShow(!modalShow);
    dispatch(getGroupDetails("", 1, 20));
  };

  const handleFilter = (value) => {
    dispatch(getGroupDetails("", 1, 10, value, filedFilter));
  };

  const handleColumnFilter = (value) => {
    dispatch(getGroupDetails("", 1, 10, sort, value));
  };

  const handleSearch = (data) => {
    dispatch(getGroupDetails(data, 1, 200));
  };

  const debounce_fun = _.debounce(function (response) {
    handleSearch(response);
  }, 500);

  const header = [
    { name: "Group Name", selector: (row) => row.name, sortable: true },
    { name: "Price", selector: (row) => row.price, sortable: true },
    { name: "Period", selector: (row) => row.period, sortable: true },
    {
      name: "Max Current Slot",
      selector: (row) => row.max_slot,
      sortable: true,
    },
    { name: "Discount %", selector: (row) => row.discount, sortable: true },
    {
      name: "Book Amendment",
      selector: (row) => row.book_amendment,
      sortable: true,
    },
    {
      name: "Advance Booking #",
      selector: (row) => row.advance_booking,
      sortable: true,
    },
    {
      name: "Cancellation Fee",
      selector: (row) => row.cancellation_fee,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (selectors) => (
        <div className="d-flex align-items-center">
          <button
            className="btn actionBtn"
            onClick={() => {
              history("/group-details", { state: selectors });
            }}
          >
            <img src={Edit} alt="edit" />
          </button>
          <button
            className="btn actionBtn"
            onClick={() => {
              setModalShow(!modalShow);
              setDeleteId(selectors.id);
            }}
          >
            <img src={Delete} alt="delete" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="mainboxes">
      <TopHeader title={"Group Listing"} />
      <div className="row align-items-center searchMain pb-3 mb-3">
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Group
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete?</h4>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => {
                setModalShow(!modalShow);
              }}
            >
              Close
            </button>
            <button
              className="btn enableBtnActive"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>
        <div className="col-xxl-6 col-lg-6 col-md-12">
          <SearchBar placeholder={"Search Group"} search={debounce_fun} />
        </div>
        <div className="col-xxl-4 col-lg-4 col-md-8 d-flex align-items-center tabletSpacing mobileCenter">
          <DropdownButton
            id="dropdown-basic-button"
            title={filedFilter}
            className="bg-transparent listDropDown"
            onSelect={(e) => {
              e = e.split(",");
              setFieldFilter(e[1]);
              handleColumnFilter(e[0]);
            }}
          >
            {header.map((data) => {
              return (
                <Dropdown.Item eventKey={[data.prop, data.title]}>
                  {data.title}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
          <DropdownButton
            id="dropdown-basic-button"
            title={filter}
            className="bg-transparent listDropDown mobileDrop"
            onSelect={(e) => {
              setFilter(e === "desc" ? "Most Recent" : "Oldest");
              setSort(e);
              handleFilter(e);
            }}
          >
            <Dropdown.Item eventKey={"desc"}>Most Recent</Dropdown.Item>
            <Dropdown.Item eventKey={"asc"}>Oldest</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="col-xxl-2 col-lg-2 col-md-4 tabletSpacing">
          <button
            className="btn createGymBtn tabletGymBtn"
            onClick={() => {
              history("/group-create");
            }}
          >
            Create Group
          </button>
        </div>
      </div>
      <div className="row">
        <div className="table-responsive">
          {isLoading ? (
            <div className="spinner">
              <TailSpin heigth="75" width="100" color="black" />
            </div>
          ) : (
            <DataTable columns={header} data={groupList} pagination />
          )}
        </div>
      </div>
    </div>
  );
}

export default Groups;
