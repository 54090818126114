import {
  GET_CHAT_FAIL,
  GET_CHAT_SUCCESS,
  GET_CHAT_REQUEST,
  GET_MSG_FAIL,
  GET_MSG_REQUEST,
  GET_MSG_SUCCESS,
  CREATE_CHAT_FAIL,
  CREATE_CHAT_REQUEST,
  CREATE_CHAT_SUCCESS,
  UPDATE_CHAT_STATUS_REQUEST,
  UPDATE_CHAT_STATUS_SUCCESS,
  UPDATE_CHAT_STATUS_FAIL,
} from "../action/actionConstant";

const intialState = {
  status: "",
  conversationList: null,
  isProcess: false,
  messageList: null,
  isMessageList: false,
  chatRes: null,
};

const chat = (state = intialState, action) => {
  switch (action.type) {
    case CREATE_CHAT_REQUEST:
      return {
        ...state,
        chatRes: null,
        isProcess: true,
      };
    case CREATE_CHAT_SUCCESS:
      return {
        ...state,
        chatRes: action.payload,
        isProcess: false,
      };
    case CREATE_CHAT_FAIL:
      return {
        ...state,
        chatRes: action.payload,
        isProcess: false,
      };
    case GET_CHAT_REQUEST:
      return {
        ...state,
        conversationList: null,
        isProcess: true,
      };
    case GET_CHAT_SUCCESS:
      return {
        ...state,
        conversationList: action.payload,
        isProcess: false,
      };
    case GET_CHAT_FAIL:
      return {
        ...state,
        isProcess: false,
      };
    case GET_MSG_REQUEST:
      return {
        ...state,
        messageList: null,
        isMessageList: true,
      };
    case GET_MSG_SUCCESS:
      return {
        ...state,
        messageList: action.payload?.reverse(),
        isMessageList: false,
      };
    case GET_MSG_FAIL:
      return {
        ...state,
        isMessageList: false,
      };
    case UPDATE_CHAT_STATUS_REQUEST:
      return {
        ...state,
        messageList: null,
        isMessageList: true,
      };
    case UPDATE_CHAT_STATUS_SUCCESS:
      return {
        ...state,
        messageList: action.payload?.reverse(),
        isMessageList: false,
      };
    case UPDATE_CHAT_STATUS_FAIL:
      return {
        ...state,
        isMessageList: false,
      };

    // case EDIT_CHAT_REQUEST:
    //   return {
    //     ...state,
    //     data: null,
    //     isProcess: true,
    //   };
    // case EDIT_CHAT_SUCCESS:
    //   return {
    //     ...state,
    //     data: null,
    //     isProcess: false,
    //   };
    // case EDIT_CHAT_FAIL:
    //   return {
    //     ...state,
    //     isProcess: false,
    //   };
    default:
      return state;
  }
};

export default chat;
