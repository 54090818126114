import TopHeader from "./TopHeader";

function CCTV(props) {
  return (
    <div className="mainboxes">
      <TopHeader title={"CCTV"} />
      {/* <div className="row align-items-center justify-content-between searchMain pb-2 mb-3">
        <div className="col-xxl-6 col-lg-6 d-flex align-items-center justify-content-between">
          <input type="text" className="input" placeholder="URL or link" />
          <DropdownButton
            id="dropdown-basic-button"
            title="Select Camera"
            className="bg-transparent lastBooking"
          >
            <Dropdown.Item href="#/action-1">Gym 1</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Gym 2</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Gym 3</Dropdown.Item>
          </DropdownButton>
        </div>
        <div className="col-xxl-3 col-lg-3 d-flex align-items-center justify-content-end tabletTopSpacing">
          <button className="btn topCancel mx-2">Cancel</button>
          <button className="btn saveBtn">Save</button>
        </div>
      </div> */}
      <div className="row">
        <div className="col-xxl-12 col-lg-12">
          <div>
            <h4>Camera feed will be shown once API from CCTV is connected</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CCTV;
