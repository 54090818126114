import { toast } from "react-toastify";

import {
  DEBIT_AMOUNT_REQUEST,
  DEBIT_AMOUNT_SUCCESS,
  DEBIT_AMOUNT_FAIL,
  CREDIT_AMOUNT_REQUEST,
  CREDIT_AMOUNT_SUCCESS,
  CREDIT_AMOUNT_FAIL,
} from "./actionConstant";
import { getLoginUser } from "../comman/localStorage";
import { functionalityApiCall } from "../comman/connect";
import { CREDIT_AMOUNT_URL, DEBIT_AMOUNT_URL } from "../comman/urls";

const debitAmountRequest = () => ({
  type: DEBIT_AMOUNT_REQUEST,
});

const debitAmountSuccess = () => ({
  type: DEBIT_AMOUNT_SUCCESS,
});

const debitAmountFailure = () => ({
  type: DEBIT_AMOUNT_FAIL,
});

const creditAmountRequest = () => ({
  type: CREDIT_AMOUNT_REQUEST,
});

const creditAmountSuccess = () => ({
  type: CREDIT_AMOUNT_SUCCESS,
});

const creditAmountFailure = () => ({
  type: CREDIT_AMOUNT_FAIL,
});

export const debitAmount = (id, requestData, successCallBack) => (dispatch) => {
  dispatch(debitAmountRequest());
  const { token } = getLoginUser();
  if (token) {
    const onSuccess = () => {
      dispatch(debitAmountSuccess());
      successCallBack();
    };
    const url = `${DEBIT_AMOUNT_URL}/${id}`;

    const onFailure = (response) => {
      dispatch(debitAmountFailure());
      toast.error(response.message || response.data.message);
    };

    functionalityApiCall("PUT", url, requestData, onSuccess, onFailure, token);
  }
};

export const creditAmount =
  (id, requestData, successCallBack) => (dispatch) => {
    dispatch(creditAmountRequest());
    const { token } = getLoginUser();
    if (token) {
      const onSuccess = (response) => {
        dispatch(creditAmountSuccess(response));
        successCallBack(response);
      };
      const url = `${CREDIT_AMOUNT_URL}/${id}`;

      const onFailure = (response) => {
        dispatch(creditAmountFailure());
        toast.error(response.message || response.data.message);
      };

      functionalityApiCall(
        "PUT",
        url,
        requestData,
        onSuccess,
        onFailure,
        token
      );
    }
  };
