export const setLoginUser = (data) => {
  localStorage.setItem("user", JSON.stringify(data));
};

export const getLoginUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const isAuthenticated = (data) => {
  return localStorage.setItem("isAuthenticate", data);
};
