/* eslint-disable  no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable  no-useless-computed-key */
import moment from "moment";
import { toast } from "react-toastify";
import React, { useState } from "react";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import TimePicker from "react-time-picker";
import { TailSpin } from "react-loader-spinner";
import AliceCarousel from "react-alice-carousel";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { Dropdown, DropdownButton, InputGroup } from "react-bootstrap";

import TopHeader from "./TopHeader";
import { validationFields } from "../comman/Hooks";
import CameraIcon from "../Components/svgImg/Camera.svg";
import { editGymDetails, setOpenDoor } from "../action/gymDetails";
import { getTimeStops, imageUploadApiCall } from "../comman/connect";

import "../Components/CSSfiles/GymDetail.css";
import "../../node_modules/react-slideshow-image/dist/styles.css";
import "../../node_modules/react-alice-carousel/lib/alice-carousel.css";

function GymDetail() {
  const index = 1;
  const { state } = useLocation();
  const defaultValue = state;
  const history = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(defaultValue?.google_map_url);
  const [formErrors, setFormErrors] = useState([]);
  const [enable24, setEnable24] = useState(
    defaultValue?.twenty_four_seven === 1 ? true : false
  );
  const [enablePeak, setEnablePeak] = useState(
    defaultValue?.no_peak_hours === 1 ? true : false
  );
  const [enableSuperPeak, setEnableSuperPeak] = useState(
    defaultValue?.no_super_peak_hours === 1 ? true : false
  );
  const [enable, setEnable] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [schedual, setSchedual] = useState(
    defaultValue?.service_status === 1 ? true : false
  );
  const [gymType, setGymType] = useState(defaultValue?.gym_type);
  const [operationEnd, setOperationEnd] = useState(
    defaultValue.close_at ? defaultValue.close_at : "23:59"
  );
  const [operationStart, setOperationStart] = useState(
    defaultValue.open_at ? defaultValue.open_at : "00:00"
  );
  const [defaultImages, setDefaultImages] = useState(
    defaultValue.images ? defaultValue.images.split(",") : []
  );
  const [qrImage, setQrImage] = useState(defaultValue?.qr_code);
  const [minSlot, setMinSlot] = useState(defaultValue?.minutes_per_slot);
  const [gymDetail, setGymDetail] = useState({
    id: parseInt(defaultValue?.id),
    address: defaultValue.address,
    area: defaultValue.area,
    available_equipments: defaultValue.available_equipments,
    description_1: defaultValue.description_1,
    google_map_url: defaultValue.google_map_url,
    gym_type: defaultValue.gym_type,
    images: defaultValue.images,
    max_user_pax: defaultValue.max_user_pax,
    minutes_per_slot: defaultValue.minutes_per_slot,
    open_at: defaultValue.open_at,
    peak_hour_price: defaultValue.peak_hour_price,
    peak_hour_thirty_min_price: defaultValue.peak_hour_thirty_min_price,
    peak_hours_max: defaultValue.peak_hours_max,
    peak_hours_min: defaultValue.peak_hours_min,
    service_end: defaultValue.service_end,
    service_start: defaultValue.service_start,
    super_peak_hour_price: defaultValue.super_peak_hour_price,
    super_peak_hour_thirty_min_price:
      defaultValue.super_peak_hour_thirty_min_price,
    super_peak_hours_max: defaultValue.super_peak_hours_max,
    super_peak_hours_min: defaultValue.super_peak_hours_min,
  });
  const [peakHour, setPeakHour] = useState(
    defaultValue?.peak_hours_min ? defaultValue?.peak_hours_min : "Select..."
  );
  const [totalSlot, setTotalSlot] = useState(defaultValue?.slots_per_day);
  const [peakHourTo, setPeakHourTo] = useState(
    defaultValue?.peak_hours_max ? defaultValue?.peak_hours_max : "Select..."
  );
  const [peakHourOne, setPeakHourOne] = useState(
    defaultValue?.peak_hour_thirty_min_price
  );
  const [peakHourTwo, setPeakHourTwo] = useState(defaultValue?.slots_per_day);
  const [superHour, setSuperHour] = useState(
    defaultValue?.super_peak_hours_min
      ? defaultValue?.super_peak_hours_min
      : "Select..."
  );
  const [superHourTo, setSuperHourTo] = useState(
    defaultValue?.super_peak_hours_max
      ? defaultValue?.super_peak_hours_max
      : "Select..."
  );
  const [lat, setLat] = useState(defaultValue?.location?.coordinates[1]);
  const [long, setLong] = useState(defaultValue?.location?.coordinates[0]);
  const isLoading = useSelector((state) => state.gymDetail.isProcess);
  const [startDate, setStartDate] = useState(defaultValue?.start_date);
  const [endDate, setEndDate] = useState(defaultValue?.end_date);
  const [isDuration, setIsDuration] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [slots, setSlots] = useState(
    getTimeStops(operationStart, operationEnd, minSlot)
  );

  const handleActiveService = (e) => {
    setSchedual(!schedual);
    let gymObj = gymDetail;
    gymObj.service_status = e.target.checked;
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "google_map_url" && value !== "") {
      //eslint-disable-next-line
      let longlat = /\/\@(.*),(.*),/.exec(value);
      setLat(parseFloat(longlat[1]));
      setLong(parseFloat(longlat[2]));
      const location = {
        latitude: parseFloat(longlat[1]),
        longitude: parseFloat(longlat[2]),
      };
      setGymDetail((gymDetail) => ({
        ...gymDetail,
        ["location"]: location,
      }));
    }
    if (
      name === "max_user_pax" ||
      name === "area" ||
      name === "first_hour_price" ||
      name === "first_hour_thirty_min_price" ||
      name === "peak_hour_price" ||
      name === "peak_hour_thirty_min_price" ||
      name === "no_peak_hours"
    ) {
      setGymDetail((gymDetail) => ({
        ...gymDetail,
        [name]: parseInt(value),
      }));
    } else {
      setGymDetail((gymDetail) => ({
        ...gymDetail,
        [name]: value,
      }));
    }
  };

  const handleCheck = (e) => {
    const { name } = e.target;
    const value = e.target.checked;
    if (name === "twenty_four_seven") {
      if (value) {
        setEnable24(!enable24);
        setSlots(getTimeStops("00:00", "23:59", minSlot ? minSlot : "30"));
      } else {
        setEnable24(!enable24);
      }
    }
    if (name === "no_peak_hours") {
      if (value) {
        setPeakHourTo("Select...");
        setPeakHour("Select...");
        let gymObj = gymDetail;
        gymObj.peak_hours_min = null;
        gymObj.peak_hours_max = null;
        setEnablePeak(!enablePeak);
      } else {
        setEnablePeak(!enablePeak);
      }
    }
    if (name === "no_super_peak_hours") {
      if (value) {
        setSuperHourTo("Select...");
        setSuperHour("Select...");
        let gymObj = gymDetail;
        gymObj.super_peak_hours_max = null;
        gymObj.super_peak_hours_min = null;
        setEnableSuperPeak(!enableSuperPeak);
      } else {
        setEnableSuperPeak(!enableSuperPeak);
      }
    }

    setGymDetail((gymDetail) => ({
      ...gymDetail,
      [name]: value,
    }));
  };
  // const GymEquipmentArr = [
  //   {

  //   }
  // ]

  // const arr = defaultValue.available_equipments ? defaultValue.available_equipments.split(',') : [];
  // const handleEquipments = (e) => {
  //   const { name, checked } = e.target;
  //   if (checked) {
  //     arr.push(name);
  //   } else {
  //     arr.map((data, index) => {
  //       if (data === name) {
  //         arr.splice(index, 1);
  //       }
  //     });
  //   }
  // };

  const handleSubmit = () => {
    let gymObj = gymDetail;
    gymObj.service_start = moment(selectionRange.startDate).format(
      "YYYY-MM-DD  HH:mm:ss"
    );
    gymObj.service_end = moment(selectionRange.endDate).format(
      "YYYY-MM-DD  HH:mm:ss"
    );

    gymObj.google_map_url = value && value?.label;
    const validFieldArray = validationFields(gymObj);
    if (validFieldArray.length === 0) {
      const succesCallBack = () => {
        setFormErrors([]);
        history("/gym-listing");
      };

      dispatch(editGymDetails([gymObj], succesCallBack));
    } else {
      console.log("validFieldArray", validFieldArray);
      setFormErrors([...validFieldArray]);
      toast.error("Fileds are required");
    }
  };

  const handleDropdown = (name, e) => {
    setGymDetail({
      ...gymDetail,
      [name]: e,
    });
  };

  const gymImageSlide = {
    0: {
      items: 1,
    },
    1024: {
      items: 1,
    },
  };

  const handleMapClick = (lat, lng) => {
    setLat(lat);
    setLong(lng);
    const location = {
      latitude: lat,
      longitude: lng,
    };
    setGymDetail((gymDetail) => ({
      ...gymDetail,
      ["location"]: location,
    }));
  };

  const handleUploadImages = (e) => {
    const onSuccess = (response) => {
      setDefaultImages(response.split(","));
      let gymObj = gymDetail;
      gymObj.images = response;
    };
    imageUploadApiCall(e.target.files, onSuccess);
  };

  const handleUploadQR = (e) => {
    const onSuccess = (response) => {
      setQrImage(response);
      let gymObj = gymDetail;
      gymObj.qr_code = response;
    };
    imageUploadApiCall(e.target.files, onSuccess);
  };

  const handleOpenDoor = () => {
    if (defaultValue?.door_hardware_url) {
      let obj = {
        gymId: defaultValue?.id,
        door_hardware_url: defaultValue?.door_hardware_url,
      };
      dispatch(setOpenDoor(obj));
    } else {
      toast.warning("Configurations are not completed to open the door!");
    }
  };

  // const GymEquipment = [];
  // for (let index = 0; index < 13; index++) {
  //   GymEquipment.push(
  //     <div className="text-center">
  //       <div className="gymIconDiv">
  //         <span>
  //           <img src={gym} alt="icon" />
  //         </span>
  //       </div>
  //       <div className="slideInput mt-3">
  //         <InputGroup.Checkbox
  //           type="checkbox"
  //           name={index}
  //           onChange={(e) => {
  //             handleEquipments(e);
  //           }}
  //           defaultChecked={defaultEquipment.includes(index)}
  //           aria-label="Checkbox for following text input my-2 justify-content-center"
  //         />
  //         <input className="equipmentInput" type="text" name="" id="" />
  //       </div>
  //     </div>
  //   );
  // }

  const defImages = [];
  defaultImages.map((data, index) => {
    defImages.push(
      <img
        key={index}
        src={data}
        alt={data}
        className="profileImage w-100 h-100"
      />
    );
  });

  const GoogleMapExample = withGoogleMap((props) => (
    <GoogleMap
      defaultCenter={{ lat: lat, lng: long }}
      defaultZoom={13}
      onClick={(e) => {
        setLat(e.latLng.lat());
        setLong(e.latLng.lng());
        handleMapClick(e.latLng.lat(), e.latLng.lng());
      }}
    >
      <Marker position={{ lat: lat, lng: long }} />
    </GoogleMap>
  ));

  value &&
    geocodeByAddress(value?.label ? value?.label : "")
      .then((results, Status) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLat(lat);
        setLong(lng);
        setLatLong();
      });

  const setLatLong = () => {
    const location = {
      latitude: lat,
      longitude: long,
    };
    setGymDetail((gymDetail) => ({
      ...gymDetail,
      ["location"]: location,
    }));
  };

  return (
    <div className="mainboxes">
      <TopHeader title={"Edit Gym Detail"} />

      <div className="row align-items-center justify-content-end searchMain pb-2 mb-3">
        <div className="topSection flex-wrap">
          <div className="col-xxl-1 col-lg-1 col-md-2 col-sm-12 d-flex align-items-center mobileSizeSpace">
            <p className="gymTopBarTitle">ID</p>
            <p className="topDetail topGroupDetail">{defaultValue?.id}</p>
          </div>
          <div className="col-xxl-4 col-lg-7 col-md-10 col-sm-12 d-flex align-items-center mobileSizeSpace tabletTextEnd">
            <p className="gymTopBarTitle">Down time</p>
            <div className="topDetail topGroupDetail">
              <div className="form-switch custom-control custom-switch p-0 d-flex align-items-center">
                <input
                  className="form-check-input checkboxes"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  defaultChecked={
                    defaultValue.service_status === 1 ? true : false
                  }
                  onChange={(e) => {
                    handleActiveService(e);
                  }}
                />
                <label
                  className="form-check-label"
                  for="flexSwitchCheckDefault"
                >
                  <span className="mx-2">
                    <span
                      className={`${
                        defaultValue.service_status === 1
                          ? "switchText activeSwitch"
                          : "switchText"
                      }`}
                    >
                      ON
                    </span>
                    <span
                      className={`${
                        defaultValue.service_status === 0
                          ? "switchText deactive"
                          : "switchText"
                      }`}
                    >
                      OFF
                    </span>
                  </span>
                </label>
                <span className={`switchTime ${index === 1 && "activeSwitch"}`}>
                  {!schedual ? (
                    ""
                  ) : (
                    <>
                      <span
                        className={`switchTime ${
                          index === 1 && "activeSwitch"
                        }`}
                      >
                        {moment(selectionRange.startDate).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </span>
                      <>
                        <button
                          className="btn btn-border mx-2"
                          onClick={() => setShowDateRange(!showDateRange)}
                        >
                          Schedule
                        </button>
                        {showDateRange && (
                          <>
                            <div className="rangePicker">
                              <DateRangePicker
                                ranges={[selectionRange]}
                                showSelectionPreview={false}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                direction="horizontal"
                                onChange={(item) => {
                                  setSelectionRange(item.selection);
                                }}
                              />
                              <div className="d-flex justify-content-end">
                                <button
                                  className="btn saveBtn downBtn  m-2"
                                  onClick={() =>
                                    setShowDateRange(!showDateRange)
                                  }
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-3 col-md-6 col-sm-12 laptopSize d-flex align-items-center tabletTextEnd mobileSizeSpace">
            <p className="gymTopBarTitle">Open door</p>
            <div className="topDetail topGroupDetail">
              <button
                className="btn btn-border doorBtn detailBtn text-center"
                onClick={() => handleOpenDoor()}
              >
                Open Door
              </button>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-12 col-md-6 col-sm-12 laptopSize d-flex align-items-center justify-content-end mobileSizeSpace">
            <button
              className="btn topCancel mx-2"
              onClick={() => {
                history("/gym-listing");
              }}
            >
              Cancel
            </button>
            <button
              className="btn saveBtn franchBtn mobileBtn"
              onClick={() => {
                handleSubmit();
              }}
            >
              {!isLoading ? (
                "Save"
              ) : (
                <TailSpin heigth="15" width="45" color="white" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xxl-4 col-lg-12">
          <div className="row">
            <div className="col-xxl-12 col-lg-6 mt-3">
              <div className="borderShadowBox profilePicBox slideImageBox">
                <div className="gymImagesSlide">
                  <AliceCarousel
                    mouseTracking
                    items={defImages}
                    responsive={gymImageSlide}
                  />
                </div>
                <button className="btn chnagePicBtn">
                  <input
                    type="file"
                    name="images"
                    multiple
                    className="picInput"
                    onChange={(e) => {
                      handleUploadImages(e);
                    }}
                  ></input>
                  <img
                    src={CameraIcon}
                    alt="cam"
                    className="mx-1 cursor-pointer"
                  />{" "}
                  Change Photo
                </button>
              </div>
            </div>

            <div className="col-xxl-12 col-lg-6">
              <div className="borderShadowBox userWalletBox">
                <div className="mt-4">
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Address
                  </p>
                  <input
                    type="text"
                    className={`input franchiseDetailInput ${
                      formErrors.includes("address") && "error"
                    }`}
                    name="address"
                    defaultValue={defaultValue?.address}
                    onChange={handleInput}
                  />
                </div>
                <div className="mt-4">
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Google Maps URL
                  </p>
                  {/* <input
                    type="text"
                    className="input franchiseDetailInput"
                    name="google_map_url"
                    defaultValue={defaultValue?.google_map_url}
                    onChange={handleInput}
                  /> */}
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyCHMAdJH0T0nfMFglo9KbpJcnZ2-DfepyA"
                    lePlacesAutocomplete
                    placeholder="Search"
                    fetchDetails={true}
                    selectProps={{
                      defaultInputValue: value,
                      isClearable: true,
                      value,
                      onChange: setValue,
                    }}
                  />
                </div>
                <div className="mt-5">
                  <div style={{ height: "300px", width: "100%" }}>
                    <GoogleMapExample
                      containerElement={
                        <div style={{ height: `100%`, width: "100%" }} />
                      }
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-8 col-lg-12">
          <div className="borderShadowBox userProfileBox my-3">
            <div className="row">
              <div className="col-xxl-5 col-lg-6 col-md-12 col-sm-12 nameSection">
                <div>
                  <p className="topBarTitle franchiseeDetailBoxTitle">
                    Name Of Gym
                  </p>
                  <input
                    type="text"
                    className="input franchiseDetailInput detailInputFirst"
                    name="name"
                    defaultValue={defaultValue?.name}
                    onChange={handleInput}
                  />
                </div>
              </div>
              {/* <div className="col-xxl-4 col-lg-4">
                <div className="d-flex align-items-end justify-content-between">
                  <div className="detailTHreeDrop">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Start date
                    </p>
                    <input
                      type="date"
                      className="input franchiseDetailInput startDate"
                      onChange={(e) => {
                        setOperationStart(e.target.value);
                        handleDropdown("start_date", e.target.value);
                      }}
                      defaultValue={operationStart}
                    />
                  </div>
                  <span className="to">To</span>
                  <div className="detailTHreeDrop">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      End date
                    </p>
                    <input
                      type="date"
                      className="input franchiseDetailInput startDate"
                      onChange={(e) => {
                        setOperationEnd(e.target.value);
                        handleDropdown("end_date", e.target.value);
                      }}
                      defaultValue={operationEnd}
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-xxl-5 col-lg-4 col-md-9 col-sm-12 mobileOperation">
                <div className="d-flex align-items-end justify-content-between">
                  <div className="detailTHreeDrop w-100">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Operation Start
                    </p>
                    <span className="timePicker">
                      <TimePicker
                        disabled={enable24 ? true : false}
                        disableClock={true}
                        format="H:m"
                        onChange={(value) => {
                          setOperationStart(value);
                          handleDropdown("open_at", value);
                          if (value && operationEnd && minSlot) {
                            setSlots(
                              getTimeStops(value, operationEnd, minSlot)
                            );
                          }
                        }}
                        value={operationStart}
                      />
                    </span>
                  </div>
                  <span className="to">To</span>
                  <div className="detailTHreeDrop w-100">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Operation end
                    </p>
                    <span className="timePicker">
                      <TimePicker
                        disabled={enable24 ? true : false}
                        disableClock={true}
                        format="HH:m"
                        onChange={(value) => {
                          setOperationEnd(value);
                          handleDropdown("close_at", value);
                          if (operationStart && value && minSlot) {
                            setSlots(
                              getTimeStops(operationStart, value, minSlot)
                            );
                          }
                        }}
                        value={operationEnd}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-lg-2 col-md-3 col-sm-12 d-flex align-items-end">
                <div className="detailCheckmain">
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    name="twenty_four_seven"
                    onChange={handleCheck}
                    defaultChecked={enable24}
                  />
                  <span className="checkboxText">24 hours Operation</span>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-xxl-4 col-lg-4 col-md-8">
                <div className="dateSection">
                  <div className="detailTHreeDrop">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Start date
                    </p>
                    <input
                      type="date"
                      className={`input franchiseDetailInput startDate ${
                        formErrors.includes("start_date") && "error"
                      }`}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        handleDropdown("start_date", e.target.value);
                      }}
                      defaultValue={startDate}
                    />
                  </div>
                  <span className="to">To</span>
                  <div className="detailTHreeDrop">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      End Date
                    </p>
                    <input
                      type="date"
                      className={`input franchiseDetailInput startDate ${
                        formErrors.includes("end_date") && "error"
                      }`}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        handleDropdown("end_date", e.target.value);
                      }}
                      defaultValue={endDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-xxl-7 col-lg-7 col-md-12">
                <div className="align-items-center justify-content-between mobileArea">
                  <div className="w-100">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Max User Pax
                    </p>
                    <input
                      type="number"
                      className={`input franchiseDetailInput detailPriceSlot ${
                        formErrors.includes("max_user_pax") && "error"
                      }`}
                      name="max_user_pax"
                      defaultValue={defaultValue?.max_user_pax}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mobileAriaSpace w-100 mobileSizeSpace">
                    <p className="topBarTitle franchiseeDetailBoxTitle">area</p>

                    <div className="areaInput">
                      <input
                        type="number"
                        className={`input franchiseDetailInput detailPriceSlot ${
                          formErrors.includes("area") && "error"
                        }`}
                        name="area"
                        defaultValue={defaultValue?.area}
                        onChange={handleInput}
                      />
                      <span>sqm</span>
                    </div>
                  </div>
                  <div className="fullWidth mobileSizeSpace">
                    <p className="topBarTitle franchiseeDetailBoxTitle">Type</p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={gymType}
                      className={`bg-transparent innerSpace ${
                        formErrors.includes("gym_type") && "error"
                      }`}
                      onSelect={(e) => {
                        setGymType(e);
                        handleDropdown("gym_type", e);
                        if (e === "DURATION") {
                          setIsDuration(true);
                        } else {
                          setIsDuration(false);
                        }
                      }}
                    >
                      <Dropdown.Item eventKey={"SLOT"}>SLOT</Dropdown.Item>
                      <Dropdown.Item eventKey={"DURATION"}>
                        DURATION
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-lg-3 col-md-6 mobileSlot">
                <div className="d-flex align-items-end justify-content-end">
                  <div className="slotBtn">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Minutes Per Slot
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={minSlot ? minSlot : "select..."}
                      className={`bg-transparent ${
                        formErrors.includes("minutes_per_slot") && "error"
                      }`}
                      onSelect={(e) => {
                        setMinSlot(e);
                        handleDropdown("minutes_per_slot", e);
                        if (operationStart && operationEnd && e) {
                          setSlots(
                            getTimeStops(operationStart, operationEnd, e)
                          );
                        }
                      }}
                      disabled={isDuration ? true : false}
                    >
                      <Dropdown.Item eventKey={30}>30</Dropdown.Item>
                      <Dropdown.Item eventKey={45}>45</Dropdown.Item>
                      <Dropdown.Item eventKey={60}>60</Dropdown.Item>
                    </DropdownButton>
                  </div>
                  {/* <div className="slotBtn">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Slot Per Day
                    </p>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={totalSlot ? totalSlot : "select..."}
                      className="bg-transparent slotDrop"
                      onSelect={(e) => {
                        setTotalSlot(e);
                        handleDropdown("slots_per_day", e);
                      }}
                      disabled={isDuration ? true : false}
                    >
                      {slots.map((data, index) => (
                        <Dropdown.Item eventKey={index + 1}>
                          {index + 1}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              {schedual && (
                <>
                  <div className="col-xxl-6 col-lg-6 mobileHour">
                    <div className="minBlockPrice">
                      <p className="topBarTitle franchiseeDetailBoxTitle">
                        Price (30 min blcok)
                      </p>
                      <div className="priceInput">
                        <input
                          type="number"
                          className={`input franchiseDetailInput ${
                            formErrors.includes("first_hour_price") && "error"
                          }`}
                          name="first_hour_price"
                          onChange={handleInput}
                          defaultValue={defaultValue?.first_hour_price}
                          disabled={isDuration ? true : false}
                        />
                        <span>$</span>
                      </div>
                    </div>
                    <div className="minBlockPrice mobileHourSpace">
                      <p className="topBarTitle franchiseeDetailBoxTitle">
                        Price (15 min blcok)
                      </p>
                      <div className="priceInput">
                        <input
                          type="number"
                          className={`input franchiseDetailInput ${
                            formErrors.includes(
                              "first_hour_thirty_min_price"
                            ) && "error"
                          }`}
                          name="first_hour_thirty_min_price"
                          onChange={handleInput}
                          defaultValue={
                            defaultValue?.first_hour_thirty_min_price
                          }
                        />
                        <span>$</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-lg-6">
                    <div>
                      <p className="topBarTitle franchiseeDetailBoxTitle">
                        Normal Price
                      </p>
                      <div className="priceInput">
                        <input
                          type="number"
                          name=""
                          className={`input franchiseDetailInput detailPriceSlot${
                            formErrors.includes("first_hour_price") && "error"
                          }`}
                          onChange={handleInput}
                          defaultValue={defaultValue?.first_hour_price}
                          disabled={isDuration ? true : false}
                        />
                        <span>$</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-lg-2 d-flex align-items-end">
                    <div className="detailCheckmain">
                      <InputGroup.Checkbox
                        aria-label="Checkbox for following text input"
                        name="no_peak_hours"
                        onChange={handleCheck}
                        defaultChecked={
                          defaultValue?.no_peak_hours === 1 ? true : false
                        }
                      />
                      <span className="checkboxText">No Peak Hour</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row mt-4">
              <div className="col-xxl-6 col-lg-6">
                <div className="d-flex align-itmes-center justify-content-between">
                  <div className="detailPriceSlot">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Price (30 min block)
                    </p>
                    <div className="priceInput">
                      <input
                        type="number"
                        className={`input franchiseDetailInput${
                          formErrors.includes("peak_hour_price") && "error"
                        }`}
                        name="peak_hour_price"
                        defaultValue={defaultValue?.peak_hour_price}
                        onChange={handleInput}
                      />
                      <span>$</span>
                    </div>
                  </div>
                  <div className="minBlockPrice">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Price (15 min block)
                    </p>
                    <div className="priceInput">
                      <input
                        type="number"
                        className={`input franchiseDetailInput${
                          formErrors.includes("peak_hour_thirty_min_price") &&
                          "error"
                        }`}
                        name="peak_hour_thirty_min_price"
                        onChange={handleInput}
                        defaultValue={defaultValue?.peak_hour_thirty_min_price}
                      />
                      <span>$</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4 tabletSizeSpace">
                <div className="d-flex align-items-end justify-content-between">
                  <div className="fullWidthBtn">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Peak Hour
                    </p>
                    {/* <span className="timePicker">
                      <TimePicker disableClock={true} format="h:m a" onChange={(value) => {
                        setPeakHour(value);`
                        handleDropdown("peak_hours_min", value);
                      }}
                        value={peakHour}
                      />
                    </span> */}
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={peakHour}
                      className={`bg-transparent slotDrop ${
                        formErrors.includes("peak_hours_min") && "error"
                      }`}
                      onSelect={(e) => {
                        setPeakHour(e);
                        handleDropdown("peak_hours_min", e);
                      }}
                      disabled={enablePeak}
                    >
                      {slots.map((data, index) => (
                        <Dropdown.Item eventKey={data}>{data}</Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <span className="to">To</span>
                  <div className="fullWidthBtn">
                    {/* <span className="timePicker">
                      <TimePicker disableClock={true} format="h:m a" onChange={(value) => {
                        setPeakHourTo(value);
                        handleDropdown("peak_hours_max", value);
                      }}
                        value={peakHourTo}
                      />
                    </span> */}
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={peakHourTo}
                      className={`bg-transparent slotDrop ${
                        formErrors.includes("peak_hours_max") && "error"
                      }`}
                      onSelect={(e) => {
                        setPeakHourTo(e);
                        handleDropdown("peak_hours_max", e);
                      }}
                      disabled={enablePeak}
                    >
                      {slots.map((data, index) => (
                        <Dropdown.Item eventKey={data}>{data}</Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-lg-2 d-flex align-items-end">
                <div className="detailCheckmain">
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    name="no_peak_hours"
                    onChange={handleCheck}
                    defaultChecked={
                      defaultValue?.no_peak_hours === 1 ? true : false
                    }
                  />
                  <span className="checkboxText">No Peak Hour</span>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-xxl-6 col-lg-6">
                <div className="d-flex align-itmes-center justify-content-between">
                  <div className="detailPriceSlot">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Price super off-Peak (30 min blcok)
                    </p>
                    <div className="priceInput">
                      <input
                        type="number"
                        className={`input franchiseDetailInput ${
                          formErrors.includes("super_peak_hour_price") &&
                          "error"
                        }`}
                        name="super_peak_hour_price"
                        onChange={handleInput}
                        defaultValue={defaultValue?.super_peak_hour_price}
                      />
                      <span>$</span>
                    </div>
                  </div>
                  <div className="minBlockPrice">
                    <p className="topBarTitle franchiseeDetailBoxTitle">
                      Price (15 min blcok)
                    </p>
                    <div className="priceInput">
                      <input
                        type="number"
                        className={`input franchiseDetailInput ${
                          formErrors.includes(
                            "super_peak_hour_thirty_min_price"
                          ) && "error"
                        }`}
                        name="super_peak_hour_thirty_min_price"
                        onChange={handleInput}
                        defaultValue={
                          defaultValue?.super_peak_hour_thirty_min_price
                        }
                      />
                      <span>$</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-lg-4 tabletSizeSpace">
                <p className="topBarTitle franchiseeDetailBoxTitle">
                  Super off-Peak Hour
                </p>
                <div className="d-flex align-items-end justify-content-between">
                  <div className="fullWidthBtn">
                    {/* <span className="timePicker">
                      <TimePicker disabled={enableSuperPeak}
                        disableClock={true} format="h:m a" onChange={(value) => {
                          setSuperHour(value);
                          handleDropdown("super_peak_hours_min", value);
                        }}
                        value={superHour}
                      />
                    </span> */}
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={superHour}
                      className="bg-transparent slotDrop"
                      onSelect={(e) => {
                        setSuperHour(e);
                        handleDropdown("super_peak_hours_min", e);
                      }}
                      disabled={enableSuperPeak}
                    >
                      {slots.map((data, index) => (
                        <Dropdown.Item eventKey={data}>{data}</Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <span className="to">To</span>
                  <div className="fullWidthBtn">
                    {/* <span className="timePicker">
                      <TimePicker disabled={enableSuperPeak}
                        disableClock={true} format="h:m a" onChange={(value) => {
                          setSuperHourTo(value);
                          handleDropdown("super_peak_hours_max", value);
                        }}
                        value={superHourTo}
                      />
                    </span> */}
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={superHourTo}
                      className="bg-transparent slotDrop"
                      onSelect={(e) => {
                        setSuperHourTo(e);
                        handleDropdown("super_peak_hours_max", e);
                      }}
                      disabled={enableSuperPeak}
                    >
                      {slots.map((data, index) => (
                        <Dropdown.Item eventKey={data}>{data}</Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                </div>
              </div>
              <div className="col-xxl-2 col-lg-2 d-flex align-items-end">
                <div className="detailCheckmain">
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    name="no_super_peak_hours"
                    onChange={handleCheck}
                    defaultChecked={
                      defaultValue?.no_super_peak_hours === 1 ? true : false
                    }
                  />
                  <span className="checkboxText">
                    No Price Super off-Peak Hours
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="borderShadowBox userProfileBox my-3">
            <div className="row">
              <div className="col-xxl-10 col-lg-10">
                <div>
                  <p className="gymDetailBottom">Description</p>
                  <textarea
                    rows={8}
                    type="text"
                    className="input franchiseDetailInput detailPriceSlot"
                    name={"description_1"}
                    onChange={handleInput}
                    defaultValue={defaultValue?.description_1}
                  />
                </div>
                {/* <div className="mt-4">
                  <p className="gymDetailBottom">Description 2</p>
                  <div className="gymDetailBottomDesc">
                    <input
                      type="text"
                      className="input franchiseDetailInput detailPriceSlot"
                      name={"description_2"}
                      onChange={handleInput}
                      defaultValue={defaultValue?.description_2}
                    />
                  </div>
                </div> */}
              </div>
              <div className="col-xxl-2 col-lg-2 qrSpacing">
                <div className="qrMain">
                  <div>
                    {qrImage ? (
                      <img src={qrImage} alt="QR" className="qrImg" />
                    ) : (
                      "Upload"
                    )}
                  </div>
                  <span
                    className={`btn uploadQrBtn mt-3 ${
                      formErrors.includes("qr_image") && "error"
                    }`}
                  >
                    <input
                      type="file"
                      name="qr_image"
                      multiple
                      className="qrPicInput"
                      onChange={(e) => {
                        handleUploadQR(e);
                      }}
                    />
                    Upload QR
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-12">
                <div className="slide-container  pt-3">
                  <p className="gymDetailBottom mb-4">Equipments</p>
                  <textarea
                    rows={5}
                    type="text"
                    className={`input franchiseDetailInput detailPriceSlot ${
                      formErrors.includes("available_equipments") && "error"
                    }`}
                    name={"available_equipments"}
                    onChange={handleInput}
                    defaultValue={defaultValue?.available_equipments}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GymDetail;
